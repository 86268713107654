import React, { FC, memo } from 'react';
import { FormControl, FormGroup, FormLabel, Slider, TextField } from '@mui/material';
import { FormLabelGeneralStyle } from '../../../constants/styles/Forms/labels';
import { FormControlGeneralStyle, FormInputGeneralStyle } from '../../../constants/styles/Forms/inputs';
import { FormSliderGeneralStyle } from '../../../constants/styles/Forms/sliders';
import { PriceFieldComponentProps } from '../../../types/Flats/Filters/FiltersPropsTypes';

const PriceField: FC<PriceFieldComponentProps> = ({ handleChangeInput, handleChangeSlider, values, maxFlatPrice }) => {
  return (
    <FormControl sx={FormControlGeneralStyle('24px', '200px')} component="fieldset" variant="standard">
      <div className="d-flex align-items-center">
        <FormLabel sx={FormLabelGeneralStyle(undefined, '0px')} component="legend">
          Цена
        </FormLabel>
        <TextField
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          id="outlined-basic"
          variant="outlined"
          name="priceFirst"
          size="small"
          sx={FormInputGeneralStyle('initial')}
          onChange={handleChangeInput}
          value={values[0].toString()}
          aria-valuemax={50000}
        />
        <TextField
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          id="outlined-basic"
          variant="outlined"
          size="small"
          name="priceSecond"
          type="number"
          sx={FormInputGeneralStyle('initial')}
          onChange={handleChangeInput}
          value={values[1].toString()}
          aria-valuemax={maxFlatPrice}
        />
      </div>
      <FormGroup>
        <Slider
          getAriaLabel={() => 'Temperature range'}
          value={values}
          sx={FormSliderGeneralStyle()}
          onChange={handleChangeSlider}
          valueLabelDisplay="auto"
          getAriaValueText={value => `${value}`}
          disableSwap
          max={maxFlatPrice}
        />
      </FormGroup>
    </FormControl>
  );
};

export default memo(PriceField);
