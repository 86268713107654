const maskedPhoneNumber = (phone: string, isCode?: boolean) => {
  if (!phone) {
    return '';
  }
  const code = phone.slice(0, 3);
  const first = phone.slice(3, 6);
  const second = phone.slice(6, 8);
  const third = phone.slice(8, 10);
  return isCode ? `+38 (${code})${first}-${second}-${third}` : `(${code})${first}-${second}-${third}`;
};
export default maskedPhoneNumber;
