import React, { FC, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Customer from '../../../components/Customers/Customer/CustomerPage';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { getCurrentCustomer } from '../../../store/actions/Customer/CustomerThunk';
import MainWrapper from '../../../components/hoc/MainWrapper';
import FlatsContainer from '../../Flats/FlatsContainer';
import { setCustomerFlatsData } from '../../../store/actions/Flats/FlatActions';

const CustomerContainer: FC = () => {
  const { uuid } = useParams();
  const dispatch = useAppDispatch();
  const { customer } = useAppSelector(state => state.customerReducer);

  const handleGetCustomer = useCallback(async () => {
    if (!uuid) return;
    return dispatch(getCurrentCustomer(uuid));
  }, [dispatch, uuid]);

  useEffect(() => {
    handleGetCustomer().then(({ payload }: any) => {
      if (payload?.flats) {
        dispatch(setCustomerFlatsData(payload.flats));
      }
    });
  }, [dispatch, handleGetCustomer]);

  return (
    <MainWrapper>
      <Customer customer={customer} />
      <FlatsContainer customerFlats />
    </MainWrapper>
  );
};

export default CustomerContainer;
