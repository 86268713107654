import React, { FC, memo, useCallback, useMemo } from 'react';
import { CustomerCardProps } from '../../../types/Customers/CustomersPropsTypes';
import classes from './CustomerCard.module.scss';
import SVGIcon from '../../../components/UI/Icons/IconBody';
import maskPhoneNumber from '../../../utils/maskPhoneNumber';
import { maskCustomerName } from '../../../utils/capitalizeTitle';
import { Button, IconButton } from '@mui/material';
import { APP_ROUTES } from '../../../constants/routes';
import getRouteURI from '../../../utils/getRouteURI';
import { Link, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { AddButton } from '../../UI/Buttons/AddButtons';
import { addFlatIconStyle } from '../../../constants/styles/icons';
import CustomerAvatar from '../../../containers/Customers/Customer/CustomerAvatar';
import AddCustomerContainer from '../../../containers/Customers/Customer/Modals/AddCustomerContainer';
import { IActionMenu } from '../../../models/IActionMenu';
import ArchiveIcon from '@mui/icons-material/Archive';
import ActionsMenu from '../../UI/ActionsMenu';
import { Tooltip } from '../../UI/Tooltips/Tooltip';
import MaskedEmail from '../../../utils/components/MaskedEmail';
import { CustomerTypeIcon, EmailIcon, FloorsIcon, PhoneIcon } from '../../UI/Icons';

const CustomerCard: FC<CustomerCardProps> = ({
  customer,
  isPersonalPage,
  handleEditMode,
  isEditMode,
  isNewFlatPage,
  user,
  phonesData,
  handleGetContactData,
}) => {
  const navigate = useNavigate();

  // TODO: instead of tester = create constant
  const PersonalPageActions: IActionMenu[] = useMemo(
    () =>
      user?.role === 'tester'
        ? []
        : [
            {
              label: 'Добавить квартиру',
              disableRipple: true,
              icon: AddIcon,
              disabled: false,
              onClick: () => navigate(getRouteURI(APP_ROUTES.FLAT_ADD, { uuid: customer?.uuid })),
            },
          {
            label: 'Добавить OLX',
            disableRipple: true,
            icon: AddIcon,
            disabled: false,
            onClick: () => navigate(getRouteURI(APP_ROUTES.FLAT_ADD_OLX, { uuid: customer?.uuid })),
          },
            {
              label: 'Редактировать',
              disableRipple: true,
              icon: EditIcon,
              disabled: false,
              onClick: () => handleEditMode(),
            },
            {
              label: '',
              disableRipple: undefined,
              divider: true,
            },
            {
              label: 'Архивировать',
              disableRipple: true,
              icon: ArchiveIcon,
              disabled: true,
            },
            {
              label: 'Удалить',
              disableRipple: true,
              icon: DeleteOutlineIcon,
              disabled: true,
            },
          ],
    [customer?.uuid, handleEditMode, navigate, user?.role],
  );

  const renderPhones = () => {
    if (!isEditMode && !phonesData && handleGetContactData) {
      return <Button size="small" onClick={() => handleGetContactData()}>Показать Телефоны клиента</Button>
    }

    if (!customer) {
      return null;
    }

    return phonesData ? phonesData.slice(0, 2).map((phone: string, index: number) => (
        <div className="d-flex align-items-center" key={index}>
          <SVGIcon
            width={16}
            height={16}
            currentIcon={PhoneIcon}
            style={{ display: 'flex', marginRight: '7.25px' }}
          />
          <p>{maskPhoneNumber(phone, true)}</p>
        </div>
      )) : [];
  }

  const renderActionsButton = useCallback(() => {
    if (isNewFlatPage) {
      return null;
    }

    if (!customer) {
      return null;
    }

    if (isPersonalPage) {
      return <ActionsMenu options={PersonalPageActions} />;
    } else {
      return (
        <AddButton
          size="small"
          onClick={() =>
            navigate(getRouteURI(APP_ROUTES.FLAT_ADD, { uuid: customer.uuid }), {
              state: {
                customerUUID: customer.uuid,
              },
            })
          }
          style={addFlatIconStyle}
        />
      );
    }
  }, [PersonalPageActions, customer, isNewFlatPage, isPersonalPage, navigate]);

  if (!customer) {
    return null;
  }

  return (
    <div className={classes.CustomerCard}>
      {isPersonalPage ? (
        <CustomerAvatar customer={customer} />
      ) : (
        <IconButton
          sx={{
            borderRadius: '5px',
            padding: '0',
          }}
        >
          <Link to={getRouteURI(APP_ROUTES.CUSTOMER_EDIT, { uuid: customer.uuid })}>
            <CustomerAvatar customer={customer} />
          </Link>
        </IconButton>
      )}

      <div className={classes.CustomerCardInfo}>
        <div className={classes.CustomerCardInfoGeneral}>
          <div>
            {isPersonalPage || isNewFlatPage ? (
              <p>{maskCustomerName(customer.name)}</p>
            ) : (
              <Tooltip
                title={maskCustomerName(customer.name)}
                arrow
                placement="top"
                sx={{ padding: '15px', marginLeft: '4px!important' }}
              >
                <Link to={getRouteURI(APP_ROUTES.CUSTOMER_EDIT, { uuid: customer.uuid })}>
                  {maskCustomerName(customer.name)}
                </Link>
              </Tooltip>
            )}

            {customer.email ? (
              <div className="d-flex align-items-center">
                {isPersonalPage || isNewFlatPage ? (
                  <>
                    <SVGIcon width={13.5} height={9} currentIcon={EmailIcon} style={{ marginRight: '7.25px' }} />
                    {customer.email}
                  </>
                ) : (
                  <MaskedEmail email={customer.email} />
                )}
              </div>
            ) : (
              <div className={classes.CustomerCardInfoGeneralEmailNotFound}>
                <SVGIcon width={16} height={12} currentIcon={EmailIcon} style={{ marginRight: '7.25px' }} />
                Не указан
              </div>
            )}
          </div>
        </div>
        <div className={classes.CustomerCardInfoContacts}>
          {renderPhones()}
        </div>
        <div className={classes.CustomerCardInfoRest}>
          <div className="d-flex align-items-center">
            <SVGIcon
              width={14}
              height={14}
              currentIcon={CustomerTypeIcon}
              style={{ display: 'flex', marginRight: '7.25px' }}
            />
            <p>{customer.type}</p>
          </div>
          <div className="d-flex align-items-center">
            <SVGIcon
              width={16}
              height={20}
              fill="#C68A52"
              currentIcon={FloorsIcon}
              style={{ display: 'flex', marginRight: '7.25px' }}
            />
            <p>{customer.flats.length}</p>
          </div>
        </div>
        <div className={classes.CustomerCardInfoButton} style={{ alignSelf: isPersonalPage ? 'baseline' : 'flex-end' }}>
          {renderActionsButton()}
        </div>
      </div>
      {isEditMode && <AddCustomerContainer opened={isEditMode} handleClose={handleEditMode} isEditMode />}
    </div>
  );
};

export default memo(CustomerCard);
