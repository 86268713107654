import React, { FC, memo, useCallback, useState } from 'react';
import { filtersSortingData } from '../../../mappings/Sorting/FiltersSorting';
import { Button, Checkbox } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { COLORS } from '../../../constants/colors';
import classes from './Filters.module.scss';

const Sorting: FC = () => {
  const [sortingChecked, setSortingChecked] = useState<any>({
    price: false,
    added: false,
    activity: false,
    rating: false,
  });

  const handleSortingChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { name, checked } = event.target;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setSortingChecked({ ...sortingChecked, [name]: checked });
    },
    [sortingChecked],
  );

  const handleSortingClick = (currentName: string) =>
    setSortingChecked((prev: { [x: string]: any }) => ({ ...sortingChecked, [currentName]: !prev[currentName] }));

  return (
    <div className={classes.Sorting}>
      {filtersSortingData.map(({ label, id, name }) => (
        <Button className={classes.SortingItem} onClick={() => handleSortingClick(name)} key={id}>
          {label}
          <Checkbox
            color="success"
            onChange={handleSortingChange}
            name={name}
            icon={
              <ArrowDropDownIcon
                sx={{
                  color: COLORS.WHITE,
                }}
              />
            }
            checked={sortingChecked[name]}
            checkedIcon={
              <ArrowDropUpIcon
                sx={{
                  color: COLORS.WHITE,
                }}
              />
            }
          />
        </Button>
      ))}
    </div>
  );
};

export default memo(Sorting);
