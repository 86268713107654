import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../models/IError';
import { IInfoNotification } from '../../models/IInfoNotification';

type ActionSearchType = 'add' | 'search' | string;

interface flatFiltersState {
  isSearchActive: boolean;
  currentSearchMode: ActionSearchType;
  errors: Array<IError>;
  notification: IInfoNotification | undefined;
}

const initialState: flatFiltersState = {
  isSearchActive: false,
  currentSearchMode: '',
  errors: [],
  notification: undefined,
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    toggleSearchActive: (state, action: PayloadAction<ActionSearchType>) => {
      state.isSearchActive = !state.isSearchActive;
      state.currentSearchMode = action.payload;
    },
    closeSearch: state => {
      state.isSearchActive = false;
      state.currentSearchMode = '';
    },
  },
  extraReducers: {},
});

export default homeSlice.reducer;
