import React from 'react';
import { SVGIconProps } from '../../../../types/UI/Icons/SVGIconsTypes';

export const UserIcon: React.FC<SVGIconProps> = ({ width = 40, height = 40, clazz }) => (
  <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_417_5283)">
      <path
        d="M20 21.25C22.7167 21.25 25.1167 21.9 27.0667 22.75C28.8667 23.55 30 25.35 30 27.3V30H10V27.3167C10 25.35 11.1333 23.55 12.9333 22.7667C14.8833 21.9 17.2833 21.25 20 21.25ZM6.66667 21.6667C8.5 21.6667 10 20.1667 10 18.3333C10 16.5 8.5 15 6.66667 15C4.83333 15 3.33333 16.5 3.33333 18.3333C3.33333 20.1667 4.83333 21.6667 6.66667 21.6667ZM8.55 23.5C7.93333 23.4 7.31667 23.3333 6.66667 23.3333C5.01667 23.3333 3.45 23.6833 2.03333 24.3C1.42952 24.5581 0.914873 24.9879 0.553364 25.5361C0.191855 26.0843 -0.00057281 26.7267 1.28082e-06 27.3833V30H7.5V27.3167C7.5 25.9333 7.88334 24.6333 8.55 23.5ZM33.3333 21.6667C35.1667 21.6667 36.6667 20.1667 36.6667 18.3333C36.6667 16.5 35.1667 15 33.3333 15C31.5 15 30 16.5 30 18.3333C30 20.1667 31.5 21.6667 33.3333 21.6667ZM40 27.3833C40 26.0333 39.2 24.8333 37.9667 24.3C36.5052 23.6623 34.9278 23.3332 33.3333 23.3333C32.6833 23.3333 32.0667 23.4 31.45 23.5C32.1167 24.6333 32.5 25.9333 32.5 27.3167V30H40V27.3833ZM20 10C22.7667 10 25 12.2333 25 15C25 17.7667 22.7667 20 20 20C17.2333 20 15 17.7667 15 15C15 12.2333 17.2333 10 20 10Z"
        fill="#C68A52"
        className={clazz}
      />
    </g>
    <defs>
      <clipPath id="clip0_417_5283">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
