import {
  BakeIcon,
  BathroomIcon,
  BedIcon,
  CatIcon,
  ChildrenIcon,
  DogIcon,
  ForeignersIcon,
  SmokingIcon,
  StudioIcon,
} from '../../components/UI/Icons';

interface FLATS_STATUSES_TYPES {
  [key: string]: string;
}

export const FLATS_STATUSES: FLATS_STATUSES_TYPES = {
  ACTUAL: 'Актуально',
  NEEDS_TO_BE_ACTUAL: 'Требует актуализации',
  NOT_ACTUAL: 'Не актуально',
  DEFERRED: 'Отложенно',
  CLOSED: 'Закрыто',
};

export const FLATS_ICONS: any = {
  bake: BakeIcon,
  bathroom: BathroomIcon,
  bed: BedIcon,
  children: ChildrenIcon,
  foreigners: ForeignersIcon,
  dog: DogIcon,
  cat: CatIcon,
  smoking: SmokingIcon,
  studio: StudioIcon,
};
