import React from 'react';
import { SVGIconProps } from '../../../../types/UI/Icons/SVGIconsTypes';

export const SquaresIcon: React.FC<SVGIconProps> = ({ width = 20, height = 20, clazz }) => (
  <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 9H6M1 20.4V1.6C1 1.44087 1.06321 1.28826 1.17574 1.17574C1.28826 1.06321 1.44087 1 1.6 1H20.4C20.5591 1 20.7117 1.06321 20.8243 1.17574C20.9368 1.28826 21 1.44087 21 1.6V8.4C21 8.55913 20.9368 8.71174 20.8243 8.82426C20.7117 8.93679 20.5591 9 20.4 9H9.6C9.44087 9 9.28826 9.06321 9.17574 9.17574C9.06321 9.28826 9 9.44087 9 9.6V20.4C9 20.4788 8.98448 20.5568 8.95433 20.6296C8.92417 20.7024 8.87998 20.7685 8.82426 20.8243C8.76855 20.88 8.70241 20.9242 8.62961 20.9543C8.55681 20.9845 8.47879 21 8.4 21H1.6C1.52121 21 1.44319 20.9845 1.37039 20.9543C1.29759 20.9242 1.23145 20.88 1.17574 20.8243C1.12002 20.7685 1.07583 20.7024 1.04567 20.6296C1.01552 20.5568 1 20.4788 1 20.4V20.4ZM15 9V6V9ZM9 9V6V9ZM9 15H6H9Z"
      stroke="#2F6D8E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={clazz}
    />
  </svg>
);
