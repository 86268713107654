import getRouteURI from '../utils/getRouteURI';

export const ROUTES = {
  VERIFY: '/verifyLogin',
  ADD_CUSTOMER: '/addCustomer',
  ADD_NEW_FLAT: '/addFlat',
  ADD_NEW_OLX_FLAT: '/pullFlat',
  FLATS: '/flats',
  FLATS_PAGINATION: '/flats/?page=:page',
  FLATS_LOAD_MORE: '/flats/?page=[:page]',
  FLAT: '/getFlat/:uuid',
  GET_CONTACT_DATA: '/getContactData/:resourceKey/:uuid',
  GET_CUSTOMER_DATA: '/getCustomerData/:uuid',
  FLAT_UPLOAD_FILES: '/:uuid/files',
  FLAT_ADD_FILE: '/files/:uuid/add',
  FLAT_UPDATE_ATTACHMENTS: '/files/:uuid/update',
  FLAT_EDIT: '/editFlat/:uuid',
  FLAT_DELETE: '/deleteFlat',
  FLAT_REMOVE_FILE: '/files/:flatUUID/:fileName/remove',
  EDIT_CUSTOMER: '/editCustomer/:uuid',
  FIND_CUSTOMER: '/findCustomer',
  GET_CUSTOMER: '/getCustomer/:uuid',
  GET_CUSTOMER_FLATS: '/flats/:customer',
  SESSION: '/session',
};

export const APP_ROUTES = {
  AUTH: '/login',
  HOME: '/',
  OBJECTS: '/objects',
  FLAT_ADD: '/addFlat/:uuid',
  FLAT_ADD_OLX: '/addFlat/olx/:uuid',
  FLAT_EDIT: '/editFlat/:uuid',
  FLAT_VIEW: '/flat/:uuid',
  CV_PROFILE: '/profile/cv/:name',
  CONTACTS: '/contacts',
  MEETS: '/meets',
  CALENDAR: '/calendar',
  CUSTOMER_EDIT: '/customer/:uuid',
};

export const BREADCRUMB_LINK = (route: string, param: any, label: string) => ({
  to: getRouteURI(route, param),
  label,
});

export const BREADCRUMBS = {
  HOME: {
    to: APP_ROUTES.HOME,
    label: 'Главная',
  },
  OBJECTS: {
    to: APP_ROUTES.OBJECTS,
    label: 'Объекты',
  },
  CUSTOMERS: {
    to: '/',
    label: 'Клиенты',
  },
};
