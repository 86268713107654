// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddCustomer_AddCustomer__6DAKG {
  display: block;
}
.AddCustomer_AddCustomerTitle__MzTEp {
  font-size: 28px;
  line-height: 32px;
  font-weight: 600;
}
.AddCustomer_AddCustomerContent__w3fYE {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 591px;
  border: 1px solid #008DDC;
  box-shadow: 0px 0px 34px rgba(0, 141, 220, 0.4);
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  padding: 32px;
}
.AddCustomer_AddCustomerLeftContent__8Rgq3 {
  display: block;
}
.AddCustomer_AddCustomerRightContent__jBNZ0 {
  display: block;
}

@media only screen and (max-width: 320px) {
  .AddCustomer_AddCustomerContent__w3fYE {
    width: 260px;
  }
}
@media only screen and (max-width: 375px) {
  .AddCustomer_AddCustomerContent__w3fYE {
    width: 305px;
  }
}
@media only screen and (max-width: 460px) {
  .AddCustomer_AddCustomerContent__w3fYE {
    width: 407px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Customers/Modals/AddCustomer.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AACE;EACE,eAAA;EACA,iBAAA;EACA,gBAAA;AACJ;AAEE;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,YAAA;EACA,yBAAA;EACA,+CAAA;EACA,kBAAA;EACA,oCAAA;EACA,aAAA;AAAJ;AAGE;EACE,cAAA;AADJ;AAIE;EACE,cAAA;AAFJ;;AAMA;EAEI;IACE,YAAA;EAJJ;AACF;AAQA;EAEI;IACE,YAAA;EAPJ;AACF;AAWA;EAEI;IACE,YAAA;EAVJ;AACF","sourcesContent":[".AddCustomer {\n  display: block;\n\n  &Title {\n    font-size: 28px;\n    line-height: 32px;\n    font-weight: 600;\n  }\n\n  &Content {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 591px;\n    border: 1px solid #008DDC;\n    box-shadow: 0px 0px 34px rgba(0, 141, 220, 0.4);\n    border-radius: 5px;\n    background-color: rgb(255, 255, 255);\n    padding: 32px;\n  }\n\n  &LeftContent {\n    display: block\n  }\n\n  &RightContent {\n    display: block\n  }\n}\n\n@media only screen and (max-width: 320px) {\n  .AddCustomer {\n    &Content {\n      width: 260px;\n    }\n  }\n}\n\n@media only screen and (max-width: 375px) {\n  .AddCustomer {\n    &Content {\n      width: 305px;\n    }\n  }\n}\n\n@media only screen and (max-width: 460px) {\n  .AddCustomer {\n    &Content {\n      width: 407px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AddCustomer": `AddCustomer_AddCustomer__6DAKG`,
	"AddCustomerTitle": `AddCustomer_AddCustomerTitle__MzTEp`,
	"AddCustomerContent": `AddCustomer_AddCustomerContent__w3fYE`,
	"AddCustomerLeftContent": `AddCustomer_AddCustomerLeftContent__8Rgq3`,
	"AddCustomerRightContent": `AddCustomer_AddCustomerRightContent__jBNZ0`
};
export default ___CSS_LOADER_EXPORT___;
