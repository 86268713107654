import React, { FC, memo } from 'react';
import InputMask from 'react-input-mask';
import { Backdrop, InputAdornment, TextField } from '@mui/material';
import classes from './Search.module.scss';
import { InputAdornmentStyle, NavbarSearchInputStyle } from '../../../constants/styles/Forms/inputs';
import SearchResult from './SearchResult';
import { unMaskPhoneNumber } from '../../../utils/unMaskPhoneNumber';
import { SearchComponentProps } from '../../../types/UI/Navbar/Search/SearchPropsTypes';

const Search: FC<SearchComponentProps> = ({
  isActive,
  handleClose,
  phone,
  handleChange,
  handleKeyClose,
  handleSelectionClick,
  customer,
  fetching,
}) => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
      open={isActive}
      onClick={handleClose}
      onKeyUp={handleKeyClose}
    >
      <div className={classes.Search}>
        <InputMask mask="(099)999-99-99" value={phone} disabled={false} onChange={handleChange} maskChar="_">
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
          {/* @ts-ignore*/}
          {() => (
            <TextField
              autoFocus
              onClick={e => {
                e.stopPropagation();
                handleSelectionClick(e);
              }}
              sx={NavbarSearchInputStyle}
              InputProps={{
                startAdornment: (
                  <InputAdornment sx={InputAdornmentStyle} position="start">
                    +38
                  </InputAdornment>
                ),
              }}
            />
          )}
        </InputMask>
        {unMaskPhoneNumber(phone).length === 10 ? (
          <SearchResult phone={phone} customer={customer} fetching={fetching} />
        ) : null}
      </div>
    </Backdrop>
  );
};

export default memo(Search);
