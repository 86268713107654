import React from 'react';
import { SVGIconProps } from '../../../../types/UI/Icons/SVGIconsTypes';

export const ViewsIcon: React.FC<SVGIconProps> = ({ width = 30, height = 30, clazz }) => (
  <svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.6875 15C19.6875 16.2432 19.1936 17.4355 18.3146 18.3146C17.4355 19.1936 16.2432 19.6875 15 19.6875C13.7568 19.6875 12.5645 19.1936 11.6854 18.3146C10.8064 17.4355 10.3125 16.2432 10.3125 15C10.3125 13.7568 10.8064 12.5645 11.6854 11.6854C12.5645 10.8064 13.7568 10.3125 15 10.3125C16.2432 10.3125 17.4355 10.8064 18.3146 11.6854C19.1936 12.5645 19.6875 13.7568 19.6875 15Z"
      fill="#C68A52"
      className={clazz}
    />
    <path
      d="M0 15C0 15 5.625 4.6875 15 4.6875C24.375 4.6875 30 15 30 15C30 15 24.375 25.3125 15 25.3125C5.625 25.3125 0 15 0 15ZM15 21.5625C16.7405 21.5625 18.4097 20.8711 19.6404 19.6404C20.8711 18.4097 21.5625 16.7405 21.5625 15C21.5625 13.2595 20.8711 11.5903 19.6404 10.3596C18.4097 9.1289 16.7405 8.4375 15 8.4375C13.2595 8.4375 11.5903 9.1289 10.3596 10.3596C9.1289 11.5903 8.4375 13.2595 8.4375 15C8.4375 16.7405 9.1289 18.4097 10.3596 19.6404C11.5903 20.8711 13.2595 21.5625 15 21.5625Z"
      fill="#C68A52"
      className={clazz}
    />
  </svg>
);
