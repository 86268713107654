export const INPUT_PATTERNS = {
  NUMERIC: { inputMode: 'numeric', pattern: '[0-9]*' },
  EMAIL_LENGTH_TO_SHOW: 13,
};

export const DATE_PATTERNS = {
  MASK: '__.__.____',
  INPUT_FORMAT: 'DD.MM.YYYY',
  PLACEHOLDER: 'ДД.ММ.ГГГГ',
  UI_FORMAT: 'DD.MM.YYYY HH:mm',
};

export const SUBWAY_STATIONS_LENGTH = 51;
