import { Button, styled } from '@mui/material';

export const SubmitButtonMain = styled(Button)`
  ${({ theme }) => `
  cursor: pointer;
  font-size: 18px;
  line-height: 19.5px;
  font-weight: 400;
  color: #fff;
  transition: .3s;
  text-transform: none;
  padding: 15px 0;
  transform: scale(1.005);
  background-color: rgba(43, 60, 70, 0.93);
  &:hover {
  backdrop-filter: blur(4px);
    transition: ${theme.transitions.create(['all'], {
      duration: theme.transitions.duration.standard,
    })};
    background: #2F6D8E;
    transform: scale(0.97);
  }
  `}
`;

// TODO: Add some handlers with props

export const EditButtonMedium = styled(Button)`
  ${({ theme }) => `
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #fff;
  transition: .3s;
  margin-right: 30px;
  text-transform: none;
  padding: 7px 20px;
  transform: scale(1.005);
  background-color: rgba(43, 60, 70, 0.93);
  &:hover {
  backdrop-filter: blur(4px);
    transition: ${theme.transitions.create(['all'], {
      duration: theme.transitions.duration.standard,
    })};
    background: #2F6D8E;
    transform: scale(0.97);
  }
  `}
`;
