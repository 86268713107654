import React, { FC, memo } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FormGroup, FormHelperText, FormLabel } from '@mui/material';
import { FormInputPropsGeneralStyle } from '../../../../constants/styles/Forms/inputs';
import { helperTextStyle } from '../../../../constants/styles/Forms/selects';
import { SelectSingleFieldComponentProps } from '../../../../types/Forms/FormPropsTypes';

const SelectSingleField: FC<SelectSingleFieldComponentProps> = ({
  label,
  options,
  formControlStyle,
  formLabelStyle,
  formInputStyle,
  formGroupStyle,
  validation,
  required,
  isInline,
  defaultValue,
  removeEmptyValue,
  value,
  handleChange,
}) => {
  const helperText = validation?.helperText;
  if (validation) {
    delete validation.helperText;
  }

  return (
    <FormControl sx={formControlStyle} variant="standard">
      <div className={isInline ? 'd-flex align-items-center' : ''}>
        <FormLabel sx={formLabelStyle} component="legend">
          {required ? (
            <p>
              {label}
              <span className="requiredLabel">*</span>
            </p>
          ) : (
            label
          )}
        </FormLabel>
        <FormGroup sx={formGroupStyle}>
          <Select
            displayEmpty
            // clearIcon={<CheckIcon />}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            inputProps={{
              style: { ...FormInputPropsGeneralStyle() },
            }}
            sx={formInputStyle}
            variant="outlined"
            defaultValue={defaultValue}
            onChange={handleChange}
            {...validation}
          >
            {removeEmptyValue ? null : (
              <MenuItem value="">
                <em style={{ visibility: 'hidden' }}>None</em>
              </MenuItem>
            )}
            {options.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText sx={helperTextStyle}>{helperText || ''}</FormHelperText>
        </FormGroup>
      </div>
    </FormControl>
  );
};
export default memo(SelectSingleField);
