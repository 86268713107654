import React from 'react';
import { SVGIconProps } from '../../../../types/UI/Icons/SVGIconsTypes';

export const AttachFileIcon: React.FC<SVGIconProps> = ({ width = 40, height = 40, clazz, stroke = '#2F6D8E' }) => (
  <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.3673 6.36967V5.98045C11.3673 4.67709 10.3107 3.62061 9.00742 3.62061H2.35984C1.05656 3.62061 0 4.67717 0 5.98053V8.49881V35.1999C0 35.8515 0.528281 36.3798 1.17992 36.3798H34.1316V8.72959C34.1316 7.42623 33.0751 6.36975 31.7718 6.36975L11.3673 6.36967Z"
      fill="#DB710D"
    />
    <path
      d="M11.3673 6.36967V5.98045C11.3673 4.67709 10.3107 3.62061 9.00742 3.62061H2.35984C1.05656 3.62061 0 4.67717 0 5.98053V8.49881V35.1999C0 35.8515 0.528281 36.3798 1.17992 36.3798H16.9427V6.36967H11.3673Z"
      fill="#F58F00"
    />
    <path
      d="M37.6399 11.9873H6.938C5.81784 11.9873 4.85198 12.7748 4.62644 13.8721L0.131592 35.7397C0.327607 36.1196 0.723076 36.3798 1.17995 36.3798H35.4634C36.6663 36.3798 37.6767 35.475 37.809 34.2794L39.9854 14.6067C40.1401 13.2092 39.046 11.9873 37.6399 11.9873Z"
      fill="#FCC200"
    />
    <path
      d="M21.5158 11.9873H6.938C5.81784 11.9873 4.85198 12.7748 4.62644 13.8721L0.131592 35.7397C0.327607 36.1196 0.723076 36.3798 1.17995 36.3798H18.8167L21.5158 11.9873Z"
      fill="#FFDE47"
    />
  </svg>
);
