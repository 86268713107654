import axiosUser from './axios/axiosUser';
import { HEADERS } from '../constants/api';
import { APP_ROUTES, ROUTES } from '../constants/routes';
import {
  AddCustomerAPIValues,
  AddNewFlatFormValues, AddNewFlatOLXFormValues,
  AddNewFlatPayloadValues,
  EditFlatInterface,
  FindCustomerAPIValues,
  PayloadCustomerPUTData,
} from '../types/Forms/FormValuesTypes';
import axiosCustomers from './axios/axiosCustomers';
import getRouteURI from '../utils/getRouteURI';
import { AuthFormValues, AuthFormVerificationValues } from '../types/Authorization/AuthorizationValues';
import axiosFlat from './axios/axiosFlat';

export const authorize = async (data: AuthFormValues) => {
  const response = await axiosUser.post(APP_ROUTES.AUTH, JSON.stringify(data), HEADERS.REGULAR_POST_HEADER);
  return response;
};

export const verify = async (data: AuthFormVerificationValues) => {
  const response = await axiosUser.post(ROUTES.VERIFY, JSON.stringify(data), HEADERS.REGULAR_POST_HEADER);
  return response;
};

export const currentSessionCheck = async () => {
  const response = await axiosUser.get(ROUTES.SESSION, {
    headers: {
      Authorization: localStorage.getItem('verticalAccessToken')?.toString() || '',
    },
  });
  return response;
};

export const addNewCustomer = async (data: AddCustomerAPIValues) => {
  const response = await axiosCustomers.post(ROUTES.ADD_CUSTOMER, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('verticalAccessToken')?.toString() || '',
    },
  });
  return response;
};

export const editCurrentCustomer = async (payload: PayloadCustomerPUTData) => {
  const { uuid, data } = payload;
  const response = await axiosCustomers.put(getRouteURI(ROUTES.EDIT_CUSTOMER, { uuid }), JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('verticalAccessToken')?.toString() || '',
    },
  });
  return response;
};

export const findCustomer = async (data: FindCustomerAPIValues) => {
  const response = await axiosCustomers.post(ROUTES.FIND_CUSTOMER, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('verticalAccessToken')?.toString() || '',
    },
  });
  return response;
};

export const getCustomer = async (uuid: string) => {
  const response = await axiosCustomers.get(getRouteURI(ROUTES.GET_CUSTOMER, { uuid }), {
    headers: {
      // 'Content-Type': 'application/json',
      Authorization: localStorage.getItem('verticalAccessToken')?.toString() || '',
    },
  });
  return response;
};

export const getCustomerInfoData = async (uuid: string) => {
  const response = await axiosCustomers.get(getRouteURI(ROUTES.GET_CUSTOMER_DATA, { uuid }), {
    headers: {
      // 'Content-Type': 'application/json',
      Authorization: localStorage.getItem('verticalAccessToken')?.toString() || '',
    },
  });
  return response;
};

export const uploadFlatAttachments = async (uuid: string, files: AddNewFlatPayloadValues) => {
  const response = await axiosFlat.post(getRouteURI(ROUTES.FLAT_UPLOAD_FILES, { uuid }), files, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: localStorage.getItem('verticalAccessToken')?.toString() || '',
    },
  });
  return response;
};

export const addFlatAttachments = async (uuid: string, files: AddNewFlatPayloadValues) => {
  const response = await axiosFlat.post(getRouteURI(ROUTES.FLAT_ADD_FILE, { uuid }), files, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: localStorage.getItem('verticalAccessToken')?.toString() || '',
    },
  });
  return response;
};

export const updateFlatAttachmentsPosition = async (uuid: string, files: AddNewFlatPayloadValues) => {
  const response = await axiosFlat.put(getRouteURI(ROUTES.FLAT_UPDATE_ATTACHMENTS, { uuid }), files, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('verticalAccessToken')?.toString() || '',
    },
  });
  return response;
};

export const editFlatService = async (uuid: string, flat: EditFlatInterface) => {
  const response = await axiosFlat.put(getRouteURI(ROUTES.FLAT_EDIT, { uuid }), flat, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('verticalAccessToken')?.toString() || '',
    },
  });
  return response;
};

export const addNewFlat = async (data: AddNewFlatFormValues) => {
  const response = await axiosFlat.post(ROUTES.ADD_NEW_FLAT, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: localStorage.getItem('verticalAccessToken')?.toString() || '',
    },
  });
  return response;
};


export const addNewOlxFlat = async (data: AddNewFlatOLXFormValues) => {
  const response = await axiosFlat.post(ROUTES.ADD_NEW_OLX_FLAT, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('verticalAccessToken')?.toString() || '',
    },
  });
  return response;
};
