import { BedIcon } from './Flats/Filters/BedIcon';
import { DistrictIcon } from './Flats/Filters/DistrictIcon';
import { ChildrenIcon } from './Flats/Filters/ChildrenIcon';
import { ForeignersIcon } from './Flats/Filters/ForeignersIcon';
import { StudioIcon } from './Flats/Filters/StudioIcon';
import { SearchIcon } from './Navbar/SearchIcon';
import { BakeIcon } from './Flats/Filters/BakeIcon';
import { StreetIcon, StreetIconChecked } from './Flats/Filters/StreetIcon';
import { BathroomIcon } from './Flats/Filters/BathroomIcon';
import { DogIcon } from './Flats/Filters/DogIcon';
import { SubwayIcon } from './Flats/Filters/SubwayIcon';
import { DistrictIconChecked } from './Flats/Filters/DistrictIconChecked';
import { ResidenceIcon, ResidenceIconChecked } from './Flats/Filters/ResidenceIcon';
import { SmokingIcon } from './Flats/Filters/SmokingIcon';
import { CatIcon } from './Flats/Filters/CatIcon';
import { SubwayIconChecked } from './Flats/Filters/SubwayIconChecked';
import { EmailIcon } from './Navbar/Search/SearchResult/EmailIcon';
import { PhoneIcon } from './Navbar/Search/SearchResult/PhoneIcon';
import { FloorsIcon } from './Flats/FloorsIcon';
import { CustomerTypeIcon } from './Navbar/Search/SearchResult/CustomerTypeIcon';
import { SquaresIcon } from './Flats/SquaresIcon';
import { LocationIcon } from './Flats/LocationIcon';
import { GpsIcon } from './Flats/GpsIcon';
import { OwnerIcon } from './Flats/OwnerIcon';
import { UserIcon } from './Flats/UserIcon';
import { ViewsIcon } from './Flats/Viewsicon';
import { StatusIcon } from './Flats/StatusIcon';
import { DateIcon } from './Flats/DateIcon';
import { RoomsIcon } from './Flats/RoomsIcon';
import { FlatIcon } from './Flats/FlatIcon';
import { FavoritesIcon } from './Flats/Flat/FavoritesIcon';
import { WebLinkIcon } from './Flats/Flat/WebLinkIcon';
import { SubwayStationsIcon } from './Flats/Flat/SubwayStationsIcon';
import { InfoIcon } from './Navbar/Search/InfoIcon';

export {
  SearchIcon,
  BedIcon,
  StudioIcon,
  SmokingIcon,
  ForeignersIcon,
  ChildrenIcon,
  DistrictIcon,
  StreetIcon,
  StreetIconChecked,
  BakeIcon,
  BathroomIcon,
  DogIcon,
  CatIcon,
  DistrictIconChecked,
  SubwayIcon,
  SubwayIconChecked,
  ResidenceIconChecked,
  ResidenceIcon,
  EmailIcon,
  PhoneIcon,
  FloorsIcon,
  CustomerTypeIcon,
  RoomsIcon,
  SquaresIcon,
  LocationIcon,
  GpsIcon,
  OwnerIcon,
  UserIcon,
  ViewsIcon,
  StatusIcon,
  DateIcon,
  FlatIcon,
  FavoritesIcon,
  WebLinkIcon,
  SubwayStationsIcon,
  InfoIcon,
};
