import React, { ChangeEvent, FC, memo } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { InputAdornmentStyle } from '../../../../constants/styles/Forms/inputs';
import { PhoneNumberProps } from '../../../../types/UI/Fields/FieldsPropsTypes';

const PhoneNumber: FC<PhoneNumberProps> = ({ formInputStyle, validation }) => {
  return (
    <TextField
      onClick={e => e.stopPropagation()}
      sx={formInputStyle}
      onInput={(e: ChangeEvent<HTMLInputElement>) => {
        e.target.value = e.target.value.toString().slice(0, 9);
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment sx={InputAdornmentStyle} position="start">
            +380
          </InputAdornment>
        ),
      }}
      {...validation}
    />
  );
};

export default memo(PhoneNumber);
