import React, { FC, memo } from 'react';
import HeadInfoWrapper from '../../Wrappers/HeadInfoWrapper';
import CustomerCardContainer from '../../../containers/Customers/Customer/CustomerCardContainer';
import classes from './Customer.module.scss';
import { stringToColor } from '../../../utils/stringAvatar';
import { capitalizeEveryWord } from '../../../utils/capitalizeTitle';
import { BREADCRUMBS } from '../../../constants/routes';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs';
import { CustomerPageComponentProps } from '../../../types/Customers/CustomersPropsTypes';

const Customer: FC<CustomerPageComponentProps> = ({ customer }) => {
  const { breadcrumbs, currentPage } = useBreadcrumbs([BREADCRUMBS.HOME, BREADCRUMBS.CUSTOMERS], {
    label: capitalizeEveryWord(customer?.name),
  });

  if (!customer) {
    return null;
  }

  return (
    <HeadInfoWrapper
      breadcrumbs={breadcrumbs}
      currentPage={currentPage}
      bodyStyle={{ height: '424px' }}
      headStyle={{ height: '524px' }}
    >
      <div className={classes.CustomerPage}>
        <h2>Страница клиента</h2>
        <CustomerCardContainer customer={customer} isPersonalPage />

        <div className={classes.CustomerPageNote}>
          <div
            className={classes.CustomerPageNoteStick}
            style={{ background: stringToColor(capitalizeEveryWord(customer.name)).toString() }}
          />
          <div className={classes.CustomerPageNoteDetails}>
            <p>{customer.comment || 'Комментарий отсутствует'}</p>
          </div>
        </div>
      </div>
    </HeadInfoWrapper>
  );
};

export default memo(Customer);
