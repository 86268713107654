import React, { FC, memo } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { FormCheckbox } from '../../../../constants/styles/Forms/checkboxes';
import { CheckboxLabelComponentProps } from '../../../../types/UI/Fields/FieldsPropsTypes';

const CheckboxLabelField: FC<CheckboxLabelComponentProps> = ({
  label,
  checked,
  name,
  formLabelStyle,
  validation,
  handleChange,
}) => {
  return (
    <FormControlLabel
      sx={formLabelStyle}
      control={
        <Checkbox
          sx={FormCheckbox}
          checked={validation ? validation.value : checked}
          onChange={handleChange}
          name={name}
          {...validation}
        />
      }
      label={label}
    />
  );
};

export default memo(CheckboxLabelField);
