// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddNewFlat_NewFlat__1aixe {
  display: block;
  padding: 55px 0;
}
.AddNewFlat_NewFlat__1aixe > h2 {
  font-weight: 600;
  font-size: 26px;
  color: #fff;
  margin-bottom: 30px;
}

@media only screen and (max-width: 320px) {
  .AddNewFlat_NewFlat__1aixe > h2 {
    text-align: center;
  }
}
@media only screen and (max-width: 375px) {
  .AddNewFlat_NewFlat__1aixe > h2 {
    text-align: center;
  }
}
@media only screen and (max-width: 460px) {
  .AddNewFlat_NewFlat__1aixe > h2 {
    text-align: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Flats/new/AddNewFlat.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;AACF;AACE;EACE,gBAAA;EACA,eAAA;EACA,WAAA;EACA,mBAAA;AACJ;;AAEA;EAEI;IACE,kBAAA;EAAJ;AACF;AAIA;EAEI;IACE,kBAAA;EAHJ;AACF;AAOE;EAEI;IACE,kBAAA;EANN;AACF","sourcesContent":[".NewFlat {\n  display: block;\n  padding: 55px 0;\n\n  & > h2 {\n    font-weight: 600;\n    font-size: 26px;\n    color: #fff;\n    margin-bottom: 30px;\n  }\n}\n@media only screen and (max-width: 320px) {\n  .NewFlat {\n    & > h2 {\n      text-align: center;\n    }\n  }\n}\n\n@media only screen and (max-width: 375px) {\n  .NewFlat {\n    & > h2 {\n      text-align: center;\n    }\n  }\n}\n\n  @media only screen and (max-width: 460px) {\n    .NewFlat {\n      & > h2 {\n        text-align: center;\n      }\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NewFlat": `AddNewFlat_NewFlat__1aixe`
};
export default ___CSS_LOADER_EXPORT___;
