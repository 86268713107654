/* Options for Filters Select "Status" field */

export const FlatStatusSelectOptions = [
  { value: 'actual', label: 'Актуальный', id: 1 },
  { value: 'requires_actualization', label: 'Требует актуализации', id: 2 },
  { value: 'not_actual', label: 'Не актуальный', id: 3 },
  { value: 'deferred', label: 'Отложенный', id: 4 },
  { value: 'closed', label: 'Закрытый', id: 5 },
];

/* Options for Filters Select "ClientType" field */
export const ClientTypeSelectOptions = [
  { value: 'Частное лицо', label: 'Частное лицо', id: 1 },
  { value: 'Риэлтор', label: 'Риэлтор', id: 2 },
  { value: 'Посредник', label: 'Посредник', id: 3 },
];

export const DistrictLinesSingle = [
  { value: 'Голосеевский', label: 'Голосеевский', id: 1 },
  { value: 'Дарницкий', label: 'Дарницкий', id: 2 },
  { value: 'Деснянский', label: 'Деснянский', id: 3 },
  { value: 'Днепровский', label: 'Днепровский', id: 4 },
  { value: 'Оболонский', label: 'Оболонский', id: 5 },
  { value: 'Печерский', label: 'Печерский', id: 6 },
  { value: 'Подольский', label: 'Подольский', id: 7 },
  { value: 'Святошинский', label: 'Святошинский', id: 8 },
  { value: 'Соломенский', label: 'Соломенский', id: 9 },
  { value: 'Шевченковский', label: 'Шевченковский', id: 10 },
];

export const ResidenceListSingle = [
  { value: 'Заречный', label: 'Заречный', id: 1 },
  { value: 'Славутич', label: 'Славутич', id: 2 },
  { value: 'Севен', label: 'Севен', id: 3 },
  { value: 'Патриотика', label: 'Патриотика', id: 4 },
  { value: 'Молодежный', label: 'Молодежный', id: 5 },
  { value: 'Ривьера', label: 'Ривьера', id: 6 },
  { value: 'Южная Брама', label: 'Южная Брама', id: 7 },
  { value: 'Лебединый', label: 'Лебединый', id: 8 },
  { value: 'Ривер Стоун', label: 'Ривер Стоун', id: 9 },
  { value: 'Олимпик Парк', label: 'Олимпик Парк', id: 10 },
  { value: 'Метрополия', label: 'Метрополия', id: 11 },
  { value: 'Корона', label: 'Корона', id: 12 },
  { value: 'Англия', label: 'Англия', id: 13 },
];
