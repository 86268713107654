import { ROUTES } from '../constants/routes';
import axiosFlat from './axios/axiosFlat';
import getRouteURI from '../utils/getRouteURI';
import { getPayloadQueries } from '../utils/getPayloadQueries';

export const getFlats = async (payload: any) => {
  const { page, filtersPayload } = payload;

  const params = {
    ...getPayloadQueries(filtersPayload),
  };

  if (page > 1) {
    params.page = page;
  }

  const response = await axiosFlat.get(ROUTES.FLATS, {
    params,
    headers: {
      Authorization: localStorage.getItem('verticalAccessToken')?.toString() || '',
    },
  });
  return response;
};

export const filterFlatsService = async (queries: any) => {
  const response = await axiosFlat.get(ROUTES.FLATS, {
    params: queries,
    headers: {
      Authorization: localStorage.getItem('verticalAccessToken')?.toString() || '',
    },
  });
  return response;
};

export const getFlatService = async (uuid: string) => {
  const response = await axiosFlat.get(getRouteURI(ROUTES.FLAT, { uuid }), {
    headers: {
      // 'Content-Type': 'application/json',
      Authorization: localStorage.getItem('verticalAccessToken')?.toString() || '',
    },
  });
  return response;
};


export const getContactData = async (resourceKey: string, uuid: string) => {
  const response = await axiosFlat.get(getRouteURI(ROUTES.GET_CONTACT_DATA, { resourceKey, uuid }), {
    headers: {
      // 'Content-Type': 'application/json',
      Authorization: localStorage.getItem('verticalAccessToken')?.toString() || '',
    },
  });
  return response;
};

export const removeFile = async (flatUUID: string, fileName: string) => {
  const response = await axiosFlat.delete(getRouteURI(ROUTES.FLAT_REMOVE_FILE, { flatUUID, fileName }), {
    headers: {
      // 'Content-Type': 'application/json',
      Authorization: localStorage.getItem('verticalAccessToken')?.toString() || '',
    },
  });
  return response;
};

export const deleteFlatService = async (payload: any) => {
  const { page, filtersPayload, uuid } = payload;

  const params = {
    uuid,
    ...getPayloadQueries(filtersPayload),
  };

  if (page > 1) {
    params.page = page;
  }

  const response = await axiosFlat.delete(ROUTES.FLAT_DELETE, {
    params,
    headers: {
      // 'Content-Type': 'application/json',
      Authorization: localStorage.getItem('verticalAccessToken')?.toString() || '',
    },
  });
  return response;
};

export const getCustomerFlatsService = async (uuid: string) => {
  const response = await axiosFlat.get(getRouteURI(ROUTES.FLAT, { uuid }), {
    headers: {
      // 'Content-Type': 'application/json',
      Authorization: localStorage.getItem('verticalAccessToken')?.toString() || '',
    },
  });
  return response;
};
