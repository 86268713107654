import { AddNewFlatFormProps } from '../../types/Forms/FormPropsTypes';
import { Form, FormikProps, useFormikContext } from 'formik';
import { AddNewFlatFormValues } from '../../types/Forms/FormValuesTypes';
import {
  FormControlModalStyle,
  FormInputConstructorStyle,
  FormInputSearchPlaceholder,
} from '../../constants/styles/Forms/inputs';
import { FormLabelConstructorStyle, FormLabelModalStyle } from '../../constants/styles/Forms/labels';
import React, { memo, useCallback, useEffect } from 'react';
import { SubwayLinesList } from '../../mappings/Forms/inputs';
import { COLORS } from '../../constants/colors';
import { Button, CircularProgress, FormControl, FormGroup, FormLabel, Rating } from '@mui/material';
import CheckboxLabelConstructor from '../UI/Fields/Checkboxes/CheckboxLabelField';
import IconsBox from '../UI/Icons/IconsBox';
import { SubmitButtonMain } from '../../constants/styles/Buttons/SubmitButtons';
import AttachmentsContainer from '../../containers/UI/Attachments/AttachmentsContainer';
import { DistrictLinesSingle, ResidenceListSingle } from '../../mappings/Forms/selects';
import classes from '../Flats/Flats.module.scss';
import { useAppSelector } from '../../hooks/redux';
import { useFreezeWhileLoading } from '../../hooks/useFreezeWhileLoading';
import SelectMultipleContainer from '../../containers/UI/Fields/Selects/SelectMultipleContainer';
import InputFieldContainer from '../../containers/UI/Fields/Inputs/InputFieldContainer';
import StreetFieldContainer from '../../containers/Flats/Filter/StreetFieldContainer';
import RangeFieldContainer from '../../containers/UI/Fields/Ranges/RangeFieldContainer';
import SelectSingleFieldContainer from '../../containers/UI/Fields/Selects/SelectSingleFieldContainer';
import {
  BakeIcon,
  BathroomIcon,
  BedIcon,
  CatIcon,
  ChildrenIcon,
  DogIcon,
  ForeignersIcon,
  SmokingIcon,
  StudioIcon,
} from '../UI/Icons';
import CheckboxLabelFieldContainer from '../../containers/UI/Fields/Checkboxes/CheckboxLabelFieldContainer';
import IconsBoxContainer from '../../containers/UI/Icons/IconsBoxContainer';

const AddNewFlatFormUI = (props: AddNewFlatFormProps & FormikProps<AddNewFlatFormValues>) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    files,
    setFiles,
    flatPreview,
    isEditMode,
    handleChangeStreet,
    handleChangeSpecialField,
    webLinkData,
    handleGetContactData,
    setFieldValue,
  } = props;
  const { loading, street, special } = useAppSelector(state => state.flatReducer);

  useFreezeWhileLoading(loading);

  const formikProps = useFormikContext<AddNewFlatFormValues>();

  useEffect(() => {
    if (props.webLinkData && isEditMode && flatPreview?.webLink) {
      formikProps.setFieldValue('webLink', webLinkData);
    }
  }, [props.webLinkData]);

  const handleRenderValue = (field: any) => {
    let value = values[field as keyof AddNewFlatFormValues];

    // if (value === [undefined] && field === 'subwayStations') {
    //   value = [];
    // }

    if (Array.isArray(value) && value.length === 1 && value[0] === undefined && field === 'subwayStations') {
      value = [];
    }

    if (field === 'rating') {
      value = Number(values[field as keyof AddNewFlatFormValues]);
    }

    return value;
  }

  const customHandleChange = (field: string) => (event: any) => {
    if (event.target.value !== null && event.target.value !== undefined) {
      // Use Formik's setFieldValue function to set the field value
      setFieldValue(field, event.target.value);
    }
  };

  const handleValidateAddNewFlatField = useCallback(
    (field: string, type: string, customOnChange?: any) => {
      const value = handleRenderValue(field);

      return {
        type: type,
        name: field,
        value,
        onBlur: handleBlur,
        onChange: customOnChange ? customOnChange : handleChange,
        error: touched[field as keyof AddNewFlatFormValues] && Boolean(errors[field as keyof AddNewFlatFormValues]),
        helperText: touched[field as keyof AddNewFlatFormValues] && errors[field as keyof AddNewFlatFormValues],
      };
    },
    [errors, handleBlur, handleChange, touched, values],
  );

  const priceValidation = handleValidateAddNewFlatField('price', 'number');
  const districtValidation = handleValidateAddNewFlatField('district', 'select');
  const subwayStationsValidation = handleValidateAddNewFlatField('subwayStations', 'select', customHandleChange('subwayStations'));
  const timeToSubwayValidation = handleValidateAddNewFlatField('timeToSubway', 'number');
  const residenceValidation = handleValidateAddNewFlatField('residence', 'select');
  const floorsCurrentFloorValidation = handleValidateAddNewFlatField('floorsCurrent', 'number');
  const floorsTotalValidation = handleValidateAddNewFlatField('floorsTotal', 'number');
  const squareValidation = handleValidateAddNewFlatField('square', 'number');
  const roomsValidation = handleValidateAddNewFlatField('rooms', 'number');
  const notesValidation = handleValidateAddNewFlatField('notes', 'text');
  const webLinkValidation = handleValidateAddNewFlatField('webLink', 'text');
  const ratingValidation = handleValidateAddNewFlatField('rating', 'text');
  delete ratingValidation.helperText;
  const adTitleValidation = handleValidateAddNewFlatField('adTitle', 'text');
  const adTextValidation = handleValidateAddNewFlatField('adText', 'text');
  const exclusiveValidation = handleValidateAddNewFlatField('exclusive', 'checkbox');

  const renderWebLink = () => {
    if (isEditMode && flatPreview?.webLink && !webLinkData) {
      return <Button size="small" onClick={() => handleGetContactData('webLink')}>Показать Веб источник</Button>
    }

    return (
      <InputFieldContainer
        label="Веб источник"
        isInline
        formControlStyle={FormControlModalStyle('25px 0', 'unset')}
        formLabelStyle={FormLabelConstructorStyle(undefined, '0px', undefined, 'rgba(43, 60, 70, 1)')}
        formInputStyle={FormInputConstructorStyle('2px 0px', '100%', undefined, undefined, 'rgba(43, 60, 70, 1)')}
        formGroupStyle={{ width: '100%' }}
        value={webLinkValidation.value}
        handleChange={webLinkValidation.onChange}
        validation={webLinkValidation}
      />
    )
  }

  return (
    <Form style={{ margin: '0px 40px' }} className="form_add">
      <div className="d-flex justify-content-between flats_container">
        <div>
          <InputFieldContainer
            label="Цена"
            required
            isInline
            formControlStyle={FormControlModalStyle('50px 0 25px 0', 'unset')}
            formLabelStyle={FormLabelConstructorStyle(undefined, '0px', undefined, 'rgba(43, 60, 70, 1)')}
            formInputStyle={FormInputConstructorStyle('2px 0px', '100%', undefined, undefined, 'rgba(43, 60, 70, 1)')}
            formGroupStyle={{ width: '100%' }}
            value={priceValidation.value}
            handleChange={priceValidation.onChange}
            validation={priceValidation}
          />

          <SelectSingleFieldContainer
            label="Район"
            isInline
            required
            formControlStyle={FormControlModalStyle('25px 0px', 'unset')}
            formLabelStyle={FormLabelConstructorStyle(undefined, '0px', undefined, 'rgba(43, 60, 70, 1)')}
            formInputStyle={FormInputConstructorStyle('2px 0px', '100%', undefined, undefined, COLORS.LABELS_COLOR)}
            formGroupStyle={{ width: '100%', maxWidth: '367.14px' }}
            options={DistrictLinesSingle}
            validation={districtValidation}
            selectedValue={districtValidation.value}
            handleChange={districtValidation.onChange}
          />

          <SelectMultipleContainer
            formControlStyle={FormControlModalStyle('25px 0px', 'unset')}
            formLabelStyle={FormLabelConstructorStyle(undefined, '0px', undefined, 'rgba(43, 60, 70, 1)')}
            formInputStyle={FormInputConstructorStyle('2px 0px', '100%', undefined, undefined, 'rgba(43, 60, 70, 1)')}
            formGroupStyle={{ width: '100%', maxWidth: '247.44px' }}
            placeholderStyle={{ ...FormInputSearchPlaceholder, width: '100%!important' }}
            list={SubwayLinesList}
            isInline
            label="Станции метро"
            isGroup
            isSearchRequired
            customFormStyle={{ width: '100%!important' }}
            colorGroupedTitles={[COLORS.RED, COLORS.BLUE, COLORS.GREEN]}
            emPlaceholderColor={COLORS.LABELS_COLOR}
            selectedValue={subwayStationsValidation.value}
            handleChange={subwayStationsValidation.onChange}
            validation={subwayStationsValidation}
          />
          <InputFieldContainer
            label="До метро"
            isInline
            endPlaceholder="минут"
            formControlStyle={FormControlModalStyle('25px 0', 'unset')}
            formLabelStyle={FormLabelConstructorStyle(undefined, '0px', undefined, 'rgba(43, 60, 70, 1)')}
            formInputStyle={FormInputConstructorStyle('2px 0px', '300px', 0, undefined, 'rgba(43, 60, 70, 1)')}
            value={timeToSubwayValidation.value}
            handleChange={timeToSubwayValidation.onChange}
            validation={timeToSubwayValidation}
          />

          <StreetFieldContainer
            formControlStyle={FormControlModalStyle('25px 0px', 'unset')}
            formLabelStyle={FormLabelConstructorStyle(undefined, '0px', undefined, 'rgba(43, 60, 70, 1)')}
            formInputStyle={FormInputConstructorStyle('2px 0px', '100%', 0, undefined, 'rgba(43, 60, 70, 1)')}
            formGroupStyle={{ width: '100%' }}
            isInline
            required
            label="Адрес"
            svgColor={COLORS.BLACK}
            street={street}
            handleChange={handleChangeStreet}
          />
        </div>
        <div style={{ marginRight: '100px' }} className="test2">
          <div className="d-flex align-items-center zheka">
            <SelectSingleFieldContainer
              label="ЖК"
              isInline
              formControlStyle={FormControlModalStyle('50px 0 0 0', 'unset')}
              formLabelStyle={FormLabelConstructorStyle(undefined, '0px', undefined, 'rgba(43, 60, 70, 1)')}
              formInputStyle={FormInputConstructorStyle('2px 0px', '270px', 4, undefined, 'rgba(43, 60, 70, 1)')}
              formGroupStyle={{ width: '100%' }}
              options={ResidenceListSingle}
              validation={residenceValidation}
              selectedValue={residenceValidation.value}
              handleChange={residenceValidation.onChange}
            />
            <RangeFieldContainer
              label="Этажность / этаж"
              labels={['', '']}
              formControlStyle={FormControlModalStyle('50px 0 0 0', 'unset')}
              formLabelStyle={FormLabelConstructorStyle(undefined, '0px', undefined, 'rgba(43, 60, 70, 1)')}
              formInputStyle={FormInputConstructorStyle('initial', undefined)}
              currentType="numeric"
              removeMarginRight
              required
              handleChange={() => {}}
              values={[floorsTotalValidation.value, floorsCurrentFloorValidation.value]}
              validateMultiple={[floorsTotalValidation, floorsCurrentFloorValidation]}
            />
          </div>
          <div className="d-flex align-items-center ploshad">
            <InputFieldContainer
              label="Площадь"
              required
              isInline
              formControlStyle={FormControlModalStyle('25px 0px', 'unset')}
              formLabelStyle={FormLabelConstructorStyle(undefined, '0px', undefined, 'rgba(43, 60, 70, 1)')}
              formInputStyle={FormInputConstructorStyle('2px 0px', '60px', 4, undefined, 'rgba(43, 60, 70, 1)')}
              formGroupStyle={{ width: '100%' }}
              value={squareValidation.value}
              handleChange={squareValidation.onChange}
              validation={squareValidation}
            />
            <InputFieldContainer
              label="Кол-во комнат"
              required
              isInline
              formControlStyle={FormControlModalStyle('25px 0px', 'unset')}
              formLabelStyle={FormLabelConstructorStyle(undefined, '0px', undefined, 'rgba(43, 60, 70, 1)')}
              formInputStyle={FormInputConstructorStyle('2px 0px', '60px', 4, undefined, 'rgba(43, 60, 70, 1)')}
              formGroupStyle={{ width: '100%' }}
              value={roomsValidation.value}
              handleChange={roomsValidation.onChange}
              validation={roomsValidation}
            />
            <FormControl sx={FormControlModalStyle('10px 0px', 'unset')} component="fieldset">
              <FormLabel
                sx={FormLabelConstructorStyle(undefined, '0px', undefined, 'rgba(43, 60, 70, 1)')}
                component="legend"
              />
              <FormGroup>
                <CheckboxLabelFieldContainer
                  label="Эксклюзив"
                  formLabelStyle={FormLabelModalStyle(undefined, '0px')}
                  validation={exclusiveValidation}
                  handleChange={exclusiveValidation.onChange}
                  checked={exclusiveValidation.value}
                />
              </FormGroup>
            </FormControl>
          </div>
          <div className="d-flex align-items-center newFlat">
            <IconsBoxContainer
              icons={[
                BedIcon,
                StudioIcon,
                ChildrenIcon,
                DogIcon,
                CatIcon,
                SmokingIcon,
                ForeignersIcon,
                BathroomIcon,
                BakeIcon,
              ]}
              fill={COLORS.GENERAL}
              isInverse
              iconsState={special || {}}
              handleChange={handleChangeSpecialField}
            />
          </div>
          {/* TODO: Add constructor*/}
          <div className={classes.FlatsItemAdditionalInfoStatItem}>
            <Rating sx={{ fontSize: '30px', marginTop: '30px' }} precision={0.5} {...ratingValidation} />
          </div>
        </div>
      </div>

      <div style={{ margin: '25px 0 50px 0' }}>
        <AttachmentsContainer files={files} setFiles={setFiles} isEditMode={isEditMode} flatPreview={flatPreview} />
      </div>

      <div>
        {renderWebLink()}
        <InputFieldContainer
          label="Важная информация"
          isInline
          formControlStyle={FormControlModalStyle('25px 0 10px 0', 'unset')}
          formLabelStyle={FormLabelConstructorStyle(undefined, '0px', undefined, 'rgba(43, 60, 70, 1)')}
          formInputStyle={FormInputConstructorStyle('2px 0px', '100%', undefined, undefined, 'rgba(43, 60, 70, 1)')}
          formGroupStyle={{ width: '100%' }}
          value={notesValidation.value}
          handleChange={notesValidation.onChange}
          validation={notesValidation}
        />
        <p
          style={{
            // marginBottom: '15px',
            fontSize: '12px',
            color: values.notes && values.notes.length >= 290 ? 'red' : '#7d7979',
            textAlign: 'end',
          }}
        >
          {values.notes?.length}/290
        </p>
        <InputFieldContainer
          label="Рекламный заголовок"
          isInline
          required
          formControlStyle={FormControlModalStyle('15px 0 25px 0', 'unset')}
          formLabelStyle={FormLabelConstructorStyle(undefined, '0px', undefined, 'rgba(43, 60, 70, 1)')}
          formInputStyle={FormInputConstructorStyle('2px 0px', '100%', undefined, undefined, 'rgba(43, 60, 70, 1)')}
          formGroupStyle={{ width: '100%' }}
          value={adTitleValidation.value}
          handleChange={adTitleValidation.onChange}
          validation={adTitleValidation}
        />

        <InputFieldContainer
          label="Рекламный текст"
          isInline
          required
          rows={4}
          formControlStyle={FormControlModalStyle('25px 0', 'unset')}
          formLabelStyle={FormLabelConstructorStyle(undefined, '0px', undefined, 'rgba(43, 60, 70, 1)')}
          formInputStyle={FormInputConstructorStyle('2px 0px', '100%', undefined, undefined, 'rgba(43, 60, 70, 1)')}
          formGroupStyle={{ width: '100%' }}
          value={adTextValidation.value}
          handleChange={adTextValidation.onChange}
          validation={adTextValidation}
          multiline
        />
      </div>

      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          marginTop: '50px',
          marginBottom: '100px',
        }}
      >
        <SubmitButtonMain
          type="submit"
          disabled={loading}
          variant="contained"
          sx={{
            marginRight: '25px',
            width: '400px!important',
          }}
        >
          <p>{isEditMode && flatPreview ? 'Изменить' : 'Добавить'}</p>
          {loading && <CircularProgress size="1.3em" sx={{ marginLeft: '10px' }} />}
        </SubmitButtonMain>
        {isEditMode && flatPreview ? null : (
          <SubmitButtonMain
            // disabled={isSubmitting}
            disabled
            variant="contained"
            sx={{ width: '400px!important', background: '#fff', color: '#000' }}
          >
            <p>Черновик</p>
          </SubmitButtonMain>
        )}
      </div>
    </Form>
  );
};

export default memo(AddNewFlatFormUI);
