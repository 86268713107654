import React, { FC, memo } from 'react';
import { FormControl, FormGroup, FormLabel, InputAdornment, TextField } from '@mui/material';
import { InputLabelPropsStyle } from '../../../../constants/styles/Forms/labels';
import { InputAdornmentStyle, InputEndAdornmentStyle } from '../../../../constants/styles/Forms/inputs';
import { InputFieldComponentProps } from '../../../../types/Forms/FormPropsTypes';
import { COLORS } from '../../../../constants/colors';

const InputField: FC<InputFieldComponentProps> = ({
  label,
  required,
  formControlStyle,
  formLabelStyle,
  formInputStyle,
  formGroupStyle,
  placeholder,
  isInline = false,
  validation,
  multiline,
  rows,
  endPlaceholder,
  name,
  handleChange,
  value,
  disableAutoComplete,
}) => {
  const highlightError = {
    '.MuiOutlinedInput-root fieldset': {
      borderColor: validation && validation.error ? `${COLORS.ERROR}!important` : 'inherit',
    },
  };

  const renderInputPlaceholder = () => {
    if (placeholder) {
      return {
        startAdornment: (
          <InputAdornment sx={InputAdornmentStyle} position="start">
            {placeholder} -
          </InputAdornment>
        ),
      };
    }

    if (endPlaceholder) {
      return {
        endAdornment: (
          <InputAdornment sx={InputEndAdornmentStyle} position="start">
            {endPlaceholder}
          </InputAdornment>
        ),
      };
    }

    return {};
  };

  return (
    <FormControl sx={formControlStyle} variant="standard">
      <div className={isInline ? 'd-flex align-items-center subway_time' : ''}>
        <FormLabel sx={formLabelStyle} component="legend">
          {required ? (
            <p>
              {label}
              <span className="requiredLabel">*</span>
            </p>
          ) : (
            label
          )}
        </FormLabel>
        <FormGroup sx={formGroupStyle}>
          <TextField
            id="outlined-basic"
            sx={{ ...formInputStyle, ...highlightError }}
            variant="outlined"
            multiline={multiline}
            rows={rows}
            autoComplete={disableAutoComplete ? 'off' : 'on'}
            onChange={handleChange}
            name={name}
            value={value}
            InputProps={renderInputPlaceholder()}
            InputLabelProps={{
              style: {
                ...InputLabelPropsStyle(),
              },
            }}
            {...validation}
          />
        </FormGroup>
      </div>
    </FormControl>
  );
};

export default memo(InputField);
