import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/UserSlice';
import flatFiltersReducer from './reducers/FlatFiltersSlice';
import homeReducer from './reducers/HomeSlice';
import customerReducer from './reducers/CustomersSlice';
import flatReducer from './reducers/FlatSlice';

const rootReducer = combineReducers({
  userReducer,
  flatFiltersReducer,
  homeReducer,
  customerReducer,
  flatReducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
