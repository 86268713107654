export const formatStreetAddress = (streetAddress: string | undefined): string => {
  if (!streetAddress) {
    return '';
  }

  const currentAddress = streetAddress
    .replaceAll(/\b\d{5}\b/g, '')
    .replaceAll('Украина', '')
    .split(',')
    .map(item => {
      if (item.includes('район') || item.includes('область')) {
        return '';
      }
      return item;
    })
    .join(',')
    .trim()
    .slice(0, -1)
    .replaceAll(',,', ',');

  return currentAddress;
};
