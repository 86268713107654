import { AddCustomerFormProps } from '../../types/Forms/FormPropsTypes';
import { Form, FormikProps, useFormikContext } from 'formik';
import { AddCustomerFormValues, AddNewFlatFormValues } from '../../types/Forms/FormValuesTypes';
import {
  FormControlModalStyle,
  FormInputConstructorStyle,
  FormInputModalStyle
} from '../../constants/styles/Forms/inputs';
import { FormLabelConstructorStyle, FormLabelModalStyle } from '../../constants/styles/Forms/labels';
import { FormSelectModalStyle } from '../../constants/styles/Forms/selects';
import { ClientTypeSelectOptions } from '../../mappings/Forms/selects';
import { Button, CircularProgress, FormControl, FormGroup, FormLabel, IconButton, TextField } from '@mui/material';
import PhoneNumber from '../UI/Fields/Inputs/PhoneNumber';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { SubmitButtonMain } from '../../constants/styles/Buttons/SubmitButtons';
import React, { memo, useCallback, useEffect } from 'react';
import { useAppSelector } from '../../hooks/redux';
import { useFreezeWhileLoading } from '../../hooks/useFreezeWhileLoading';
import InputFieldContainer from '../../containers/UI/Fields/Inputs/InputFieldContainer';
import SelectSingleFieldContainer from '../../containers/UI/Fields/Selects/SelectSingleFieldContainer';

const AddCustomerFormUI = (props: AddCustomerFormProps & FormikProps<AddCustomerFormValues>) => {
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    phones,
    setPhones,
    isEditMode,
    phonesData,
    handleGetContactData,
  } = props;
  const { loading } = useAppSelector(state => state.customerReducer);

  useFreezeWhileLoading(loading);

  const formikProps = useFormikContext<AddNewFlatFormValues>();

  const handleValidateCustomerField = useCallback(
    (field: string, type: string) => ({
      type: type,
      name: field,
      value: values[field as keyof AddCustomerFormValues],
      onBlur: handleBlur,
      onChange: handleChange,
      error: touched[field as keyof AddCustomerFormValues] && Boolean(errors[field as keyof AddCustomerFormValues]),
      helperText: touched[field as keyof AddCustomerFormValues] && errors[field as keyof AddCustomerFormValues],
    }),
    [errors, handleBlur, handleChange, touched, values],
  );

  const nameValidation = handleValidateCustomerField('name', 'text');
  const typeValidation = handleValidateCustomerField('type', 'select');
  const emailValidation = handleValidateCustomerField('email', 'text');
  const commentValidation = handleValidateCustomerField('comment', 'text');

  const handleAddPhone = useCallback(
    () =>
      setPhones((prev: any) => {
        return [...prev, prev.length + 1];
      }),
    [setPhones],
  );

  const handleRemovePhone = useCallback(
    (phone: number) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      values[`phone${phone}`] = '';
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      errors[`phone${phone}`] = '';
      setPhones((prev: any) => {
        return prev.filter((currentPhone: any) => currentPhone !== phone);
      });
    },
    [errors, setPhones, values],
  );

  useEffect(() => {
    if (phonesData.length && isEditMode) {
      formikProps.setFieldValue('phone1', phonesData[0]?.slice(1));
      formikProps.setFieldValue('phone2', phonesData[1]?.slice(1));
      formikProps.setFieldValue('phone3', phonesData[2]?.slice(1));
    }
  }, [phonesData]);

  const renderPhones = () => {
    if (isEditMode && !phonesData.length) {
      return <Button size="small" onClick={() => handleGetContactData('phone')}>Показать Телефоны клиента</Button>
    }

    return (
      <FormControl sx={FormControlModalStyle('25px 0px', 'unset')} variant="standard">
        <div className="d-flex align-items-start client_phones">
          <FormLabel sx={FormLabelModalStyle(undefined, '0px')} component="legend">
            <p>
              Телефоны клиента<span className="requiredLabel">*</span>
            </p>
          </FormLabel>

          <FormGroup sx={{ width: '100%' }}>
            {phones.map((phone: any) => {
              return (
                <div className="d-flex" style={{ position: 'relative' }} key={`phone-${phone}`}>
                  <PhoneNumber
                    formInputStyle={{
                      ...FormInputModalStyle('2px 0px', '98%'),
                      marginBottom: '10px',
                      caretColor: '#2F6D8E',
                      '& .MuiFormHelperText-root': {
                        position: 'unset',
                      },
                    }}
                    validation={handleValidateCustomerField(`phone${phone}`, 'number')}
                  />
                  {phone !== 1 && (
                    <IconButton
                      aria-label="remove"
                      disabled={phones.length === 3 && phone === 2}
                      onClick={() => handleRemovePhone(phone)}
                      sx={{ alignSelf: 'baseline', position: 'absolute', left: '100%' }}
                    >
                      <RemoveIcon />
                    </IconButton>
                  )}
                </div>
              );
            })}
          </FormGroup>
          <IconButton
            aria-label="add"
            onClick={handleAddPhone}
            sx={{ alignSelf: 'baseline' }}
            disabled={phones.length === 3}
          >
            <AddIcon />
          </IconButton>
        </div>
      </FormControl>
    )
  }

  return (
    <Form>
      <div>
        <InputFieldContainer
          label="Ф.И.О. / Название"
          required
          isInline
          formControlStyle={FormControlModalStyle('25px 0px 10px 0px', 'unset')}
          formLabelStyle={FormLabelModalStyle(undefined, '0px')}
          formInputStyle={FormInputModalStyle('2px 0px', '100%')}
          formGroupStyle={{ width: '100%' }}
          value={nameValidation.value}
          handleChange={nameValidation.onChange}
          validation={nameValidation}
        />
        <p
          style={{
            fontSize: '12px',
            color: values.name.length >= 60 ? 'red' : '#7d7979',
            textAlign: 'end',
          }}
        >
          {values.name?.length}/60
        </p>
        <SelectSingleFieldContainer
          formControlStyle={FormControlModalStyle('10px 0px 25px 0px', 'unset')}
          formLabelStyle={FormLabelModalStyle(undefined, '0px')}
          formInputStyle={FormSelectModalStyle('2px 4px', '100%')}
          formGroupStyle={{ width: '100%' }}
          required
          isInline
          label="Тип клиента"
          options={ClientTypeSelectOptions}
          validation={typeValidation}
          selectedValue={typeValidation.value}
          handleChange={typeValidation.onChange}
        />

        <InputFieldContainer
          label="E-mail"
          isInline
          formControlStyle={FormControlModalStyle('25px 0px', 'unset')}
          formLabelStyle={FormLabelModalStyle(undefined, '0px')}
          formInputStyle={FormInputModalStyle('2px 0px', '100%')}
          formGroupStyle={{ width: '100%' }}
          value={emailValidation.value}
          handleChange={emailValidation.onChange}
          validation={emailValidation}
        />
      </div>
      <div>
        {renderPhones()}
      </div>
      <TextField
        fullWidth
        placeholder="Коментарий"
        multiline
        rows={4}
        sx={{ marginBottom: '15px' }}
        {...commentValidation}
      />
      <p
        style={{
          marginBottom: '15px',
          fontSize: '12px',
          color: values.comment && values.comment.length >= 330 ? 'red' : '#7d7979',
          textAlign: 'end',
        }}
      >
        {values.comment?.length}/330
      </p>

      <SubmitButtonMain
        type="submit"
        disabled={isSubmitting}
        variant="contained"
        fullWidth
        startIcon={isEditMode ? null : <AddIcon sx={{ width: '23px', height: '23px' }} />}
        sx={{
          width: '100%',
        }}
      >
        <p>{isEditMode ? 'Изменить' : 'Добавить'}</p>
        {loading && <CircularProgress size="1.3em" sx={{ marginLeft: '10px' }} />}
      </SubmitButtonMain>
    </Form>
  );
};

export default memo(AddCustomerFormUI);
