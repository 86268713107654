import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import AddNewFlat from '../../../components/Flats/new/AddNewFlat';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getCurrentCustomer } from '../../../store/actions/Customer/CustomerThunk';
import { addFlat, addOLXFlat, editFlat, getFlat, getResourceContactData } from '../../../store/actions/Flats/FlatThunk';
import {
  clearUIOptionsAddFlat,
  setFlatOption,
  setFlatWebLink,
  setSpecialFields
} from '../../../store/actions/Flats/FlatActions';
import { STATUSES } from '../../../constants/statuses';
import { APP_ROUTES } from '../../../constants/routes';
import { IFile } from '../../../models/IFile';
import { AddNewFlatFormValues, AddNewFlatOLXFormValues, EditFlatInterface } from '../../../types/Forms/FormValuesTypes';
import { Notify } from '../../../utils/notifications';
import getRouteURI from '../../../utils/getRouteURI';
import { checkIfPayloadSame } from '../../../utils/checkIfPayloadSame';
import { PROPERTY_TYPES } from '../../../mappings/Forms/checkboxes';
import { PropertyState } from '../../../types/Forms/FormStateTypes';
import AddNewFlatOLX from '../../../components/Flats/new/AddNewFlatOLX';

const AddNewFlatOLXContainer: FC = () => {
  const { customer } = useAppSelector(state => state.customerReducer);
  const {
    street,
    propertyType,
  } = useAppSelector(state => state.flatReducer);

  const { uuid } = useParams();
  const navigate = useNavigate();
  const location: any = useLocation();
  // TODO: Think on route comparison as redux state is clearing
  const isRouteAddingMode = !!location.pathname.includes('addFlat');
  const dispatch = useAppDispatch();

  const [propertyState, setPropertyState] = useState<PropertyState>({
    flat: true,
    room: false,
    house: false,
    commercial: false,
  });

  const handleGetCustomer = useCallback(
    async (customerUUID: string | undefined) => {
      if (!customerUUID) {
        return null;
      }
      await dispatch(getCurrentCustomer(customerUUID));
      return true;
    },
    [dispatch],
  );

  const handleAddNewFlat = useCallback(
    async (values: AddNewFlatOLXFormValues) => {
      if (!street) {
        return Notify({ message: 'Необходимо выбрать улицу', type: 'error' });
      }

      if (!customer) {
        return Notify({ message: 'Необходимо выбрать клиента', type: 'error' });
      }

      const dataToSend = {
        customerUUID: customer.uuid,
        propertyType,
        clientType: customer.type,
        ...values,
        street,
      }

      const { payload } = await dispatch(addOLXFlat(dataToSend as any));

      if (payload.status === STATUSES.SUCCESS) {
        Notify({
          message: payload.notification.text,
          type: 'success',
        });

        return navigate(getRouteURI(APP_ROUTES.FLAT_VIEW, { uuid: payload.uuid }));
      } else {
        return Notify({
          message: payload.text,
          type: 'error',
        });
      }
    },
    [street, customer, propertyType, dispatch, navigate],
  );

  const onInitialize = useCallback(async () => {
    if (isRouteAddingMode && uuid) {
      await handleGetCustomer(uuid);
    }

    return false;
  }, [dispatch, handleGetCustomer, isRouteAddingMode, uuid]);

  // TODO: propertyState loop occurs when we change propertyType
  const initPropertyType = useCallback(() => {
    return dispatch(
      setFlatOption({
        type: 'propertyType',
        value: Object.keys(propertyState)
          .filter(key => propertyState[key])
          .map(key => PROPERTY_TYPES[key])
          .join(' '),
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    initPropertyType();
  }, [initPropertyType]);

  const handleChangePropertyType = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // It is single select method

      if (propertyState && propertyState[event.target.name]) {
        return;
      }

      const newState = JSON.parse(JSON.stringify(propertyState));
      Object.keys(newState).forEach(key => (newState[key] = false));
      const result = {
        ...newState,
        [event.target.name]: true,
      };
      setPropertyState(result);
      dispatch(
        setFlatOption({
          type: 'propertyType',
          value: Object.keys(result)
            .filter(key => result[key])
            .map(key => PROPERTY_TYPES[key])
            .join(' '),
        }),
      );
    },
    [dispatch, propertyState],
  );

  const handleChangeStreet = useCallback(
    (value: string) => {
      return dispatch(setFlatOption({ type: 'street', value }));
    },
    [dispatch],
  );

  useEffect(() => {
    onInitialize();
    return () => {
      dispatch(clearUIOptionsAddFlat());
    };
  }, [dispatch, onInitialize]);

  if (!customer) {
    return null;
  }

  return (
    <AddNewFlatOLX
      customer={customer}
      handleAddNewFlat={handleAddNewFlat}
      propertyTypeState={propertyState}
      handleChangePropertyType={handleChangePropertyType}
      handleChangeStreet={handleChangeStreet}
    />
  );
};

export default AddNewFlatOLXContainer;
