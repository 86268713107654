import { AppDispatch } from '../../../store';
import { flatFiltersSlice } from '../../../reducers/FlatFiltersSlice';
import { ConditionalPayloadOptions } from '../../../reducers/FlatSlice';
import { PropertyState } from '../../../../types/Forms/FormStateTypes';

export const subwayDistrictFilterToggle = (payload: string) => (dispatch: AppDispatch) =>
  dispatch(flatFiltersSlice.actions.toggleSubwayDistrictFilter(payload));

export const streetResidenceFilterToggle = (payload: string) => (dispatch: AppDispatch) =>
  dispatch(flatFiltersSlice.actions.toggleStreetResidenceFilter(payload));

export const setNewAllOptionsSelected = (payload: ConditionalPayloadOptions) => (dispatch: AppDispatch) => {
  if (!payload) {
    return null;
  }
  return dispatch(flatFiltersSlice.actions.setAllOptionsSelected(payload));
};

export const setCurrentPropertyTypeState = (payload: PropertyState) => (dispatch: AppDispatch) => {
  if (!payload) {
    return null;
  }
  return dispatch(flatFiltersSlice.actions.setPropertyTypeState(payload));
};

export const setNewFilterOption = (payload: ConditionalPayloadOptions) => (dispatch: AppDispatch) => {
  if (!payload) {
    return null;
  }
  return dispatch(flatFiltersSlice.actions.setFilterOption(payload));
};
