import React from 'react';
import { SVGIconProps } from '../../../../types/UI/Icons/SVGIconsTypes';

export const LocationIcon: React.FC<SVGIconProps> = ({ width = 20.5, height = 24, clazz }) => (
  <svg width={width || 20.5} height={height || 24} viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.25 12.9C11.6652 12.9 12.8125 11.6912 12.8125 10.2C12.8125 8.70883 11.6652 7.5 10.25 7.5C8.83477 7.5 7.6875 8.70883 7.6875 10.2C7.6875 11.6912 8.83477 12.9 10.25 12.9Z"
      stroke="#C68A52"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={clazz}
    />
    <path
      d="M10.2493 3C8.43704 3 6.69895 3.75857 5.41745 5.10883C4.13595 6.45909 3.41602 8.29044 3.41602 10.2C3.41602 11.9028 3.75939 13.017 4.69727 14.25L10.2493 21L15.8014 14.25C16.7393 13.017 17.0827 11.9028 17.0827 10.2C17.0827 8.29044 16.3627 6.45909 15.0812 5.10883C13.7997 3.75857 12.0617 3 10.2493 3V3Z"
      stroke="#C68A52"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={clazz}
    />
  </svg>
);
