import React from 'react';
import { SVGIconProps } from '../../../../types/UI/Icons/SVGIconsTypes';

export const GpsIcon: React.FC<SVGIconProps> = ({ width = 24, height = 24, clazz }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.4132 3.90003L4.13215 10.226L8.96115 12.232C10.3727 12.8175 11.4941 13.9393 12.0792 15.351L14.0852 20.179L20.4132 3.90003ZM22.2602 4.58203L15.9322 20.863C15.5322 21.893 14.3812 22.42 13.3612 22.043C13.1126 21.9512 12.8857 21.8091 12.6947 21.6255C12.5037 21.4419 12.3527 21.2208 12.2512 20.976L10.2442 16.146C9.85372 15.2054 9.10602 14.458 8.16515 14.068L3.33715 12.062C2.33315 11.645 1.87515 10.486 2.31415 9.47203C2.422 9.22417 2.5778 9.00009 2.77259 8.81268C2.96738 8.62527 3.19731 8.47823 3.44915 8.38003L19.7302 2.05203C20.0838 1.90989 20.4715 1.87481 20.845 1.95115C21.2184 2.02749 21.5612 2.21188 21.8308 2.48142C22.1003 2.75096 22.2847 3.09376 22.361 3.46722C22.4374 3.84068 22.4023 4.22834 22.2602 4.58203Z"
      fill="#C68A52"
      className={clazz}
    />
  </svg>
);
