import React, { FC, memo } from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

interface MainWrapperProps {
  children: React.ReactNode;
}

const MainWrapper: FC<MainWrapperProps> = ({ children }) => {
  return (
    <>
      <Navbar />
      {children}
      <Footer />
    </>
  );
};

export default memo(MainWrapper);
