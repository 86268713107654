import {
  SelectMultipleFilterConstructorGroupedList,
  SelectMultipleFilterConstructorType,
} from '../../types/Forms/Fields/Selects/SelectTypes'; /* Options for Subway Filter field */

/* Options for Subway Filter field */
export const SubwayLines: SelectMultipleFilterConstructorType[] = [
  { title: 'Академгородок', line: 'red', lineTitle: 'Красная ветка', id: 1 },
  { title: 'Житомирская', line: 'red', lineTitle: 'Красная ветка', id: 2 },
  { title: 'Святошин', line: 'red', lineTitle: 'Красная ветка', id: 3 },
  { title: 'Нивки', line: 'red', lineTitle: 'Красная ветка', id: 4 },
  { title: 'Шулявская', line: 'red', lineTitle: 'Красная ветка', id: 5 },
  { title: 'Политехнический институт', line: 'red', lineTitle: 'Красная ветка', id: 6 },
  { title: 'Вокзальная', line: 'red', lineTitle: 'Красная ветка', id: 7 },
  { title: 'Университет', line: 'red', lineTitle: 'Красная ветка', id: 8 },
  { title: 'Театральная', line: 'red', lineTitle: 'Красная ветка', id: 9 },
  { title: 'Крещатик', line: 'red', lineTitle: 'Красная ветка', id: 10 },
  { title: 'Арсенальная', line: 'red', lineTitle: 'Красная ветка', id: 11 },
  { title: 'Днепр', line: 'red', lineTitle: 'Красная ветка', id: 12 },
  { title: 'Гидропарк', line: 'red', lineTitle: 'Красная ветка', id: 13 },
  { title: 'Левобережная', line: 'red', lineTitle: 'Красная ветка', id: 14 },
  { title: 'Дарница', line: 'red', lineTitle: 'Красная ветка', id: 15 },
  { title: 'Черниговская', line: 'red', lineTitle: 'Красная ветка', id: 17 },
  { title: 'Лесная', line: 'red', lineTitle: 'Красная ветка', id: 18 },
  { title: 'Героев Днепра', line: 'blue', lineTitle: 'Синяя ветка', id: 19 },
  { title: 'Минская', line: 'blue', lineTitle: 'Синяя ветка', id: 20 },
  { title: 'Оболонь', line: 'blue', lineTitle: 'Синяя ветка', id: 21 },
  { title: 'Почайна', line: 'blue', lineTitle: 'Синяя ветка', id: 22 },
  { title: 'Тараса Шевченко', line: 'blue', lineTitle: 'Синяя ветка', id: 23 },
  { title: 'Контрактовая площадь', line: 'blue', lineTitle: 'Синяя ветка', id: 24 },
  { title: 'Почтовая площадь', line: 'blue', lineTitle: 'Синяя ветка', id: 25 },
  { title: 'Площадь Независимости', line: 'blue', lineTitle: 'Синяя ветка', id: 26 },
  { title: 'Площадь Льва Толстого', line: 'blue', lineTitle: 'Синяя ветка', id: 27 },
  { title: 'Олимпийская', line: 'blue', lineTitle: 'Синяя ветка', id: 28 },
  { title: 'Дворец Украина', line: 'blue', lineTitle: 'Синяя ветка', id: 29 },
  { title: 'Лыбедская', line: 'blue', lineTitle: 'Синяя ветка', id: 30 },
  { title: 'Демиевская', line: 'blue', lineTitle: 'Синяя ветка', id: 31 },
  { title: 'Голосеевская', line: 'blue', lineTitle: 'Синяя ветка', id: 32 },
  { title: 'Васильковская', line: 'blue', lineTitle: 'Синяя ветка', id: 33 },
  { title: 'Выставочный центр', line: 'blue', lineTitle: 'Синяя ветка', id: 34 },
  { title: 'Ипподром', line: 'blue', lineTitle: 'Синяя ветка', id: 35 },
  { title: 'Теремки', line: 'blue', lineTitle: 'Синяя ветка', id: 36 },
  { title: 'Сырец', line: 'green', lineTitle: 'Зеленая ветка', id: 37 },
  { title: 'Дорогожичи', line: 'green', lineTitle: 'Зеленая ветка', id: 38 },
  { title: 'Лукьяновская', line: 'green', lineTitle: 'Зеленая ветка', id: 39 },
  { title: 'Золотые ворота', line: 'green', lineTitle: 'Зеленая ветка', id: 40 },
  { title: 'Дворец спорта', line: 'green', lineTitle: 'Зеленая ветка', id: 41 },
  { title: 'Кловская', line: 'green', lineTitle: 'Зеленая ветка', id: 42 },
  { title: 'Печерская', line: 'green', lineTitle: 'Зеленая ветка', id: 43 },
  { title: 'Дружбы народов', line: 'green', lineTitle: 'Зеленая ветка', id: 44 },
  { title: 'Выдубичи', line: 'green', lineTitle: 'Зеленая ветка', id: 45 },
  { title: 'Славутич', line: 'green', lineTitle: 'Зеленая ветка', id: 46 },
  { title: 'Осокорки', line: 'green', lineTitle: 'Зеленая ветка', id: 47 },
  { title: 'Позняки', line: 'green', lineTitle: 'Зеленая ветка', id: 48 },
  { title: 'Харьковская', line: 'green', lineTitle: 'Зеленая ветка', id: 49 },
  { title: 'Вырлица', line: 'green', lineTitle: 'Зеленая ветка', id: 50 },
  { title: 'Бориспольская', line: 'green', lineTitle: 'Зеленая ветка', id: 51 },
  { title: 'Красный хутор', line: 'green', lineTitle: 'Зеленая ветка', id: 52 },
];

export const DistrictLines: SelectMultipleFilterConstructorType[] = [
  { title: 'Голосеевский', id: 1 },
  { title: 'Дарницкий', id: 2 },
  { title: 'Деснянский', id: 3 },
  { title: 'Днепровский', id: 4 },
  { title: 'Оболонский', id: 5 },
  { title: 'Печерский', id: 6 },
  { title: 'Подольский', id: 7 },
  { title: 'Святошинский', id: 8 },
  { title: 'Соломенский', id: 9 },
  { title: 'Шевченковский', id: 10 },
];

export const ResidenceList: SelectMultipleFilterConstructorType[] = [
  { title: 'Заречный', id: 1 },
  { title: 'Славутич', id: 2 },
  { title: 'Севен', id: 3 },
  { title: 'Патриотика', id: 4 },
  { title: 'Молодежный', id: 5 },
  { title: 'Ривьера', id: 6 },
  { title: 'Южная Брама', id: 7 },
  { title: 'Лебединый', id: 8 },
  { title: 'Ривер Стоун', id: 9 },
  { title: 'Олимпик Парк', id: 10 },
  { title: 'Метрополия', id: 11 },
  { title: 'Корона', id: 12 },
  { title: 'Англия', id: 13 },
];

export const SubwayLinesList: SelectMultipleFilterConstructorGroupedList[] = [
  {
    id: 123,
    title: 'Красная ветка',
    data: [
      { title: 'Академгородок', line: 'red', lineTitle: 'Красная ветка', id: 1 },
      { title: 'Житомирская', line: 'red', lineTitle: 'Красная ветка', id: 2 },
      { title: 'Святошин', line: 'red', lineTitle: 'Красная ветка', id: 3 },
      { title: 'Нивки', line: 'red', lineTitle: 'Красная ветка', id: 4 },
      { title: 'Берестейская', line: 'red', lineTitle: 'Красная ветка', id: 4 },
      { title: 'Шулявская', line: 'red', lineTitle: 'Красная ветка', id: 5 },
      { title: 'Политехнический институт', line: 'red', lineTitle: 'Красная ветка', id: 6 },
      { title: 'Вокзальная', line: 'red', lineTitle: 'Красная ветка', id: 7 },
      { title: 'Университет', line: 'red', lineTitle: 'Красная ветка', id: 8 },
      { title: 'Театральная', line: 'red', lineTitle: 'Красная ветка', id: 9 },
      { title: 'Крещатик', line: 'red', lineTitle: 'Красная ветка', id: 10 },
      { title: 'Арсенальная', line: 'red', lineTitle: 'Красная ветка', id: 11 },
      { title: 'Днепр', line: 'red', lineTitle: 'Красная ветка', id: 12 },
      { title: 'Гидропарк', line: 'red', lineTitle: 'Красная ветка', id: 13 },
      { title: 'Левобережная', line: 'red', lineTitle: 'Красная ветка', id: 14 },
      { title: 'Дарница', line: 'red', lineTitle: 'Красная ветка', id: 15 },
      { title: 'Черниговская', line: 'red', lineTitle: 'Красная ветка', id: 17 },
      { title: 'Лесная', line: 'red', lineTitle: 'Красная ветка', id: 18 },
    ],
  },
  {
    id: 124,
    title: 'Синяя ветка',
    data: [
      { title: 'Героев Днепра', line: 'blue', lineTitle: 'Синяя ветка', id: 19 },
      { title: 'Минская', line: 'blue', lineTitle: 'Синяя ветка', id: 20 },
      { title: 'Оболонь', line: 'blue', lineTitle: 'Синяя ветка', id: 21 },
      { title: 'Почайна', line: 'blue', lineTitle: 'Синяя ветка', id: 22 },
      { title: 'Тараса Шевченко', line: 'blue', lineTitle: 'Синяя ветка', id: 23 },
      { title: 'Контрактовая площадь', line: 'blue', lineTitle: 'Синяя ветка', id: 24 },
      { title: 'Почтовая площадь', line: 'blue', lineTitle: 'Синяя ветка', id: 25 },
      { title: 'Площадь Независимости', line: 'blue', lineTitle: 'Синяя ветка', id: 26 },
      { title: 'Площадь Льва Толстого', line: 'blue', lineTitle: 'Синяя ветка', id: 27 },
      { title: 'Олимпийская', line: 'blue', lineTitle: 'Синяя ветка', id: 28 },
      { title: 'Дворец Украина', line: 'blue', lineTitle: 'Синяя ветка', id: 29 },
      { title: 'Лыбедская', line: 'blue', lineTitle: 'Синяя ветка', id: 30 },
      { title: 'Демиевская', line: 'blue', lineTitle: 'Синяя ветка', id: 31 },
      { title: 'Голосеевская', line: 'blue', lineTitle: 'Синяя ветка', id: 32 },
      { title: 'Васильковская', line: 'blue', lineTitle: 'Синяя ветка', id: 33 },
      { title: 'Выставочный центр', line: 'blue', lineTitle: 'Синяя ветка', id: 34 },
      { title: 'Ипподром', line: 'blue', lineTitle: 'Синяя ветка', id: 35 },
      { title: 'Теремки', line: 'blue', lineTitle: 'Синяя ветка', id: 36 },
    ],
  },
  {
    id: 125,
    title: 'Зеленая ветка',
    data: [
      { title: 'Сырец', line: 'green', lineTitle: 'Зеленая ветка', id: 37 },
      { title: 'Дорогожичи', line: 'green', lineTitle: 'Зеленая ветка', id: 38 },
      { title: 'Лукьяновская', line: 'green', lineTitle: 'Зеленая ветка', id: 39 },
      { title: 'Золотые ворота', line: 'green', lineTitle: 'Зеленая ветка', id: 40 },
      { title: 'Дворец спорта', line: 'green', lineTitle: 'Зеленая ветка', id: 41 },
      { title: 'Кловская', line: 'green', lineTitle: 'Зеленая ветка', id: 42 },
      { title: 'Печерская', line: 'green', lineTitle: 'Зеленая ветка', id: 43 },
      { title: 'Дружбы народов', line: 'green', lineTitle: 'Зеленая ветка', id: 44 },
      { title: 'Выдубичи', line: 'green', lineTitle: 'Зеленая ветка', id: 45 },
      { title: 'Славутич', line: 'green', lineTitle: 'Зеленая ветка', id: 46 },
      { title: 'Осокорки', line: 'green', lineTitle: 'Зеленая ветка', id: 47 },
      { title: 'Позняки', line: 'green', lineTitle: 'Зеленая ветка', id: 48 },
      { title: 'Харьковская', line: 'green', lineTitle: 'Зеленая ветка', id: 49 },
      { title: 'Вырлица', line: 'green', lineTitle: 'Зеленая ветка', id: 50 },
      { title: 'Бориспольская', line: 'green', lineTitle: 'Зеленая ветка', id: 51 },
      { title: 'Красный хутор', line: 'green', lineTitle: 'Зеленая ветка', id: 52 },
    ],
  },
];

export const AuthFormInputs = [
  {
    name: 'email',
    id: 'email',
    type: 'email',
    label: 'Email',
  },
  {
    name: 'password',
    id: 'password',
    type: 'password',
    label: 'Password',
  },
];

export const AuthFormVerificationInput = [
  {
    name: 'code',
    id: 'verificationCode',
    type: 'number',
    label: 'Код верификации',
  },
];
