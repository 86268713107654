import React, { FC, useCallback, useEffect, useState } from 'react';
import PriceFilter from '../../../components/Flats/Filters/PriceField';
import { useAppSelector } from '../../../hooks/redux';
import { PriceFieldContainerProps } from '../../../types/Flats/Filters/FiltersPropsTypes';
import { useDebounceAsyncHandler } from '../../../hooks/useDebounce';

const PriceFieldContainer: FC<PriceFieldContainerProps> = ({ handleChange, values }) => {
  const { maxFlatPrice } = useAppSelector(state => state.flatReducer);
  const [localValue, setLocalValue] = useState<number[]>(values.length ? values : [0, maxFlatPrice]);
  const [payloadValue, setPayloadValue] = useState<number[]>(values.length ? values : [0, maxFlatPrice]);
  const [triggered, setTriggered] = useState<boolean>(false);

  useEffect(() => {
    if (localValue[1] !== maxFlatPrice && !triggered && !values.length) {
      setLocalValue([localValue[0], maxFlatPrice] as number[]);
    }
  }, [localValue, maxFlatPrice, triggered, values.length]);

  useEffect(() => {
    if (triggered) {
      handleChange(payloadValue, triggered);
    }
  }, [handleChange, payloadValue, triggered]);

  useDebounceAsyncHandler(triggered, () => setPayloadValue(localValue), 200);

  const handleChangeSlider = useCallback((event: Event, newValue: number | number[]) => {
    setTriggered(true);
    setLocalValue(newValue as number[]);
  }, []);

  const handleChangeInput = useCallback(
    (event: any) => {
      if (event.target.name === 'priceFirst') {
        if (Number(event.target.value) > Number(localValue[1])) {
          return;
        }
        setTriggered(true);

        setLocalValue(prev => {
          const newValues = [...prev];
          newValues[0] = Number(event.target.value);
          return newValues;
        });
      } else {
        setTriggered(true);
        setLocalValue(prev => {
          const newValues = [...prev];
          newValues[1] = Number(event.target.value);
          return newValues;
        });
      }
    },
    [localValue],
  );

  return (
    <PriceFilter
      handleChangeInput={handleChangeInput}
      handleChangeSlider={handleChangeSlider}
      values={localValue}
      maxFlatPrice={maxFlatPrice}
    />
  );
};

export default PriceFieldContainer;
