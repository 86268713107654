import React from 'react';
import { SVGIconProps } from '../../../../types/UI/Icons/SVGIconsTypes';
import { COLORS } from '../../../../constants/colors';

export const SearchIcon: React.FC<SVGIconProps> = ({ width = 36, height = 36, stroke = COLORS.GENERAL }) => (
  <svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.75 28.5C22.7916 28.5 28.5 22.7916 28.5 15.75C28.5 8.7084 22.7916 3 15.75 3C8.7084 3 3 8.7084 3 15.75C3 22.7916 8.7084 28.5 15.75 28.5Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M24.916 24.9163L31.28 31.2802"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
