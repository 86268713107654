import React, { FC } from 'react';
import { CustomerCardProps } from '../../../types/Customers/CustomersPropsTypes';
import classes from '../../../components/Customers/Customer/CustomerCard.module.scss';
import { Avatar } from '@mui/material';
import { stringAvatar } from '../../../utils/stringAvatar';
import { capitalizeEveryWord } from '../../../utils/capitalizeTitle';

const CustomerAvatar: FC<CustomerCardProps> = ({ customer }) => {
  if (!customer) {
    return null;
  }
  return (
    <div className={classes.CustomerAvatar}>
      <Avatar {...stringAvatar(capitalizeEveryWord(customer.name))} variant="square" />
    </div>
  );
};

export default CustomerAvatar;
