import React, { FC, useCallback, useEffect, useState } from 'react';
import Search from '../../../components/Navbar/Search/Search';
import { useAppDispatch } from '../../../hooks/redux';
import { closeNavbarSearch } from '../../../store/actions/Home/HomeActions';
import { FindCustomerAPIValues } from '../../../types/Forms/FormValuesTypes';
import { unMaskPhoneNumber } from '../../../utils/unMaskPhoneNumber';
import { ICustomer } from '../../../models/ICustomer';
import { STATUSES } from '../../../constants/statuses';
import { searchCustomer } from '../../../store/actions/Customer/CustomerThunk';
import { SearchContainerProps } from '../../../types/UI/Navbar/Search/SearchPropsTypes';

const SearchContainer: FC<SearchContainerProps> = ({ searchMode, isActive }) => {
  const dispatch = useAppDispatch();

  const [phone, setPhone] = useState<string | any>('0');
  const [customer, setCustomer] = useState<ICustomer | null>(null);
  const [fetching, setFetching] = useState(false);

  const handleSearchCustomer = useCallback(
    async (currentPhone: string | FindCustomerAPIValues | any) => {
      setFetching(true);
      const { payload } = await dispatch(searchCustomer(currentPhone));

      if (payload && payload.status === STATUSES.NOT_FOUND) {
        return setCustomer(null);
      }

      if (payload && payload.status === STATUSES.SUCCESS) {
        setCustomer(payload.customer);
      }
    },
    [dispatch],
  );

  const handleToggleCustomerSearch = useCallback(() => {
    if (unMaskPhoneNumber(phone).length === 10) {
      handleSearchCustomer(unMaskPhoneNumber(phone)).then(() => {
        setTimeout(() => {
          setFetching(false);
        }, 500);
      });
    } else {
      setCustomer(null);
    }
  }, [handleSearchCustomer, phone]);

  useEffect(() => {
    handleToggleCustomerSearch();
  }, [handleToggleCustomerSearch]);

  const handleChange = (e: {
    target: {
      selectionStart: any;
      selectionEnd: any;
      setSelectionRange: (arg0: number, arg1: number) => void;
      value: React.SetStateAction<string>;
    };
  }): void => {
    e.target.setSelectionRange(e.target.value.length, e.target.value.length);
    setPhone(e.target.value);
  };

  const handleSelectionClick = (e: React.FocusEvent<HTMLInputElement>) =>
    e.target.setSelectionRange(0, e.target?.value?.length);

  const handleCloseSearch = () => dispatch(closeNavbarSearch());
  const handleKeyClose = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape') {
      handleCloseSearch();
    }
  };

  return (
    <Search
      isActive={isActive}
      handleClose={handleCloseSearch}
      phone={phone}
      fetching={fetching}
      customer={customer}
      handleChange={handleChange}
      handleSelectionClick={handleSelectionClick}
      handleKeyClose={handleKeyClose}
    />
  );
};

export default SearchContainer;
