import { COLORS } from '../../colors';
import { FONTS } from '../fonts';

export const FormInputGeneralStyle = (
  padding = '6px',
  inputWidth?: string | undefined,
  mr = 1,
  cursor?: string | undefined,
  color?: string | undefined,
  borderColor?: string | undefined,
) => ({
  mr,
  caretColor: COLORS.WHITE,
  width: inputWidth ? `${inputWidth}!important` : null,
  '& .MuiOutlinedInput-input': {
    cursor: cursor ? cursor : 'initial!important',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
    },
    padding: '7.5px 4px 7.5px 6px',
    fontSize: '14px!important',
    color: color ? color : `${COLORS.WHITE}!important`,
    width: inputWidth ? `${inputWidth}!important` : null,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: borderColor ? borderColor : `${COLORS.INPUT_OUTLINE}!important`,
  },
  '& .MuiInputLabel-root': {
    color: `${COLORS.INPUT_OUTLINE}!important`,
    fontSize: '14px!important',
    lineHeight: '1.2375em!important',
  },
  '& .MuiInputLabel-shrink': {
    left: '0px!important',
  },
  '.MuiInputAdornment-root': {
    color: `${COLORS.INPUT_OUTLINE}!important`,
  },
  '.MuiTypography-root': {
    fontFamily: FONTS.GENERAL,
    color: `${COLORS.INPUT_OUTLINE}!important`,
  },
  '& .MuiOutlinedInput-root': {
    fontFamily: FONTS.GENERAL,
    padding: padding ? `${padding}!important` : '6px',
    fieldset: {
      borderColor: `${COLORS.INPUT_OUTLINE}!important`,
    },
    '&.Mui-focused fieldset': {
      borderColor: `${COLORS.HOVER}!important`,
    },
    '&:hover fieldset': {
      borderColor: `${COLORS.HOVER}!important`,
    },
    '& .MuiSvgIcon-root': {
      color: `${COLORS.INPUT_OUTLINE}!important`,
    },
  },
});

export const FormInputModalStyle = (
  padding = '6px',
  inputWidth?: string | undefined,
  mr = 1,
  cursor?: string | undefined,
  color?: string | undefined,
  borderColor?: string | undefined,
) => ({
  mr,
  width: inputWidth ? `${inputWidth}!important` : null,
  '& .MuiOutlinedInput-input': {
    cursor: cursor ? cursor : 'initial!important',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
    },
    padding: '7.5px 4px 7.5px 6px',
    fontSize: '14px!important',
    color: color ? color : `${COLORS.GENERAL}!important`,
    width: inputWidth ? `${inputWidth}!important` : null,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: borderColor ? borderColor : `${COLORS.GRAY_PHONE}!important`,
  },
  '& .MuiInputLabel-root': {
    color: `${COLORS.GENERAL}!important`,
    fontSize: '14px!important',
    lineHeight: '1.2375em!important',
  },
  '& .MuiInputLabel-shrink': {
    left: '0px!important',
  },
  '.MuiInputAdornment-root': {
    color: `${COLORS.GRAY_PHONE}!important`,
  },
  '.MuiTypography-root': {
    fontFamily: FONTS.GENERAL,
    color: `${COLORS.GRAY_PHONE}!important`,
  },
  '& .MuiFormHelperText-root': {
    position: 'absolute',
    top: '100%',
    margin: '3px',
    whiteSpace: 'nowrap',
  },
  '& .MuiOutlinedInput-root': {
    fontFamily: FONTS.GENERAL,
    padding: padding ? `${padding}!important` : '6px',
    fieldset: {
      borderColor: `${COLORS.GRAY_PHONE}!important`,
    },
    '&.Mui-focused fieldset': {
      borderColor: `${COLORS.HOVER}!important`,
    },
    '&:hover fieldset': {
      borderColor: `${COLORS.HOVER}!important`,
    },
    '& .MuiSvgIcon-root': {
      color: `${COLORS.GENERAL}!important`,
    },
  },
});

export const FormInputConstructorStyle = (
  padding = '6px',
  inputWidth?: string | undefined,
  mr = 1,
  cursor?: string | undefined,
  color?: string | undefined,
  borderColor?: string | undefined,
  fontSize?: string | undefined,
  labelFontSize?: string | undefined,
  inputPadding?: string | undefined,
  labelColor?: string | undefined,
  inputAdornmentColor?: string | undefined,
  typographyColor?: string | undefined,
  outlinedInputFieldsetColor?: string | undefined,
  svgColor?: string | undefined,
) => ({
  mr,
  width: inputWidth ? `${inputWidth}!important` : null,
  '& .MuiOutlinedInput-input': {
    cursor: cursor ? cursor : 'initial!important',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
    },
    padding: inputPadding ? `${inputPadding}!important` : '7.5px 4px 7.5px 6px',
    fontSize: fontSize ? `${fontSize}!important` : '14px!important',
    color: color ? color : `${COLORS.GENERAL}!important`,
    width: inputWidth ? `${inputWidth}!important` : null,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: borderColor ? borderColor : `${COLORS.GRAY_PHONE}!important`,
  },
  '& .MuiInputLabel-root': {
    color: labelColor ? `${labelColor}!important` : `${COLORS.GENERAL}!important`,
    fontSize: labelFontSize ? `${labelFontSize}!important` : '14px!important',
    lineHeight: '1.2375em!important',
  },
  '& .MuiInputLabel-shrink': {
    left: '0px!important',
  },
  '.MuiInputAdornment-root': {
    color: inputAdornmentColor ? `${inputAdornmentColor}!important` : `${COLORS.GRAY_PHONE}!important`,
  },
  '.MuiTypography-root': {
    fontFamily: FONTS.GENERAL,
    color: typographyColor ? `${typographyColor}!important` : `${COLORS.GRAY_PHONE}!important`,
  },
  '& .MuiFormHelperText-root': {
    position: 'absolute',
    top: '100%',
    margin: '3px',
    whiteSpace: 'nowrap',
  },
  '& .MuiOutlinedInput-root': {
    fontFamily: FONTS.GENERAL,
    padding: padding ? `${padding}!important` : '6px',
    fieldset: {
      borderColor: outlinedInputFieldsetColor
        ? `${outlinedInputFieldsetColor}!important`
        : `${COLORS.GRAY_PHONE}!important`,
    },
    '&.Mui-focused fieldset': {
      borderColor: `${COLORS.HOVER}!important`,
    },
    '&:hover fieldset': {
      borderColor: `${COLORS.HOVER}!important`,
    },
    '& .MuiSvgIcon-root': {
      color: svgColor ? `${svgColor}!important` : `${COLORS.GENERAL}!important`,
    },
  },
});

export const FormInputSearchPlaceholder = {
  width: '280px',
  border: 'none!important',
  borderRadius: '0px!important',
  '& .MuiOutlinedInput-input': {
    border: 'none!important',
    borderRadius: '0px!important',
    cursor: 'initial!important',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
    },
    padding: '7.5px 4px 7.5px 6px',
    fontSize: '14px!important',
    color: `${COLORS.FILTERS_BG}!important`,
    width: '300px!important',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none!important',
    borderRadius: '0px!important',
    borderColor: `${COLORS.FILTERS_BG}!important`,
  },
  '& .MuiInputLabel-root': {
    color: `${COLORS.FILTERS_BG}!important`,
    fontSize: '14px!important',
    lineHeight: '1.2375em!important',
  },
  '& .MuiInputLabel-shrink': {
    left: '0px!important',
  },
  '.MuiInputAdornment-root': {
    color: `${COLORS.FILTERS_BG}!important`,
  },
  '.MuiTypography-root': {
    fontFamily: FONTS.GENERAL,
    color: `${COLORS.FILTERS_BG}!important`,
  },
  '& .MuiOutlinedInput-root': {
    fontFamily: FONTS.GENERAL,
    padding: '6px',
    fieldset: {
      border: 'none!important',
      borderRadius: '0px!important',
      borderColor: `${COLORS.FILTERS_BG}!important`,
    },
    '&.Mui-focused fieldset': {
      border: 'none!important',
      borderRadius: '0px!important',
      borderColor: `${COLORS.FILTERS_BG}!important`,
    },
    '&:hover fieldset': {
      border: 'none!important',
      borderRadius: '0px!important',
      borderColor: `${COLORS.FILTERS_BG}!important`,
    },
    '& .MuiSvgIcon-root': {
      color: `${COLORS.FILTERS_BG}!important`,
    },
  },
};

export const FormInputAddFlatPlaceholder = {
  width: '280px',
  border: 'none!important',
  borderRadius: '0px!important',
  '& .MuiOutlinedInput-input': {
    border: 'none!important',
    borderRadius: '0px!important',
    cursor: 'initial!important',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
    },
    padding: '7.5px 4px 7.5px 6px',
    fontSize: '14px!important',
    color: `${COLORS.LABELS_COLOR}!important`,
    width: '300px!important',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none!important',
    borderRadius: '0px!important',
    borderColor: `${COLORS.LABELS_COLOR}!important`,
  },
  '& .MuiInputLabel-root': {
    color: `${COLORS.LABELS_COLOR}!important`,
    fontSize: '14px!important',
    lineHeight: '1.2375em!important',
  },
  '& .MuiInputLabel-shrink': {
    left: '0px!important',
  },
  '.MuiInputAdornment-root': {
    color: `${COLORS.LABELS_COLOR}!important`,
  },
  '.MuiTypography-root': {
    fontFamily: FONTS.GENERAL,
    color: `${COLORS.LABELS_COLOR}!important`,
  },
  '& .MuiOutlinedInput-root': {
    fontFamily: FONTS.GENERAL,
    padding: '6px',
    fieldset: {
      border: 'none!important',
      borderRadius: '0px!important',
      borderColor: `${COLORS.LABELS_COLOR}!important`,
    },
    '&.Mui-focused fieldset': {
      border: 'none!important',
      borderRadius: '0px!important',
      borderColor: `${COLORS.LABELS_COLOR}!important`,
    },
    '&:hover fieldset': {
      border: 'none!important',
      borderRadius: '0px!important',
      borderColor: `${COLORS.LABELS_COLOR}!important`,
    },
    '& .MuiSvgIcon-root': {
      color: `${COLORS.LABELS_COLOR}!important`,
    },
  },
};

export const FormControlGeneralStyle = (m = '15px 24px', width = '300px') => ({
  margin: m ? `${m}!important` : '15px 24px',
  color: '#fff',
  width: width ? `${width}!important` : '300px',
});

export const FormControlModalStyle = (m = '25px 0', width?: string) => ({
  display: 'block!important',
  margin: m ? `${m}!important` : '25px 0',
  color: '#fff',
  width: width ? `${width}!important` : '300px',
});

export const FormInputPropsGeneralStyle = (height = '35px', width = '35px') => ({
  height,
  width,
  padding: '0 14px',
});

export const InputAdornmentStyle = {
  marginLeft: '5px',
  marginRight: '0',
  fontSize: '14px',
};

export const InputEndAdornmentStyle = {
  marginRight: '5px',
  fontSize: '14px',
};

export const NavbarSearchInputStyle = {
  width: '700px!important',
  lineHeight: '42px',
  position: 'fixed',
  left: '50%',
  top: '25%',
  transform: 'translate(-50%, 0)',
  zIndex: '99999',
  caretColor: `${COLORS.BLACK}!important`,
  '& .MuiOutlinedInput-input': {
    // background: '#fff!important',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
    },
    padding: '7.5px 4px 7.5px 6px',
    fontSize: '28px!important',
    color: `${COLORS.GRAY_PHONE}!important`,
    width: '300px!important',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: `${COLORS.BLACK}!important`,
    color: `${COLORS.GRAY_PHONE}!important`,
  },
  '& .MuiInputLabel-root': {
    color: `${COLORS.GRAY_PHONE}!important`,
    fontSize: '14px!important',
    lineHeight: '1.2375em!important',
  },
  '& .MuiInputLabel-shrink': {
    left: '0px!important',
  },
  '.MuiInputAdornment-root': {
    color: `${COLORS.GRAY_PHONE}!important`,
  },
  '.MuiTypography-root': {
    fontFamily: FONTS.GENERAL,
    fontSize: '28px!important',
    color: `${COLORS.GRAY_PHONE}!important`,
  },
  '& .MuiOutlinedInput-root': {
    fontFamily: FONTS.GENERAL,
    padding: '18px!important',
    background: `${COLORS.WHITE}!important`,
    fieldset: {
      borderColor: `${COLORS.BLACK}!important`,
    },
    '&.Mui-focused fieldset': {
      borderColor: `${COLORS.HOVER}!important`,
    },
    '&:hover fieldset': {
      borderColor: `${COLORS.HOVER}!important`,
    },
    '& .MuiSvgIcon-root': {
      color: `${COLORS.BLACK}!important`,
    },
  },
};
