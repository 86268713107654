import { INotification } from '../models/INotification';
import { toast } from 'react-hot-toast';

export const Notify = ({ message, type = 'success', emoji, duration = 3000 }: INotification) => {
  switch (type) {
    case 'success':
      toast.success(message, {
        duration,
      });
      break;
    case 'error':
      toast.error(message, {
        duration,
      });
      break;
    case 'emoji':
      toast(message, {
        icon: emoji,
        duration,
      });
      break;
    default:
      toast.success(message, {
        duration,
      });
  }
};
