import React, { FC } from 'react';
import classes from '../../Flats/Filters/Filters.module.scss';
import { Checkbox } from '@mui/material';
import { FormCheckboxHoverYellow } from '../../../constants/styles/Forms/checkboxes';
import { COLORS } from '../../../constants/colors';
import { IconsBoxComponentProps } from '../../../types/UI/Icons/SVGIconsTypes';

const IconsBox: FC<IconsBoxComponentProps> = ({
  icons: Icons,
  fill,
  isInverse,
  iconsState,
  handleChange,
}: IconsBoxComponentProps): any =>
  Icons.map(Icon => {
    const { name } = Icon;
    const iconName = name.replace('Icon', '').toLowerCase();
    return (
      <div key={iconName} className={isInverse ? classes.FiltersIconInverse : classes.FiltersIcon}>
        <Checkbox
          sx={FormCheckboxHoverYellow}
          icon={<Icon fill={fill || undefined} />}
          onMouseUp={() => handleChange(iconName)}
          checkedIcon={<Icon fill={COLORS.HOVER} />}
          checked={iconsState[iconName] || false}
        />
      </div>
    );
  });

export default IconsBox;
