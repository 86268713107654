import React from 'react';
import { SVGIconBodyProps } from '../../../types/UI/Icons/SVGIconsTypes';

const SVGIcon: React.FC<SVGIconBodyProps> = ({
  width = 36,
  height = 36,
  style = {},
  currentIcon: CurrentIcon,
  stroke,
  fill,
}) => (
  <div style={style}>
    <CurrentIcon width={width} height={height} stroke={stroke} fill={fill} />
  </div>
);

export default SVGIcon;
