import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import React, { FC } from 'react';
import { AppRoutesProps } from './RoutesTypes';
import PublicRoute from './PublicRoute';
import AuthContainer from '../containers/Authorization/Auth/AuthContainer';
import PrivateRoute from './PrivateRoute';
import Dashboard from '../components/Dashboard/Dashboard';
import { APP_ROUTES } from '../constants/routes';
import NotFound from '../components/404';
import CustomerContainer from '../containers/Customers/Customer/CustomerContainer';
import AddNewFlatContainer from '../containers/Flats/new/AddNewFlatContainer';
import FlatContainer from '../containers/Flats/view/FlatContainer';
import AddNewFlatOLXContainer from '../containers/Flats/new/AddNewFlatOLXContainer';
import OpenRoute from './OpenRoute';
import UserCVProfile from '../components/Users/Profile';

const AppRoutes: FC<AppRoutesProps> = ({ session }) => {
  const location = useLocation();
  const profilePattern = /^\/profile\/cv\/[a-zA-Z-]+$/;

  const renderRule = ((session?.token && session?.user || profilePattern.test(location.pathname))
    ? <Outlet />
    : <Navigate to={APP_ROUTES.AUTH} />)

  return (
    <Routes>
      <Route path="/" element={renderRule}>
        <Route index element={<PrivateRoute session={session} component={Dashboard} />} />
        <Route path={APP_ROUTES.OBJECTS} element={<PrivateRoute session={session} component={Dashboard} />} />
        <Route
          path={APP_ROUTES.FLAT_ADD}
          element={<PrivateRoute session={session} component={AddNewFlatContainer} />}
        />
        <Route
          path={APP_ROUTES.FLAT_ADD_OLX}
          element={<PrivateRoute session={session} component={AddNewFlatOLXContainer} />}
        />
        <Route path={APP_ROUTES.FLAT_VIEW} element={<PrivateRoute session={session} component={FlatContainer} />} />
        <Route path={APP_ROUTES.CV_PROFILE} element={<OpenRoute component={UserCVProfile} />} />
        <Route
          path={APP_ROUTES.FLAT_EDIT}
          element={<PrivateRoute session={session} component={AddNewFlatContainer} />}
        />
        <Route
          path={APP_ROUTES.CUSTOMER_EDIT}
          element={<PrivateRoute session={session} component={CustomerContainer} />}
        />

        <Route path={APP_ROUTES.CONTACTS} element={<PrivateRoute session={session} component={Dashboard} />} />
        <Route path={APP_ROUTES.MEETS} element={<PrivateRoute session={session} component={Dashboard} />} />
        <Route path={APP_ROUTES.CALENDAR} element={<PrivateRoute session={session} component={Dashboard} />} />
      </Route>
      <Route path={APP_ROUTES.AUTH} element={<PublicRoute session={session} component={AuthContainer} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
