import React, { FC, useCallback, useState } from 'react';
import InputField from '../../../../components/UI/Fields/Inputs/InputField';
import { InputFieldContainerProps } from '../../../../types/Forms/FormPropsTypes';
import { useDebounceAsyncHandler } from '../../../../hooks/useDebounce';

const InputFieldContainer: FC<InputFieldContainerProps> = ({
  label,
  required,
  formControlStyle,
  formLabelStyle,
  formInputStyle,
  formGroupStyle,
  placeholder,
  isInline = false,
  validation,
  multiline,
  rows,
  endPlaceholder,
  name,
  value,
  handleChange,
  disableAutoComplete,
}) => {
  return (
    <InputField
      label={label}
      isInline={isInline}
      name={name}
      required={required}
      formControlStyle={formControlStyle}
      formLabelStyle={formLabelStyle}
      formInputStyle={formInputStyle}
      formGroupStyle={formGroupStyle}
      placeholder={placeholder}
      rows={rows}
      endPlaceholder={endPlaceholder}
      multiline={multiline}
      handleChange={handleChange}
      validation={validation}
      value={value}
      disableAutoComplete={disableAutoComplete}
    />
  );
};

export default InputFieldContainer;
