import React, { FC, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import Flats from '../../components/Flats/Flats';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { deleteFlat, getAllFlats, getFlat, getResourceContactData } from '../../store/actions/Flats/FlatThunk';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { clearUIOptionsAddFlat, setCustomerFlatsData, setFlatOption } from '../../store/actions/Flats/FlatActions';
import { CircularProgress } from '@mui/material';
import FlatToPrint from '../../components/Flats/view/FlatToPrint';
import getRouteURI from '../../utils/getRouteURI';
import { APP_ROUTES } from '../../constants/routes';
import { Notify } from '../../utils/notifications';
import { FlatsContainerProps } from '../../types/Flats/FlatsPropsTypes';
import { getCustomerContactData } from '../../store/actions/Customer/CustomerThunk';
import { STATUSES } from '../../constants/statuses';

const FlatsContainer: FC<FlatsContainerProps> = ({ customerFlats, singleFlat, allFlats }) => {
  const dispatch = useAppDispatch();
  const { uuid } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { customer, loading: customerLoading } = useAppSelector(state => state.customerReducer);
  const { flats, flatsPagesLimit, loading, isPrintingFlat, flatPreview, currentPage, isEditMode } = useAppSelector(
    state => state.flatReducer,
  );
  const { user } = useAppSelector(state => state.userReducer);
  const { filtersPayload } = useAppSelector(state => state.flatFiltersReducer);

  const [isDeleteModal, setIsDeleteModal] = useState<any>({ isOped: false, uuid: null, vfId: null });

  const [phonesData, setPhonesData] = useState(null);
  const [selectedFlat, setSelectedFlat] = useState<any>(null);

  const onInitialize = useCallback(
    async (uuid?: string) => {
      if (customerFlats && customer) {
        await dispatch(setCustomerFlatsData(customer.flats));
        return true;
      }

      if (singleFlat && uuid) {
        await dispatch(getFlat(uuid));
        return true;
        // Open current flat
      }

      if (allFlats) {
        await dispatch(getAllFlats({ page: currentPage, filtersPayload }));
        return true;
      }
      return [];
    },
    [allFlats, currentPage, customer, customerFlats, dispatch, singleFlat],
  );

  const handleGetContactData = async (resourceKey: string, uuid: any) => {
    setSelectedFlat(uuid);
    setPhonesData(null)
    const { payload } = await dispatch(getResourceContactData({ resourceKey, uuid }));

    if (payload.status === STATUSES.SUCCESS && payload?.contactData) {
      if (resourceKey === 'phone') {
        return setPhonesData(payload.contactData);
      }
    } else {
      Notify({ message: 'Ошибка получения данных', type: 'error' });
    }
  }

  const handleChangePage = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) =>
      dispatch(
        setFlatOption({
          type: 'currentPage',
          value,
        }),
      ),
    [dispatch],
  );

  const handleDeleteFlat = useCallback(
    (currentUUID: string) => {
      if (!currentUUID) {
        return Notify({ message: 'Не удалось удалить квартиру', type: 'error' });
      }
      setIsDeleteModal({ isOped: false, uuid: null, vfId: null });
      dispatch(deleteFlat({ page: currentPage, filtersPayload, uuid: currentUUID }));
    },
    [currentPage, dispatch, filtersPayload],
  );

  useEffect(() => {
    onInitialize(uuid);
    return () => {
      dispatch(clearUIOptionsAddFlat());
    };
  }, [dispatch, onInitialize, uuid]);

  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 793, behavior: 'smooth' });
  }, [currentPage]);

  const memoizedFlats = useMemo(() => [...flats], [flats]);

  if (isPrintingFlat && flatPreview) {
    return <FlatToPrint flat={flatPreview}  />;
  }

  return customerLoading ? (
    <CircularProgress />
  ) : (
    <Flats
      flats={memoizedFlats}
      handleChangePage={handleChangePage}
      pagesLimit={flatsPagesLimit}
      page={currentPage}
      fetching={loading}
      handleDeleteFlat={handleDeleteFlat}
      isDeleteModal={isDeleteModal}
      setIsDeleteModal={setIsDeleteModal}
      user={user}
      handleGetContactData={handleGetContactData}
      phonesData={phonesData}
      selectedFlat={selectedFlat}
    />
  );
};

export default FlatsContainer;
