import { FormikErrors, useFormikContext, withFormik } from 'formik';
import { AddNewFlatFormProps, AddNewFlatOLXFormProps } from '../../types/Forms/FormPropsTypes';
import { AddNewFlatFormValues, AddNewFlatOLXFormValues } from '../../types/Forms/FormValuesTypes';
import AddNewFlatFormUI from '../../components/Forms/AddNewFlatFormUI';
import isURL from '../../utils/isURL';
import AddNewFlatOLXFormUI from '../../components/Forms/AddNewFlatOLXFormUI';

export const AddNewFlatOLXForm = withFormik<AddNewFlatOLXFormProps, AddNewFlatOLXFormValues>({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  mapPropsToValues: () => {
    return {
      url: '',
      street: undefined,
      notes: '',
      subwayStations: [],
      timeToSubway: undefined,
      residence: '',
    };
  },

  validate: (values: AddNewFlatOLXFormValues) => {
    const errors: FormikErrors<AddNewFlatOLXFormValues> = {};

    if (!values.url) {
      errors.url = 'Необходимо заполнить поле URL'
    }

    if (values.url && !values.url.includes('olx.ua')) {
      errors.url = 'Некорректный URL';
    }

    if (values.url && !isURL(values.url)) {
      errors.url = 'Некорректный URL';
    }

    if (values.notes && values.notes.length > 290) {
      errors.notes = 'Допустимо не более 290 символов';
    }

    return errors;
  },
  validateOnBlur: true,
  validateOnChange: false,
  handleSubmit: async (values, { props }) => {
    const newValues = { ...values };
    props.handleAddNewFlat(newValues);
    // do submitting things
  },
})(AddNewFlatOLXFormUI);
