import { AppDispatch } from '../../store';
import { flatSlice, PayloadSetNewOption } from '../../reducers/FlatSlice';
import { FlatSpecialValues } from '../../../types/Forms/FormValuesTypes';
import { IFlat } from '../../../models/IFlat';

export const setFlatOption = (payload: PayloadSetNewOption) => (dispatch: AppDispatch) =>
  dispatch(flatSlice.actions.setNewOption(payload));

export const setCustomerFlatsData = (payload: IFlat[]) => (dispatch: AppDispatch) => {
  dispatch(flatSlice.actions.setCustomerFlats(payload));
};

export const clearUIOptionsAddFlat = () => (dispatch: AppDispatch) =>
  dispatch(flatSlice.actions.clearUIAddFlatOptions());
export const setSpecialFields = (special: FlatSpecialValues) => (dispatch: AppDispatch) =>
  dispatch(flatSlice.actions.setSpecials(special));

export const setFlatWebLink = (webLink: string) => (dispatch: AppDispatch) =>
  dispatch(flatSlice.actions.setFlatLink(webLink));
