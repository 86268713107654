export const GeneralPaginationStyle = {
  '.MuiButtonBase-root.MuiPaginationItem-root': {
    color: 'rgba(47, 109, 142, 1)!important',
    fontSize: '18px!important',
    width: '48px!important',
    height: '48px!important',
  },
  '.MuiButtonBase-root.MuiPaginationItem-root.Mui-selected': {
    color: 'rgba(47, 109, 142, 1)!important',
    backgroundColor: 'rgba(0, 66, 105, 0.07)!important',
  },
};
