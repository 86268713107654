import React, { FC } from 'react';
import classes from './Auth.module.scss';
import { AuthFormButtonStyles, AuthFormInputStyles, AuthFormStyles } from '../../../constants/styles';
import { AuthForm } from '../../../Forms/Auth/AuthForm';
import { AuthFormInputs } from '../../../mappings/Forms/inputs';
import { AuthContainerProps } from '../../../types/Authorization/AuthorizationPropsTypes';

const Auth: FC<AuthContainerProps> = ({ handleSignIn }) => {
  return (
    <div className={classes.AuthForm}>
      <div className={classes.AuthFormHeader}>
        <div>Авторизация</div>
        <div>Войдите в свою учетную запись</div>
      </div>
      <div>
        <AuthForm
          submitTitle="Авторизоваться"
          inputs={AuthFormInputs}
          fullWidth
          formStyle={AuthFormStyles}
          buttonStyle={AuthFormButtonStyles}
          inputStyle={AuthFormInputStyles}
          handleSignIn={handleSignIn}
        />
      </div>
    </div>
  );
};

export default Auth;
