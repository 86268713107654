import React from 'react';
import { SVGIconProps } from '../../../../types/UI/Icons/SVGIconsTypes';

export const OwnerIcon: React.FC<SVGIconProps> = ({ width = 40, height = 40, clazz }) => (
  <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.667 13.3333C26.667 9.64996 23.6837 6.66663 20.0003 6.66663C16.317 6.66663 13.3337 9.64996 13.3337 13.3333C13.3337 17.0166 16.317 20 20.0003 20C23.6837 20 26.667 17.0166 26.667 13.3333ZM6.66699 30V33.3333H33.3337V30C33.3337 25.5666 24.4503 23.3333 20.0003 23.3333C15.5503 23.3333 6.66699 25.5666 6.66699 30Z"
      fill="#C68A52"
      className={clazz}
    />
  </svg>
);
