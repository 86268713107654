import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Attachments from '../../../components/UI/Attachments/Attachments';
import { AttachmentsContainerProps } from '../../../types/UI/Attachments/AttachmentsPropsTypes';
import { useAppDispatch } from '../../../hooks/redux';
import { useDropzone } from 'react-dropzone';
import { ATTACHMENTS_RULES } from '../../../constants/attachments';
import { Notify } from '../../../utils/notifications';
import { addFlatFiles, removeUploadedFile, updateFlatImagesPosition } from '../../../store/actions/Flats/FlatThunk';
import { AddNewFlatFilesValues } from '../../../types/Forms/FormValuesTypes';
import { transformAPIFileImage, transformImageURL } from '../../../constants/api';
import { STATUSES } from '../../../constants/statuses';
import { setFlatOption } from '../../../store/actions/Flats/FlatActions';

const AttachmentsContainer: FC<AttachmentsContainerProps> = ({ files, setFiles, isEditMode, flatPreview }) => {
  const dispatch = useAppDispatch();
  const { getRootProps, getInputProps } = useDropzone({
    accept: ATTACHMENTS_RULES.fileTypes,
    onDrop: async acceptedFiles => {
      if (
        acceptedFiles.length > ATTACHMENTS_RULES.maxFilesAllowed ||
        (flatPreview &&
          flatPreview?.files?.join('').split(',').length + acceptedFiles.length > ATTACHMENTS_RULES.maxFilesAllowed) ||
        (files && files?.length + acceptedFiles.length > ATTACHMENTS_RULES.maxFilesAllowed)
      ) {
        return Notify({
          message: `Нельзя загружать более ${ATTACHMENTS_RULES.maxFilesAllowed} файлов`,
          type: 'error',
        });
      }

      const transformed = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );

      setFiles((prev: any) => {
        if (prev) {
          return [...prev, ...transformed];
        }
        return [...transformed];
      });
      if (isEditMode && flatPreview) {
        const filesData = new FormData();

        if (transformed.length) {
          transformed.forEach((file: string | Blob) => {
            filesData.append('file[]', file);
          });
        }

        const filesPayload = {
          uuid: flatPreview.uuid,
          files: filesData,
        };

        await dispatch(addFlatFiles(filesPayload as AddNewFlatFilesValues));
        setFiles([]);
      }
    },
  });

  const flatFiles = useMemo(
    () =>
      transformImageURL(flatPreview?.files).map((item: string) => {
        return {
          id: item.slice(15, 20) + Math.random() * 90,
          thumb: item,
          imgUrl: item,
        };
      }),
    [flatPreview?.files],
  );

  const [previewFiles, setPreviewFiles] = useState<any>(flatFiles);

  useEffect(() => {
    setPreviewFiles(flatFiles);
  }, [flatPreview?.files]);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files?.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  const handleDeleteFile = useCallback(
    async (file: any, mode: string) => {
      if (mode === ATTACHMENTS_RULES.deleteMode.local) {
        return setFiles((prev: any[]) => prev.filter(f => f.name !== file.name));
      }

      const payload = {
        flatUUID: flatPreview?.uuid,
        fileName: transformAPIFileImage(file.thumb),
      };

      // TODO: Change to Thunk extraBuilder to get the flat

      const response = await dispatch(removeUploadedFile(payload));

      if (response.payload?.status === STATUSES.SUCCESS) {
        dispatch(setFlatOption({ type: 'flatPreview', value: response.payload?.flat }));
      }
    },
    [dispatch, flatPreview?.uuid, setFiles],
  );

  const handleOnDragEndFlatPreview = useCallback(
    async (result: any) => {
      if (!result.destination || !flatPreview) return;

      const items = Array.from(previewFiles);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      const files =
        items.length &&
        items
          .map(item => {
            // TODO: Change this
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return transformAPIFileImage(item.thumb);
          })
          .join(',');

      const payload = {
        uuid: flatPreview.uuid,
        files: {
          files,
        },
      };

      await dispatch(updateFlatImagesPosition(payload));

      setPreviewFiles(items);
    },
    [dispatch, flatPreview, previewFiles],
  );

  return (
    <Attachments
      files={files}
      setFiles={setFiles}
      isEditMode={isEditMode}
      flatPreview={flatPreview}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      handleDeleteFile={handleDeleteFile}
      handleOnDragEndFlatPreview={handleOnDragEndFlatPreview}
      previewFiles={previewFiles}
    />
  );
};

export default AttachmentsContainer;
