import React, { FC, memo } from 'react';
import Modal from '@mui/material/Modal';
import classes from './ConfirmationModal.module.scss';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import { CircularProgress, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SubmitButtonMain } from '../../../constants/styles/Buttons/SubmitButtons';
import { ConfirmationModalProps } from '../../../types/UI/ModalsPropsTypes';

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  onConfirm,
  onCancel,
  title,
  message,
  loading,
  buttonCancelText,
  buttonConfirmText,
  confirmButtonVariant,
  cancelButtonVariant,
}) => {
  return (
    <Modal
      aria-labelledby="actions-modal-confirmation"
      aria-describedby="actions-modal-confirmation"
      open
      className={classes.ConfirmationModal}
      onClose={onCancel}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <Box className={classes.ConfirmationModalContent}>
          <div className="d-flex align-items-center justify-content-between" style={{ marginBottom: '5px' }}>
            <h4 className={classes.ConfirmationModalTitle}>{title}</h4>
            <IconButton onClick={onCancel}>
              <CloseIcon sx={{ fontSize: '33px' }} />
            </IconButton>
          </div>
          <div className={classes.ConfirmationModalText}>{message}</div>
          <div className="d-flex align-items-center justify-content-center">
            <SubmitButtonMain
              disabled={loading}
              variant="contained"
              sx={{ width: '200px!important', background: '#fff', borderColor: '#000', color: '#000' }}
              onClick={onCancel}
            >
              <p>{buttonCancelText}</p>
            </SubmitButtonMain>
            <SubmitButtonMain
              type="submit"
              disabled={loading}
              variant="contained"
              onClick={onConfirm}
              sx={{
                marginLeft: '25px',
                width: '200px!important',
              }}
            >
              <p>{buttonConfirmText}</p>
              {loading && <CircularProgress size="1.3em" sx={{ marginLeft: '10px' }} />}
            </SubmitButtonMain>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default memo(ConfirmationModal);
