const getRemainedTime = (time: number, type: string) => {
  const now = new Date();
  let currentTime = null;
  let requiredTime = null;

  if (type === 'seconds') {
    currentTime = now.getSeconds();
    requiredTime = Math.floor(time);
    return new Date(now.setSeconds(currentTime + requiredTime));
  }

  if (type === 'hours') {
    currentTime = now.getHours();
    requiredTime = Math.floor(time / 3600);
    return new Date(now.setHours(currentTime + requiredTime));
  }

  if (type === 'days') {
    currentTime = now.getDate();
    requiredTime = Math.floor(time / (3600 * 24));
    return new Date(now.setDate(currentTime + requiredTime));
  }

  return new Date();
};

export default getRemainedTime;
