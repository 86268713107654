import React from 'react';
import { SVGIconProps } from '../../../../types/UI/Icons/SVGIconsTypes';

export const AddIcon: React.FC<SVGIconProps> = ({ width = 36, height = 36 }) => (
  <svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="18" cy="18" r="17" fill="#2B3C46" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 11.5C18.5523 11.5 19 11.9477 19 12.5V24.5C19 25.0523 18.5523 25.5 18 25.5C17.4477 25.5 17 25.0523 17 24.5V12.5C17 11.9477 17.4477 11.5 18 11.5Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 18.5C11 17.9477 11.4477 17.5 12 17.5H24C24.5523 17.5 25 17.9477 25 18.5C25 19.0523 24.5523 19.5 24 19.5H12C11.4477 19.5 11 19.0523 11 18.5Z"
      fill="white"
    />
  </svg>
);
