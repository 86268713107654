import { IFile } from '../models/IFile';
import { CURRENT_ENV } from '../.secret';

const APIs: {
  [key: string]: string;
} = {
  DEVELOPMENT: 'http://localhost:2222/api',
  PRODUCTION: 'http://176.104.6.131:2222/api',
};

export const API_URL = APIs[CURRENT_ENV];
export const transformImageURL = (files: IFile[] | string[]) => {
  if (!files || !files.length) {
    return [];
  }
  const transformedFiles = files.join('').split(',');
  return transformedFiles.map((file: string) => {
    return `${API_URL.replaceAll('api', '')}${file}`;
  });
};

export const transformAPIFileImage = (file: string) => file.replaceAll(API_URL.replaceAll('api', ''), '');

export const API = {
  TOKEN: 'verticalAccessToken',
  USER: 'user',
  EXPIRES: 'expiresIn',
};

export const HEADERS = {
  REGULAR_POST_HEADER: {
    headers: {
      'Content-Type': 'application/json',
    },
  },
};
