import { FormikErrors, withFormik } from 'formik';
import isValidVerificationCode from '../../utils/isValidVerificationCode';
import AuthFormUI from '../../components/Forms/AuthFormUI';
import { AuthFormVerificationValues } from '../../types/Authorization/AuthorizationValues';
import { AuthVerificationProps } from '../../types/Authorization/AuthorizationPropsTypes';

export const AuthFormVerification = withFormik<AuthVerificationProps, AuthFormVerificationValues>({
  mapPropsToValues: () => ({
    code: '',
    email: '',
    keepSigned: null,
  }),

  validate: (values: AuthFormVerificationValues) => {
    const errors: FormikErrors<AuthFormVerificationValues> = {};
    if (!values.code) {
      errors.code = 'Обязательное поле';
    } else if (!isValidVerificationCode(values.code)) {
      errors.code = 'Неверный формат кода';
    }
    return errors;
  },
  handleSubmit: async (values, { props }) => {
    props.handleVerifyLogin(values);
    // do submitting things
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
})(AuthFormUI);
