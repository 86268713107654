import React, { FC, memo } from 'react';
import FilterContainer from '../../containers/Flats/Filter/FilterContainer';
import FlatsContainer from '../../containers/Flats/FlatsContainer';
import MainWrapper from '../hoc/MainWrapper';

const Dashboard: FC = () => {
  return (
    <MainWrapper>
      <FilterContainer />
      <FlatsContainer allFlats />
      {/*<DragList />*/}
    </MainWrapper>
  );
};

export default memo(Dashboard);
