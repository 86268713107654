import React, { FC, memo } from 'react';
import classes from './Filters.module.scss';
import { BREADCRUMBS } from '../../../constants/routes';
import { Checkbox, FormControl, FormGroup, FormLabel } from '@mui/material';
import { FormControlGeneralStyle, FormInputGeneralStyle } from '../../../constants/styles/Forms/inputs';
import { FormLabelGeneralStyle } from '../../../constants/styles/Forms/labels';
import IconsBox from '../../UI/Icons/IconsBox';
import { ClientTypeSelectOptions, FlatStatusSelectOptions } from '../../../mappings/Forms/selects';
import { DistrictLines, ResidenceList, SubwayLinesList } from '../../../mappings/Forms/inputs';
import { COLORS } from '../../../constants/colors';
import { TeamTypeSelectMultipleOptions } from '../../../mappings/Forms/selectsMultiple';
import { FormCheckboxHoverYellow } from '../../../constants/styles/Forms/checkboxes';
import { VERTICAL } from '../../../constants/strings';
import { FormSelectGeneralStyle } from '../../../constants/styles/Forms/selects';
import Breadcrumbs from '../../UI/Breadcrumbs/Breadcrumbs';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs';
import { FiltersProps } from '../../../types/Flats/Filters/FiltersPropsTypes';
import SelectMultipleContainer from '../../../containers/UI/Fields/Selects/SelectMultipleContainer';
import InputFieldContainer from '../../../containers/UI/Fields/Inputs/InputFieldContainer';
import PropertyTypeContainer from '../../../containers/Flats/Filter/PropertyTypeContainer';
import StreetFieldContainer from '../../../containers/Flats/Filter/StreetFieldContainer';
import PriceFieldContainer from '../../../containers/Flats/Filter/PriceFieldContainer';
import RangeFieldContainer from '../../../containers/UI/Fields/Ranges/RangeFieldContainer';
import DateFieldContainer from '../../../containers/Flats/Filter/DateFieldContainer';
import SelectSingleFieldContainer from '../../../containers/UI/Fields/Selects/SelectSingleFieldContainer';
import {
  BakeIcon,
  BathroomIcon,
  BedIcon,
  CatIcon,
  ChildrenIcon,
  DistrictIcon,
  DistrictIconChecked,
  DogIcon,
  ForeignersIcon,
  ResidenceIcon,
  ResidenceIconChecked,
  SmokingIcon,
  StreetIcon,
  StreetIconChecked,
  StudioIcon,
  SubwayIcon,
  SubwayIconChecked,
} from '../../UI/Icons';
import CheckboxLabelFieldContainer from '../../../containers/UI/Fields/Checkboxes/CheckboxLabelFieldContainer';
import IconsBoxContainer from '../../../containers/UI/Icons/IconsBoxContainer';

const Filters: FC<FiltersProps> = ({
  handleResidenceCheckboxChange,
  handleDistrictCheckboxChange,
  subwayDistrictToggle,
  streetResidenceToggle,
  filtersPayload,
  handleChangeSelectMultiple,
  allOptionsSelected,
  handleSelectAllOptions,
  handleChangeInputField,
  propertyState,
  handleChangePropertyType,
  handleChangeStreet,
  handleChangePriceField,
  handleChangeRangeFields,
  handleChangeDateField,
  handleChangeSelectSingle,
  handleChangeCheckboxField,
  handleChangeSpecialFields,
}) => {
  const { breadcrumbs, currentPage } = useBreadcrumbs([BREADCRUMBS.HOME], 'Объекты');

  return (
    <div className={classes.Filters}>
      <Breadcrumbs breadcrumbs={breadcrumbs} currentPage={currentPage} />
      <div className={classes.FiltersBody}>
        <div className="filters-container">
          <div className={classes.FiltersInputs}>
            <div>
              <PropertyTypeContainer propertyState={propertyState} handleChange={handleChangePropertyType} />
              <FormGroup>
                {subwayDistrictToggle === 'district' ? (
                  <SelectMultipleContainer
                    formControlStyle={FormControlGeneralStyle(undefined)}
                    formLabelStyle={FormLabelGeneralStyle()}
                    formInputStyle={FormSelectGeneralStyle('6px', '300px')}
                    list={DistrictLines}
                    label="Районы"
                    name="district"
                    selectedValue={filtersPayload.district}
                    handleChange={handleChangeSelectMultiple}
                  />
                ) : (
                  <SelectMultipleContainer
                    formControlStyle={FormControlGeneralStyle(undefined)}
                    formLabelStyle={FormLabelGeneralStyle()}
                    formInputStyle={FormSelectGeneralStyle('6px', '300px')}
                    list={SubwayLinesList}
                    label="Станции метро"
                    isGroup
                    isSearchRequired
                    customFormStyle={{ width: '300px!important' }}
                    allOptionTitle="Все станции"
                    colorGroupedTitles={[COLORS.RED, COLORS.BLUE, COLORS.GREEN]}
                    name="subwayStations"
                    selectedValue={filtersPayload.subwayStations}
                    handleChange={handleChangeSelectMultiple}
                    allSelected={allOptionsSelected.subway}
                    handleSelectAllOptions={handleSelectAllOptions}
                  />
                )}
                <div className="d-flex" style={{ margin: '0px 24px' }}>
                  <Checkbox
                    color="success"
                    onChange={handleDistrictCheckboxChange}
                    icon={<SubwayIcon />}
                    checked={subwayDistrictToggle === 'subway'}
                    checkedIcon={<SubwayIconChecked />}
                  />
                  <Checkbox
                    sx={FormCheckboxHoverYellow}
                    onChange={handleDistrictCheckboxChange}
                    icon={<DistrictIcon />}
                    checked={subwayDistrictToggle === 'district'}
                    checkedIcon={<DistrictIconChecked />}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                {streetResidenceToggle === 'residence' ? (
                  <SelectMultipleContainer
                    formControlStyle={FormControlGeneralStyle(undefined)}
                    formLabelStyle={FormLabelGeneralStyle()}
                    formInputStyle={FormSelectGeneralStyle('6px', '300px')}
                    list={ResidenceList}
                    label="ЖК"
                    name="residence"
                    selectedValue={filtersPayload.residence}
                    handleChange={handleChangeSelectMultiple}
                  />
                ) : (
                  <StreetFieldContainer street={filtersPayload.street} handleChange={handleChangeStreet} />
                )}
                <div className="d-flex" style={{ margin: '0px 24px' }}>
                  <Checkbox
                    color="success"
                    onChange={handleResidenceCheckboxChange}
                    icon={<StreetIcon />}
                    checked={streetResidenceToggle === 'street'}
                    checkedIcon={<StreetIconChecked />}
                  />
                  <Checkbox
                    sx={FormCheckboxHoverYellow}
                    onChange={handleResidenceCheckboxChange}
                    icon={<ResidenceIcon />}
                    checked={streetResidenceToggle === 'residence'}
                    checkedIcon={<ResidenceIconChecked />}
                  />
                </div>
              </FormGroup>
            </div>
            <div>
              <PriceFieldContainer handleChange={handleChangePriceField} values={filtersPayload.price} />
              <RangeFieldContainer
                label="Площадь"
                formControlStyle={FormControlGeneralStyle()}
                formLabelStyle={FormLabelGeneralStyle('', '0px')}
                formInputStyle={FormInputGeneralStyle('initial')}
                currentType="numeric"
                filterNames={['squareMin', 'squareMax']}
                handleChange={handleChangeRangeFields}
                values={filtersPayload.square}
              />
              <RangeFieldContainer
                label="Этажность"
                formControlStyle={FormControlGeneralStyle()}
                formLabelStyle={FormLabelGeneralStyle('', '0px')}
                formInputStyle={FormInputGeneralStyle('initial')}
                currentType="numeric"
                filterNames={['floorsMin', 'floorsMax']}
                handleChange={handleChangeRangeFields}
                values={filtersPayload.floors}
              />
              <RangeFieldContainer
                label="Кол-во комнат"
                formControlStyle={FormControlGeneralStyle()}
                formLabelStyle={FormLabelGeneralStyle('', '0px')}
                formInputStyle={FormInputGeneralStyle('initial')}
                currentType="numeric"
                filterNames={['roomsMin', 'roomsMax']}
                handleChange={handleChangeRangeFields}
                values={filtersPayload.rooms}
              />
              <FormControl
                sx={{ ...FormControlGeneralStyle('0px 24px'), width: 'fit-content' }}
                component="fieldset"
                variant="standard"
              >
                <FormLabel sx={FormLabelGeneralStyle()} component="legend" />
                <FormGroup>
                  <CheckboxLabelFieldContainer
                    label="Эксклюзив"
                    name="exclusive"
                    formLabelStyle={FormLabelGeneralStyle(undefined, '0px')}
                    checked={filtersPayload.exclusive}
                    handleChange={handleChangeCheckboxField}
                  />
                </FormGroup>
              </FormControl>
            </div>
            <div>
              <InputFieldContainer
                label="ID"
                isInline
                name="vfId"
                formControlStyle={FormControlGeneralStyle(undefined, '280px')}
                formLabelStyle={FormLabelGeneralStyle(undefined, '0px')}
                formInputStyle={FormInputGeneralStyle('6px', '255px')}
                placeholder={VERTICAL.ID}
                value={filtersPayload.vfId}
                handleChange={handleChangeInputField}
              />
              <DateFieldContainer
                name="createdAt"
                values={filtersPayload.createdAt}
                handleChange={handleChangeDateField}
              />
              <SelectSingleFieldContainer
                label="Статус"
                isInline
                name="status"
                formControlStyle={FormControlGeneralStyle()}
                formLabelStyle={FormLabelGeneralStyle(undefined, '0px')}
                formInputStyle={FormSelectGeneralStyle('6px', '255px')}
                options={FlatStatusSelectOptions}
                selectedValue={filtersPayload.status}
                handleChange={handleChangeSelectSingle}
              />
              <SelectSingleFieldContainer
                label="Тип клиента"
                isInline
                name="clientType"
                formControlStyle={FormControlGeneralStyle()}
                formLabelStyle={FormLabelGeneralStyle(undefined, '0px')}
                formInputStyle={FormSelectGeneralStyle('6px', '255px')}
                options={ClientTypeSelectOptions}
                selectedValue={filtersPayload.clientType}
                handleChange={handleChangeSelectSingle}
              />
              <SelectMultipleContainer
                formControlStyle={FormControlGeneralStyle(undefined)}
                formLabelStyle={FormLabelGeneralStyle(undefined, '0px')}
                formInputStyle={FormSelectGeneralStyle('6px', '255px')}
                list={TeamTypeSelectMultipleOptions}
                label="Команда"
                placeholderLabel="Выбрать"
                isInline
                selectedValue={[]}
                handleChange={handleChangeSelectMultiple}
              />
            </div>
          </div>
        </div>
        <div className={classes.FiltersIcons}>
          <IconsBoxContainer
            icons={[
              BedIcon,
              StudioIcon,
              ChildrenIcon,
              DogIcon,
              CatIcon,
              SmokingIcon,
              ForeignersIcon,
              BathroomIcon,
              BakeIcon,
            ]}
            iconsState={filtersPayload.special}
            handleChange={handleChangeSpecialFields}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(Filters);
