export const ATTACHMENTS_RULES = {
  className: 'dropzone',
  fileTypes: {
    'image/jpeg': ['.jpeg', '.png', '.webp'],
    'image/png': ['.png'],
  },
  maxFilesAllowed: 10,
  deleteMode: {
    api: 'api',
    local: 'local',
  },
};
