import { PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../models/IError';
import { ICustomer } from '../models/ICustomer';
import { EditCustomerPayloadProps } from '../types/Forms/FormPropsTypes';

export const handleAPIBaseRequest = (reducer: any) => ({
  [reducer.pending.type]: (state: { loading: boolean; errors: never[] }) => {
    state.loading = true;
    state.errors = [];
  },
  [reducer.fulfilled.type]: (state: { loading: boolean }) => {
    state.loading = false;
  },
  [reducer.rejected.type]: (state: { loading: boolean; errors: any[] }, action: PayloadAction<IError>) => {
    state.loading = false;
    state.errors = [...state.errors, action.payload];
  },
});

export const handleAPIManageCustomer = (reducer: any) => ({
  [reducer.pending.type]: (state: { loading: boolean; errors: never[] }) => {
    state.loading = true;
    state.errors = [];
  },
  [reducer.fulfilled.type]: (
    state: { loading: boolean; customer: ICustomer },
    action: PayloadAction<EditCustomerPayloadProps>,
  ) => {
    state.loading = false;
    const { customer } = action.payload;
    state.customer = customer;
  },
  [reducer.rejected.type]: (state: { loading: boolean; errors: any[] }, action: PayloadAction<IError>) => {
    state.loading = false;
    state.errors = [...state.errors, action.payload];
  },
});
