import React, { FC, useEffect, useState } from 'react';
import { CustomerCardContainerProps } from '../../../types/Customers/CustomersPropsTypes';
import CustomerCard from '../../../components/Customers/Customer/CustomerCard';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { getResourceContactData } from '../../../store/actions/Flats/FlatThunk';
import { STATUSES } from '../../../constants/statuses';
import { setFlatWebLink } from '../../../store/actions/Flats/FlatActions';
import { Notify } from '../../../utils/notifications';
import { getCustomerContactData } from '../../../store/actions/Customer/CustomerThunk';

const CustomerCardContainer: FC<CustomerCardContainerProps> = ({
  customer,
  isPersonalPage = false,
  isNewFlatPage = false,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { user } = useAppSelector(state => state.userReducer);

  const dispatch = useAppDispatch();

  const [phonesData, setPhonesData] = useState<any>(null);

  useEffect(() => {
    if (customer?.phones) {
      setPhonesData(customer?.phones);
    }
  }, [customer?.phones])

  useEffect(() => {
    setPhonesData(null);
  }, [isPersonalPage, isNewFlatPage])

  const handleGetContactData = async () => {
    const { payload } = await dispatch(getCustomerContactData({ uuid: customer?.uuid }));

    if (payload.status === STATUSES.SUCCESS && payload?.contactData) {
        return setPhonesData(payload.contactData);
    }
     return Notify({ message: 'Ошибка получения данных', type: 'error' });
  }

  const handleEditMode = () => {
    setPhonesData(null);
    setIsEditMode(!isEditMode);
  };

  if (!customer) {
    return null;
  }

  return (
    <CustomerCard
      customer={customer}
      isPersonalPage={isPersonalPage}
      handleEditMode={handleEditMode}
      isEditMode={isEditMode}
      user={user}
      isNewFlatPage={isNewFlatPage}
      handleGetContactData={handleGetContactData}
      phonesData={phonesData}
    />
  );
};

export default CustomerCardContainer;
