import { AppDispatch } from '../../store';
import { userSlice } from '../../reducers/UserSlice';
import { IUserAuth } from '../../../models/IUser';
import { Notify } from '../../../utils/notifications';
import getRemainedTime from '../../../utils/getRemainedTime';
import { parseExpiresInTime } from '../../../utils/parseExpiresInTime';

export const clearUIError = () => (dispatch: AppDispatch) => dispatch(userSlice.actions.clearUIErrors());

export const setupUser = (payload: IUserAuth) => (dispatch: AppDispatch) =>
  dispatch(userSlice.actions.userLogin(payload));

export const deactivateUser = () => (dispatch: AppDispatch) => {
  return dispatch(userSlice.actions.userDeactivation());
};

export const logoutUser = (time: string | null) => (dispatch: AppDispatch) => {
  if (!time) {
    return null;
  }

  const currentTimeType = time.includes('d') ? 'days' : 'hours';
  const finishDate = getRemainedTime(Number(parseExpiresInTime(time)), currentTimeType);
  return setTimeout(() => {
    const listener = () => {
      if (!document.hidden) {
        setTimeout(() => {
          Notify({
            message: 'Срок действия вашей сессии истек',
            type: 'error',
          });
        }, 1000);
      }
    };

    document.addEventListener('visibilitychange', listener, false);
    document.addEventListener(
      'visibilitychange',
      () => {
        if (document.hidden) {
          document.removeEventListener('visibilitychange', listener);
        }
      },
      false,
    );
    dispatch(userSlice.actions.userDeactivation());
  }, ((new Date(finishDate).getTime() - new Date().getTime()) / 1000) * 1000);
};

export const autoSetupUser = () => (dispatch: AppDispatch) => dispatch(userSlice.actions.autoUserSetup());
