import React, { FC, memo, useEffect, useRef } from 'react';
import classes from './FlatToPrint.module.scss';
import SVGIcon from '../../UI/Icons/IconBody';
import { FLATS_ICONS } from '../../../mappings/Flats/flatsStatuses';
import { formatStreetAddress } from '../../../utils/formatStreetAddress';
import Logotype from '../../../assets/images/logo.png';
import { useAppDispatch } from '../../../hooks/redux';
import { transformImageURL } from '../../../constants/api';
import classNames from 'classnames';

import { FlatToPrintProps } from '../../../types/Flats/FlatsPropsTypes';
import { FloorsIcon, GpsIcon, LocationIcon, RoomsIcon, SquaresIcon } from '../../UI/Icons';

const FlatToPrint: FC<FlatToPrintProps> = ({ flat }) => {
  const dispatch = useAppDispatch();
  const componentRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      document.title = `VF-${flat.vfId}`;
      // window.print();
    }, 0);
  }, [window]);

  useEffect(() => {
    window.onafterprint = () => {
      document.title = 'Vertical';
      // setTimeout(() => dispatch(setFlatOption({ type: 'isPrintingFlat', value: false })), 0);
    };
  }, [window]);

  const {
    files,
    title,
    adTitle,
    rooms,
    floors,
    square,
    street,
    district,
    residence,
    timeToSubway,
    special,
    adText,
    price,
  } = flat;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (
    <div className="flat-print-container">
      <div className={classes.FlatInfoHeaderContent}>
        <img src={Logotype} alt="Logotype" />
        <div className={classes.FlatInfoHeaderContentCenter}>
          <p className={classes.FlatInfoHeaderContentTitle}>{title?.replaceAll('Сдам в аренду квартиру', '')}</p>
          <p className={classes.FlatInfoHeaderContentPrice}>{price} грн</p>
        </div>

        <div className={classes.FlatInfoHeaderContentContacts}>
          <p>+38(098)388-26-26</p>
          <p>an@vertical.in.ua</p>
        </div>
      </div>
      <hr style={{ margin: '10px 17px 0 17px' }} />

      <div className={classes.FlatGeneralInfoStats}>
        <div className={classes.FlatGeneralInfoStatsItem}>
          <SVGIcon currentIcon={RoomsIcon} width={30} height={30} />
          {rooms}
        </div>
        <div className={classes.FlatGeneralInfoStatsItem}>
          <SVGIcon currentIcon={FloorsIcon} width={19} height={30} /> {floors.total} / {floors.current}
        </div>
        <div className={classes.FlatGeneralInfoStatsItem}>
          <SVGIcon currentIcon={SquaresIcon} width={28} height={28} />
          <p>{square} м²</p>
        </div>
        <div className={classes.FlatGeneralInfoStatsItem} style={{ marginLeft: '50px' }}>
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          {Object.entries(special!).map(([key, value]) => {
            const Icon = FLATS_ICONS[key];

            if (!Icon || !value) {
              return null;
            }

            return (
              <SVGIcon
                key={key}
                width={30}
                height={30}
                currentIcon={Icon}
                fill="#159900"
                style={{ marginLeft: '15px' }}
              />
            );
          })}
        </div>
      </div>

      <div className={classes.FlatGeneralInfoStreet}>
        <SVGIcon currentIcon={LocationIcon} width={20.5} height={24} />
        <p>
          {formatStreetAddress(street)} {district} район{residence ? `, ЖК ${residence}` : null}
        </p>
      </div>
      <div className={classes.FlatGeneralInfoSubway}>
        {timeToSubway ? (
          <>
            <SVGIcon currentIcon={GpsIcon} width={24} height={24} />{' '}
            <p>До метро в пешей доступности до {timeToSubway} мин</p>
          </>
        ) : null}
      </div>

      <div className={classes.FlatPrintImages}>
        {transformImageURL(files).map((photo: string) => {
          return (
            <div key={photo} className={classes.FlatPrintImage}>
              <img alt="Flat Slider" className={classes.FlatPrintImageItem} src={photo} />
            </div>
          );
        })}
      </div>

      <div className={classNames(classes.FlatAdvertisement, 'pageBreak')}>
        <div className={classes.FlatAdvertisementText}>
          <p>{adTitle}</p>
          <p>{adText}</p>
        </div>
      </div>

      <div className={classes.FlatPrintTableContainer}>
        <div className={classes.FlatPrintTable}>
          <p>Внутри</p>
          <div className={classes.FlatPrintTableInfo}>
            <div className="d-flex align-items-center">
              <SVGIcon currentIcon={SquaresIcon} width={19.15} height={20} style={{ marginRight: '19.9px' }} />
              <p className="mr30" style={{ width: '150px' }}>
                Площадь общая
              </p>{' '}
              <p>{square} м²</p>
            </div>
            <div className="d-flex align-items-center">
              <SVGIcon currentIcon={RoomsIcon} width={22.12} height={22.97} style={{ marginRight: '19.9px' }} />
              <p className="mr30" style={{ width: '150px' }}>
                Кол-во комнат
              </p>{' '}
              <p>{rooms}</p>
            </div>
          </div>
        </div>

        <div className={classes.FlatPrintTable}>
          <p>Объект в целом</p>
          <div className={classes.FlatPrintTableInfo}>
            <div className="d-flex align-items-center">
              <SVGIcon currentIcon={FloorsIcon} width={17.1} height={27} style={{ marginRight: '19.9px' }} />
              <p className="mr30" style={{ width: '150px' }}>
                Этажность
              </p>{' '}
              <p>{floors.total}</p>
            </div>
            <div className="d-flex align-items-center">
              <SVGIcon currentIcon={FloorsIcon} width={17.1} height={27} style={{ marginRight: '19.9px' }} />
              <p className="mr30" style={{ width: '150px' }}>
                Этаж
              </p>{' '}
              <p>{floors.current}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(FlatToPrint);
