import React, { FC, memo } from 'react';
import classes from './Attachments.module.scss';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { IconButton } from '@mui/material';
import { IFile } from '../../../models/IFile';
import { AttachmentsPreviewComponentProps } from '../../../types/UI/Attachments/AttachmentsPropsTypes';
import { ATTACHMENTS_RULES } from '../../../constants/attachments';
import {
  AttachmentsDeleteButton,
  AttachmentsImage,
  AttachmentsThumbInner,
} from '../../../constants/styles/Buttons/Attachments';

const Preview: FC<AttachmentsPreviewComponentProps> = ({
  files,
  handleDeleteFile,
  handleOnDragEndFlatPreview,
  previewFiles,
}) => {
  const filesThumbs = files?.length
    ? files?.map((file: IFile | any) => {
        return (
          <section className={classes.AttachmentsThumb} key={file.name}>
            <div style={AttachmentsThumbInner}>
              <div>
                <IconButton
                  sx={AttachmentsDeleteButton}
                  onClick={() => handleDeleteFile(file, ATTACHMENTS_RULES.deleteMode.local)}
                >
                  <RemoveCircleIcon />
                </IconButton>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/*@ts-ignore*/}
                <Zoom onSubmit={(e: any) => e.preventDefault()} onChange={(e: any) => e.preventDefault()}>
                  <img
                    style={AttachmentsImage}
                    alt={file.name}
                    src={URL.createObjectURL(file as any)}
                    // Revoke data uri after image is loaded
                    onLoad={() => {
                      URL.revokeObjectURL(file.preview);
                    }}
                  />
                </Zoom>
              </div>
            </div>
          </section>
        );
      })
    : [];

  const flatThumbs = previewFiles?.length ? (
    <>
      <DragDropContext onDragEnd={handleOnDragEndFlatPreview}>
        <Droppable droppableId="characters" type="droppableListItem" direction="horizontal">
          {provided => (
            <ul className="characters" {...provided.droppableProps} ref={provided.innerRef}>
              {previewFiles?.map((file: IFile | any, index: number) => {
                return (
                  <Draggable key={file.id} draggableId={file.id} index={index} disableInteractiveElementBlocking>
                    {provided => (
                      <li
                        className={classes.AttachmentsThumb}
                        key={file.name}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div style={AttachmentsThumbInner}>
                          <div>
                            <IconButton
                              sx={AttachmentsDeleteButton}
                              onClick={() => handleDeleteFile(file, ATTACHMENTS_RULES.deleteMode.api)}
                            >
                              <RemoveCircleIcon />
                            </IconButton>
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/*@ts-ignore*/}
                            <Zoom onSubmit={(e: any) => e.preventDefault()} onChange={(e: any) => e.preventDefault()}>
                              <img
                                style={AttachmentsImage}
                                alt="Flat Image"
                                src={file.thumb}
                                // Revoke data uri after image is loaded
                                onLoad={() => {
                                  URL.revokeObjectURL(file);
                                }}
                              />
                            </Zoom>
                          </div>
                        </div>
                      </li>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </>
  ) : (
    []
  );

  return (
    <aside className={classes.AttachmentsThumbsContainer}>
      {flatThumbs}
      {filesThumbs}
    </aside>
  );
};

export default memo(Preview);
