import { useEffect } from 'react';

export const useFreezeWhileLoading = (loading: boolean) => {
  const handleFreeze = () => {
    document.body.style.pointerEvents = 'none';
    document.body.classList.add('blurredBody');
  };
  const handleRemoveFreeze = () => {
    document.body.classList.remove('blurredBody');
    document.body.style.removeProperty('pointer-events');
  };

  useEffect(() => {
    loading ? handleFreeze() : handleRemoveFreeze();
  }, [loading]);
};
