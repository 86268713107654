import { FlatSpecialValues } from '../../types/Forms/FormValuesTypes';

export const FlatSpecialOptions: FlatSpecialValues = {
  dog: false,
  cat: false,
  children: false,
  smoking: false,
  foreigners: false,
  bed: false,
  studio: false,
  bathroom: false,
  bake: false,
};
