import React from 'react';
import { SVGIconProps } from '../../../../../types/UI/Icons/SVGIconsTypes';

export const SubwayStationsIcon: React.FC<SVGIconProps> = ({
  width = 24,
  height = 20,
  clazz,
  fill = 'none',
  stroke,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    className={clazz}
  >
    <path
      d="M3.36364 16.663H2.66851C2.2993 16.663 2 16.9623 2 17.3315C2 17.7007 2.2993 18 2.66851 18H8.60422C8.97343 18 9.27273 17.7007 9.27273 17.3315C9.27273 16.9623 8.97343 16.663 8.60422 16.663H7.90909L9.27273 12.4228L12 17.6053L14.7273 12.4228L16.0909 16.663H15.3958C15.0266 16.663 14.7273 16.9623 14.7273 17.3315C14.7273 17.7007 15.0266 18 15.3958 18H21.3315C21.7007 18 22 17.7007 22 17.3315C22 16.9623 21.7007 16.663 21.3315 16.663H20.6364L16.4489 5.22031C16.1452 4.39039 14.9931 4.33351 14.6091 5.12949L12 10.5382L9.39088 5.12949C9.00691 4.33351 7.85482 4.39038 7.55111 5.22031L3.36364 16.663Z"
      stroke="#C68A52"
      strokeWidth="1.5"
    />
  </svg>
);
