import * as React from 'react';
import { FC, memo } from 'react';
import Typography from '@mui/material/Typography';
import { Breadcrumbs as BreadcrumbsUI } from '@mui/material';
import { BreadcrumbsProps } from '../../../types/UI/BreadcrumbsTypes';
import { NavLink } from 'react-router-dom';
import { BreadcrumbStyle } from '../../../constants/styles/breadcrumbs';
import { COLORS } from '../../../constants/colors';

const Breadcrumbs: FC<BreadcrumbsProps> = ({
  breadcrumbs,
  currentPage,
  style = { ...BreadcrumbStyle() },
  separator = '|',
  isInverse,
}) => {
  const renderCurrentPage = () => {
    if (typeof currentPage === 'string') {
      return <Typography>{currentPage}</Typography>;
    } else {
      return <Typography>{currentPage?.label}</Typography>;
    }
  };

  return (
    <div role="presentation" className="main-container" style={{ padding: '20px 0' }}>
      <BreadcrumbsUI
        aria-label="breadcrumb"
        className="breadcrumbs"
        sx={isInverse ? { ...style, color: COLORS.GENERAL } : { ...style }}
        separator={separator}
      >
        {breadcrumbs.map(breadcrumb => {
          if (!breadcrumb) return null;
          return (
            <NavLink
              key={breadcrumb.to}
              to={breadcrumb.to}
              className={isInverse ? 'breadcrumb-link-inverse' : 'breadcrumb-link'}
            >
              {breadcrumb.label}
            </NavLink>
          );
        })}
        {currentPage && renderCurrentPage()}
      </BreadcrumbsUI>
    </div>
  );
};

export default memo(Breadcrumbs);
