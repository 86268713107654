import React, { FC, memo } from 'react';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs';
import { APP_ROUTES, BREADCRUMB_LINK, BREADCRUMBS } from '../../../constants/routes';
import classes from './AddNewFlat.module.scss';
import CustomerCardContainer from '../../../containers/Customers/Customer/CustomerCardContainer';
import PropertyTypeFilter from '../Filters/PropertyType';
import { AddNewFlatForm } from '../../../Forms/Flats/AddNewFlatForm';
import MainWrapper from '../../hoc/MainWrapper';
import HeadInfoWrapper from '../../Wrappers/HeadInfoWrapper';
import { AddNewFlatComponentProps, AddNewFlatOLXComponentProps } from '../../../types/Flats/FlatsPropsTypes';
import PropertyTypeContainer from '../../../containers/Flats/Filter/PropertyTypeContainer';
import { AddNewFlatOLXForm } from '../../../Forms/Flats/AddNewFlatOLXForm';

const AddNewFlatOLX: FC<AddNewFlatOLXComponentProps> = ({
                                                    customer,
                                                    handleAddNewFlat,
                                                    propertyTypeState,
                                                    handleChangePropertyType,
                                                    handleChangeStreet,
                                                  }) => {
  const { breadcrumbs, currentPage } = useBreadcrumbs(
    [
      BREADCRUMBS.HOME,
      BREADCRUMBS.OBJECTS,
    ],
    'Добавить квартиру OLX',
  );

  return (
    <MainWrapper>
      <HeadInfoWrapper
        breadcrumbs={breadcrumbs}
        currentPage={currentPage}
        bodyStyle={{ height: '346px' }}
        headStyle={{ height: '449px' }}
      >
        <div className={classes.NewFlat}>
          <h2>Добавление объекта OLX</h2>
          <CustomerCardContainer customer={customer} isNewFlatPage />
          <div className="w-full d-flex align-items-center justify-content-between" style={{ marginTop: '40px' }}>
            <PropertyTypeContainer propertyState={propertyTypeState} isInline handleChange={handleChangePropertyType} />
          </div>
        </div>
      </HeadInfoWrapper>
      <AddNewFlatOLXForm
        handleAddNewFlat={handleAddNewFlat}
        customer={customer}
        handleChangeStreet={handleChangeStreet}
      />
    </MainWrapper>
  );
};

export default memo(AddNewFlatOLX);
