import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../models/IError';
import {
  addFlat,
  addFlatFiles, addOLXFlat,
  deleteFlat,
  editFlat,
  getAllFlats,
  getFlat,
  removeUploadedFile,
  updateFlatImagesPosition,
} from '../actions/Flats/FlatThunk';
import { IDeleteFlatPayload, IFlat, IFlatPayload } from '../../models/IFlat';
import { handleAPIBaseRequest } from '../../utils/handleAPIBaseRequest';
import { FlatSpecialValues } from '../../types/Forms/FormValuesTypes';
import { FlatSpecialOptions } from '../../mappings/Flats/flatsData';
import { filterFlatsByQueries } from '../actions/Flats/Filters/FiltersThunk';
import { IInfoNotification } from '../../models/IInfoNotification';
import { IFile } from '../../models/IFile';

interface flatFiltersState {
  street: string | undefined;
  propertyType: string;
  clientType: string;
  notification: IInfoNotification | undefined;
  attachedFiles: IFile[] | undefined | any;
  special: any;
  flats: any;
  flatsPagesLimit: number;
  maxFlatPrice: number;
  flatPreview: IFlat | undefined;
  isPrintingFlat: boolean;
  loading: boolean;
  errors: Array<IError>;
  currentPage: number;
  isEditMode: boolean;
}

export interface PayloadSetNewOption {
  type: any;
  value: string | undefined | any;
}

export type ConditionalPayloadOptions = PayloadSetNewOption | null;

const initialState: flatFiltersState = {
  street: '',
  propertyType: '',
  clientType: '',
  notification: undefined,
  errors: [],
  attachedFiles: [],
  special: FlatSpecialOptions,
  flats: [],
  flatsPagesLimit: 0,
  maxFlatPrice: 0,
  flatPreview: undefined,
  isPrintingFlat: false,
  loading: false,
  currentPage: 1,
  isEditMode: false,
};

export const flatSlice = createSlice({
  name: 'flat',
  initialState,
  reducers: {
    setNewOption: (state, action: PayloadAction<PayloadSetNewOption>) => {
      const { type, value } = action.payload;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state[type] = value;
    },
    setFiles: (state, action: PayloadAction<IFile[]>) => {
      state.attachedFiles = action.payload;
    },
    setSpecials: (state, action: PayloadAction<FlatSpecialValues>) => {
      state.special = action.payload;
    },
    setFlatLink: (state, action: PayloadAction<any>) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.flatPreview.webLink = action.payload;
    },
    clearUIAddFlatOptions: state => {
      state.street = '';
      state.attachedFiles = [];
      state.special = FlatSpecialOptions;
      state.flatPreview = undefined;
      state.isEditMode = false;
    },
    setCustomerFlats: (state, action: PayloadAction<IFlat[]>) => {
      state.loading = false;
      state.flats = action.payload;
    },
  },
  extraReducers: {
    [getAllFlats.pending.type]: state => {
      state.loading = true;
      state.errors = [];
    },
    [getAllFlats.fulfilled.type]: (state, action: PayloadAction<IFlatPayload>) => {
      state.loading = false;
      state.flats = action.payload.flats;
      state.maxFlatPrice = action.payload.maxPrice;
      state.flatsPagesLimit = action.payload.limit;
    },
    [getAllFlats.rejected.type]: (state, action: PayloadAction<IError>) => {
      state.loading = false;
      state.errors = [...state.errors, action.payload];
    },
    [filterFlatsByQueries.pending.type]: state => {
      state.loading = true;
      state.errors = [];
    },
    [filterFlatsByQueries.fulfilled.type]: (state, action: PayloadAction<IFlatPayload>) => {
      state.loading = false;
      state.flats = action.payload.flats;
      state.flatsPagesLimit = action.payload.limit;
      state.maxFlatPrice = action.payload.maxPrice;
    },
    [filterFlatsByQueries.rejected.type]: (state, action: PayloadAction<IError>) => {
      state.loading = false;
      state.errors = [...state.errors, action.payload];
    },
    ...handleAPIBaseRequest(addFlat),
    [deleteFlat.pending.type]: state => {
      state.loading = true;
      state.errors = [];
    },
    [deleteFlat.fulfilled.type]: (state, action: PayloadAction<IDeleteFlatPayload>) => {
      state.loading = false;
      state.flats = action.payload.flats;
      state.maxFlatPrice = action.payload.maxPrice;
      state.flatsPagesLimit = action.payload.limit;
      state.notification = action.payload.notification;
    },
    [deleteFlat.rejected.type]: (state, action: PayloadAction<IError>) => {
      state.loading = false;
      state.errors = [...state.errors, action.payload];
    },
    [getFlat.pending.type]: state => {
      state.loading = true;
      state.errors = [];
    },
    [getFlat.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.flatPreview = action.payload.flat;
      state.special = action.payload.flat.special;
      state.propertyType = action.payload.flat.propertyType;
      state.street = action.payload.flat.street;
    },
    [getFlat.rejected.type]: (state, action: PayloadAction<IError>) => {
      state.loading = false;
      state.errors = [...state.errors, action.payload];
    },
    [addFlatFiles.pending.type]: state => {
      state.errors = [];
    },
    [addFlatFiles.fulfilled.type]: (state, action: PayloadAction<IFlat>) => {
      state.loading = false;
      state.flatPreview = action.payload;
    },
    [addFlatFiles.rejected.type]: (state, action: PayloadAction<IError>) => {
      state.loading = false;
      state.errors = [...state.errors, action.payload];
    },
    [addFlat.pending.type]: state => {
      state.loading = true;
      state.errors = [];
    },
    [addFlat.fulfilled.type]: state => {
      state.loading = false;
    },
    [addFlat.rejected.type]: (state, action: PayloadAction<IError>) => {
      state.loading = false;
      state.errors = [...state.errors, action.payload];
    },
    [addOLXFlat.pending.type]: state => {
      state.loading = true;
      state.errors = [];
    },
    [addOLXFlat.fulfilled.type]: state => {
      state.loading = false;
    },
    [addOLXFlat.rejected.type]: (state, action: PayloadAction<IError>) => {
      state.loading = false;
      state.errors = [...state.errors, action.payload];
    },
    [updateFlatImagesPosition.pending.type]: state => {
      state.errors = [];
    },
    [updateFlatImagesPosition.fulfilled.type]: (state, action: PayloadAction<IFlat>) => {
      state.loading = false;
      state.flatPreview = action.payload;
    },
    [updateFlatImagesPosition.rejected.type]: (state, action: PayloadAction<IError>) => {
      state.loading = false;
      state.errors = [...state.errors, action.payload];
    },
    [removeUploadedFile.pending.type]: state => {
      state.errors = [];
    },
    [removeUploadedFile.fulfilled.type]: (state, action: PayloadAction<IFlat>) => {
      state.loading = false;
      state.flatPreview = action.payload;
    },
    [removeUploadedFile.rejected.type]: (state, action: PayloadAction<IError>) => {
      state.loading = false;
      state.errors = [...state.errors, action.payload];
    },
    [editFlat.pending.type]: state => {
      state.loading = true;
      state.errors = [];
    },
    [editFlat.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.loading = false;
      const { flat } = action.payload;
      state.flatPreview = flat;
      state.notification = action.payload.notification;
    },
    [editFlat.rejected.type]: (state, action: PayloadAction<IError>) => {
      state.loading = false;
      state.errors = [...state.errors, action.payload];
    },
  },
});

export default flatSlice.reducer;
