// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfirmationModal_ConfirmationModal__2Z7Oj {
  display: block;
}
.ConfirmationModal_ConfirmationModalContent__HAOqp {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  border: 1px solid #008DDC;
  box-shadow: 0 0 34px rgba(0, 141, 220, 0.4);
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  padding: 30px;
}
.ConfirmationModal_ConfirmationModalTitle__fCHVi {
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
}
.ConfirmationModal_ConfirmationModalText__zrvq7 {
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Modals/ConfirmationModal.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AACE;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,YAAA;EACA,yBAAA;EACA,2CAAA;EACA,kBAAA;EACA,oCAAA;EACA,aAAA;AACJ;AAEE;EACE,eAAA;EACA,iBAAA;EACA,gBAAA;AAAJ;AAGE;EACE,eAAA;EACA,iBAAA;EACA,mBAAA;AADJ","sourcesContent":[".ConfirmationModal {\n  display: block;\n\n  &Content {\n    position: absolute;\n    top: 45%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 800px;\n    border: 1px solid #008DDC;\n    box-shadow: 0 0 34px rgb(0 141 220 / 40%);\n    border-radius: 5px;\n    background-color: rgb(255, 255, 255);\n    padding: 30px;\n  }\n\n  &Title {\n    font-size: 18px;\n    line-height: 32px;\n    font-weight: 600;\n  }\n\n  &Text {\n    font-size: 18px;\n    line-height: 32px;\n    margin-bottom: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ConfirmationModal": `ConfirmationModal_ConfirmationModal__2Z7Oj`,
	"ConfirmationModalContent": `ConfirmationModal_ConfirmationModalContent__HAOqp`,
	"ConfirmationModalTitle": `ConfirmationModal_ConfirmationModalTitle__fCHVi`,
	"ConfirmationModalText": `ConfirmationModal_ConfirmationModalText__zrvq7`
};
export default ___CSS_LOADER_EXPORT___;
