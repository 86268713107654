import { Navigate } from 'react-router-dom';
import React, { FC } from 'react';
import { RouteProps } from './RoutesTypes';
import { APP_ROUTES } from '../constants/routes';

const PublicRoute: FC<RouteProps> = ({ session, component: Component }) => {
  if (!session.token) {
    return <Component />;
  }
  return <Navigate to={APP_ROUTES.HOME} />;
};

export default PublicRoute;
