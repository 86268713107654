// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BACKGROUNDS */
/* COLORS */
/* FONTS */
/* SIZES */
.Footer_Footer__MEpnh {
  display: block;
  width: 100%;
  background: rgba(43, 60, 70, 0.93);
  height: 72px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/_variables.scss","webpack://./src/components/Footer/Footer.module.scss"],"names":[],"mappings":"AAEA,gBAAA;AAIA,WAAA;AAQA,UAAA;AAEA,UAAA;ACdA;EACE,cAAA;EACA,WAAA;EACA,kCDDc;ECEd,YAAA;AAIF","sourcesContent":["@import './_fonts.scss';\n\n/* BACKGROUNDS */\n\n$main-bg-color: rgba(43, 60, 70, 0.93);\n\n/* COLORS */\n\n$white: #fff;\n$black: #000;\n$main-color: #2B3C46;\n$main-orange-color: #c68a52;\n$main-date-hover-color: #747f84;\n\n/* FONTS */\n\n/* SIZES */\n\n$nav-icon-size: 30px;\n\n$main-font: 'Poppins', sans-serif;\n","@import '../../assets/styles/variables';\n\n.Footer {\n  display: block;\n  width: 100%;\n  background: $main-bg-color;\n  height: 72px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Footer": `Footer_Footer__MEpnh`
};
export default ___CSS_LOADER_EXPORT___;
