import React, { FC, memo } from 'react';
import { FormControl, FormGroup, FormLabel, TextField } from '@mui/material';
import { InputLabelPropsStyle } from '../../../../constants/styles/Forms/labels';
import { FormInputPropsGeneralStyle } from '../../../../constants/styles/Forms/inputs';
import { COLORS } from '../../../../constants/colors';
import { RangeFieldComponentProps } from '../../../../types/UI/Fields/FieldsPropsTypes';

const RangeField: FC<RangeFieldComponentProps> = ({
  label,
  formInputStyle,
  formGroupStyle,
  formControlStyle,
  formLabelStyle,
  required,
  labels = ['От', 'До'],
  currentType,
  removeMarginRight,
  validateMultiple,
  filterNames,
  handleChange,
  values,
}) => {
  const highlightFirstError = {
    '.MuiOutlinedInput-root fieldset': {
      borderColor: validateMultiple && validateMultiple[0].error ? `${COLORS.ERROR}!important` : 'inherit',
    },
  };

  const highlightSecondError = {
    '.MuiOutlinedInput-root fieldset': {
      borderColor: validateMultiple && validateMultiple[1].error ? `${COLORS.ERROR}!important` : 'inherit',
    },
  };

  return (
    <FormControl sx={formControlStyle} component="fieldset" variant="standard">
      <div className="d-flex align-items-center range_test">
        <FormLabel sx={formLabelStyle} component="legend">
          {required ? (
            <p>
              {label}
              <span className="requiredLabel">*</span>
            </p>
          ) : (
            label
          )}
        </FormLabel>
        <FormGroup sx={formGroupStyle}>
          <div>
            <TextField
              inputProps={
                currentType === 'numeric'
                  ? {
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                      style: { ...FormInputPropsGeneralStyle() },
                    }
                  : {
                      style: { ...FormInputPropsGeneralStyle(undefined, '100%') },
                    }
              }
              sx={{ ...formInputStyle, ...highlightFirstError }}
              id="outlined-basic"
              variant="outlined"
              defaultValue={null}
              value={values[0] > 0 ? values[0].toString() : ''}
              label={labels[0]}
              InputLabelProps={{
                style: { ...InputLabelPropsStyle(undefined, '', '5px') },
              }}
              name={filterNames ? filterNames[0] : ''}
              onChange={handleChange}
              size="small"
              type={currentType === 'numeric' ? 'number' : 'text'}
              aria-valuemax={9000}
              {...(validateMultiple && { ...validateMultiple[0] })}
            />
            <TextField
              inputProps={
                currentType === 'numeric'
                  ? {
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                      style: { ...FormInputPropsGeneralStyle() },
                    }
                  : {
                      style: { ...FormInputPropsGeneralStyle(undefined, '100%') },
                    }
              }
              sx={{
                ...formInputStyle,
                marginRight: removeMarginRight ? '0px!important' : 'initial',
                ...highlightSecondError,
              }}
              id="outlined-basic"
              variant="outlined"
              size="small"
              name={filterNames ? filterNames[1] : ''}
              onChange={handleChange}
              value={values[1] > 0 ? values[1].toString() : ''}
              label={labels[1]}
              type={currentType === 'numeric' ? 'number' : 'text'}
              InputLabelProps={{
                style: { ...InputLabelPropsStyle(undefined, '', '5px') },
              }}
              aria-valuemax={9000}
              {...(validateMultiple && { ...validateMultiple[1] })}
            />
          </div>
        </FormGroup>
      </div>
    </FormControl>
  );
};
export default memo(RangeField);
