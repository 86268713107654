import React from 'react';
import { SVGIconProps } from '../../../../types/UI/Icons/SVGIconsTypes';

export const RoomsIcon: React.FC<SVGIconProps> = ({ width = 30, height = 30, clazz }) => (
  <svg width={width} height={height} viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.0625 12.8318H15.9375V16.5818H14.0625V12.8318Z" fill="#2F6D8E" />
    <path
      d="M24.8438 24.0817V4.39424H19.6875V0L4.21875 2.66701V24.0817H0V25.9567H5.0918L19.6875 27.9699V6.26924H22.9688V25.9567H28.125V24.0817H24.8438ZM17.8125 25.8186L6.09375 24.2022V4.24646L17.8125 2.22627V25.8186Z"
      fill="#2F6D8E"
      className={clazz}
    />
  </svg>
);
