import React, { FC } from 'react';
import SVGIcon from '../Icons/IconBody';
import { AddIcon } from '../Icons/Navbar/AddIcon';
import { IconButton } from '@mui/material';
import { AddButtonProps } from '../../../types/UI/Buttons/ButtonsPropsTypes';

export const AddButton: FC<AddButtonProps> = ({ size = 'normal', onClick, style }) => {
  const iconSizes: any = {
    small: {
      width: '23.61px',
      height: '23.61px',
    },
    normal: {
      width: '36px',
      height: '36px',
    },
  };
  return (
    <IconButton aria-label="add" onClick={() => onClick('add')}>
      <SVGIcon {...iconSizes[size]} currentIcon={AddIcon} style={style} />
    </IconButton>
  );
};
