import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteFlat, getFlat, getResourceContactData } from '../../../store/actions/Flats/FlatThunk';
import MainWrapper from '../../../components/hoc/MainWrapper';
import Breadcrumbs from '../../../components/UI/Breadcrumbs/Breadcrumbs';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs';
import { APP_ROUTES, BREADCRUMBS } from '../../../constants/routes';
import FlatToPrint from '../../../components/Flats/view/FlatToPrint';
import Flat from '../../../components/Flats/view/Flat';
import getRouteURI from '../../../utils/getRouteURI';
import { setFlatOption } from '../../../store/actions/Flats/FlatActions';
import { Notify } from '../../../utils/notifications';
import { IActionMenu } from '../../../models/IActionMenu';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { STATUSES } from '../../../constants/statuses';
import { downloadFlatPDF } from '../../../components/Flats/Flats';

const FlatContainer: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { uuid } = useParams();

  const { loading, flatPreview, isPrintingFlat } = useAppSelector(state => state.flatReducer);
  const { user } = useAppSelector(state => state.userReducer);
  const { filtersPayload } = useAppSelector(state => state.flatFiltersReducer);

  const { breadcrumbs, currentPage } = useBreadcrumbs([BREADCRUMBS.HOME, BREADCRUMBS.OBJECTS], {
    label: flatPreview?.title,
  });

  const [isDeleteModal, setIsDeleteModal] = useState<any>({ isOped: false, uuid: null, vfId: null });
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const [webLinkData, setWebLinkData] = React.useState(null);
  const [contactDetailsData, setContactDetailsData] = React.useState(null);

  const handleGetContactData = async (resourceKey: string) => {
    const { payload } = await dispatch(getResourceContactData({ resourceKey, uuid }));

    if (payload.status === STATUSES.SUCCESS && payload?.contactData) {
      if (resourceKey === 'webLink') {
        return setWebLinkData(payload.contactData);
      }

      if (resourceKey === 'phone') {
        return setContactDetailsData(payload.contactData);
      }
    } else {
      Notify({ message: 'Ошибка получения данных', type: 'error' });
    }
  }

  const handleEditMode = useCallback(() => {
    dispatch(setFlatOption({ type: 'isEditMode', value: true }));
    return navigate(getRouteURI(APP_ROUTES.FLAT_EDIT, { uuid: flatPreview?.uuid }), { state: { editMode: true } });
  }, [dispatch, flatPreview?.uuid, navigate]);

  const onInitialize = useCallback(
    async (uuid?: string) => {
      if (!uuid) {
        return null;
      }
      await dispatch(getFlat(uuid));
    },
    [dispatch],
  );

  useEffect(() => {
    onInitialize(uuid);
  }, [onInitialize, uuid]);

  const handleDeleteFlat = useCallback(
    (currentUUID: string) => {
      if (!currentUUID) {
        return Notify({ message: 'Отсутствует UUID.', type: 'error' });
      }
      dispatch(deleteFlat({ page: currentPage, filtersPayload, uuid: currentUUID }));
      setIsDeleteModal({ isOped: false, uuid: null, vfId: null });
      navigate(getRouteURI(APP_ROUTES.OBJECTS, { page: currentPage, filtersPayload }));
    },
    [currentPage, dispatch, filtersPayload, navigate],
  );

  const handleOpenFullWidth = useCallback((index: number) => {
    setPhotoIndex(index);
    setIsOpen(true);
  }, []);

  const FlatActions: IActionMenu[] = useMemo(
    () =>
      user?.role === 'tester'
        ? [
            {
              label: 'Скачать',
              disableRipple: true,
              icon: DownloadOutlinedIcon,
              disabled: !flatPreview || !flatPreview.files?.length,
              onClick: async () => {
                await handleGetContactData('download')
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                await downloadFlatPDF(uuid, flatPreview?.vfId);
                return null;
              },
            },
          ]
        : [
            {
              label: 'Скачать',
              disableRipple: true,
              icon: DownloadOutlinedIcon,
              disabled: !flatPreview || !flatPreview.files?.length,
              onClick: async () => {
                await handleGetContactData('download');
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                await downloadFlatPDF(uuid, flatPreview?.vfId);
                return null;
              },
            },
            {
              label: 'Редактировать',
              disableRipple: true,
              icon: EditIcon,
              disabled: false,
              onClick: () => handleEditMode(),
            },
            {
              label: 'Дублировать',
              disableRipple: true,
              icon: ContentCopyIcon,
              disabled: true,
              // onClick: () => handleEditMode(),
            },
            {
              label: '',
              disableRipple: undefined,
              divider: true,
            },
            {
              label: 'Закрыть',
              disableRipple: true,
              icon: DoDisturbOnOutlinedIcon,
              disabled: true,
            },
            {
              label: 'Архивировать',
              disableRipple: true,
              icon: ArchiveIcon,
              disabled: true,
            },
            {
              label: 'Удалить',
              disableRipple: true,
              icon: DeleteOutlineIcon,
              disabled: false,
              onClick: () => setIsDeleteModal({ isOpen: true, uuid, vfId: flatPreview && flatPreview.vfId }),
            },
          ],
    [dispatch, flatPreview, handleEditMode, uuid],
  );

  if (!flatPreview) {
    return null;
  }

  if (isPrintingFlat) {
    return <FlatToPrint flat={flatPreview} />;
  }

  return (
    <>
      <MainWrapper>
        <Breadcrumbs breadcrumbs={breadcrumbs} currentPage={currentPage} isInverse />
        <Flat
          flat={flatPreview}
          setIsDeleteModal={setIsDeleteModal}
          isDeleteModal={isDeleteModal}
          handleDeleteFlat={handleDeleteFlat}
          fetching={loading}
          flatActions={FlatActions}
          isOpenFullWidth={isOpen}
          setIsOpenFullWidth={setIsOpen}
          handleOpenFullWidth={handleOpenFullWidth}
          photoIndex={photoIndex}
          handleGetContactData={handleGetContactData}
          webLinkData={webLinkData}
          contactDetailsData={contactDetailsData}
        />
      </MainWrapper>
    </>
  );
};

export default FlatContainer;
