import { IBreadcrumb } from '../models/IBreadcrumb';

const useBreadcrumbs = (breadcrumbs: IBreadcrumb[] | any, currentPage?: any) => {
  return {
    breadcrumbs,
    currentPage,
  };
};

export default useBreadcrumbs;
