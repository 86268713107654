import { createSelector } from '@reduxjs/toolkit';

const getAllState = (state: any) => state;

export const getErrors = createSelector([getAllState], (state: any) => {
  return Object.values(state).reduce((stateErrors: any, values: any) => {
    return [...stateErrors, ...values.errors];
  }, []);
});

export const getInfoText = createSelector([getAllState], (state: any) => {
  return Object.values(state).reduce((stateNotifications: any, values: any) => {
    return [...stateNotifications, ...values.notifications];
  }, []);
});
