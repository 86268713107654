import React, { FC, useEffect } from 'react';
import { useAppSelector } from '../hooks/redux';
import RouterComponent from '../routes/IndexRoute';
import { Notify } from '../utils/notifications';
import usePrevious from '../hooks/usePrevious';
import _forEach from 'lodash/forEach';
import { getErrors } from '../store/selectors/getNotificationTypes';
import { IError } from '../models/IError';

const AppContainer: FC = () => {
  const { token, user } = useAppSelector(state => state.userReducer);
  const errors: IError[] | any = useAppSelector(getErrors);

  const previousErrors = usePrevious(errors);
  useEffect(() => {
    if (errors.length !== previousErrors?.length && errors.length > 0) {
      _forEach(errors, error => {
        // if (isIgnoredError(error)) {
        //   return;
        // }

        Notify({
          message: error.text,
          type: 'error',
        });
        // setTimeout(() => {
        //   dispatch(clearUIError());
        // }, 2000);
      });
    }
  }, [errors, previousErrors]);

  return <RouterComponent session={{ token, user }} />;
};

export default AppContainer;
