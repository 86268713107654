import React from 'react'
import { useLocation } from 'react-router-dom';
import yaroslav from '../../assets/images/yaroslav.jpg'
import victoria from '../../assets/images/victoria.jpg'
import { Button } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import DownloadButton from '@mui/icons-material/Download';
import TelegramIcon from '@mui/icons-material/Telegram';

const UserCVProfile = () => {
  const images = [yaroslav, victoria]
  const location = useLocation();
  const queryImage = location.pathname.split('/profile/cv')[1].split('/')[1];
  const imagesStrings = images.map(imageUrl => {
    const parts = imageUrl.split('/');
    const fileNameWithExtension = parts[parts.length - 1];
    return fileNameWithExtension.slice(0, fileNameWithExtension.indexOf('.'));
  })

  const handleDownloadClick = () => {
    const link = document.createElement('a');
    link.href = yaroslav;
    link.download = `${queryImage}.jpg`;
    link.click();
  }

  const hasImg = imagesStrings.includes(queryImage);

  const contactData = {};

  switch (queryImage) {
    case 'yaroslav':
      contactData.phone = '+380730732627';
      contactData.telegram = 'https://t.me/senior_felix/';
      break;
    case 'victoria':
      contactData.phone = '+380931326854';
      contactData.telegram = 'https://t.me/Volf2022/';
      break;
    default:
      break;
  }

  if (hasImg) {
    return (
      <>
        <div className="d-flex align-items-center justify-content-center">
          <img className="profile-pic-responsive" src={images[imagesStrings.indexOf(queryImage)]} alt=""/>
        </div>
        <div className="d-flex align-items-center justify-content-center">

          <section className="call-buton">
            <a className="cc-calto-action-ripple" href={`tel:${contactData.phone}`}>
              <CallIcon style={{ marginLeft: '3px' }}/>
          </a>
          </section>

          <section className="call-buton">
            <div className="cc-calto-action-ripple-download">
              <DownloadButton style={{ marginLeft: '3px' }} onClick={handleDownloadClick}/>
          </div>
          </section>

          <section className="call-buton">
            <a className="cc-calto-action-ripple-teleg" href={`${contactData.telegram}`}>
              <TelegramIcon style={{ marginLeft: '3px' }}/>
            </a>
          </section>
        </div>
        <div className="d-flex justify-content-center">

          <a href="https://t.me/vertical_flat" target="_blank" rel="noreferrer">
            <Button variant="outlined">Переглянути квартири</Button>
          </a>
        </div>
      </>

      )
  }

  return (
    <div>
      Not found
    </div>
  )
}

export default UserCVProfile;
