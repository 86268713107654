import { avatarCustomerStyle } from '../constants/styles/icons';

export const stringToColor = (str: string) => {
  let hash = 0;
  let color = '#';
  let i;
  let value;
  // let strLength;

  if (!str) {
    return `${color}333333`;
  }

  const strLength = str.length;

  // eslint-disable-next-line no-plusplus
  for (i = 0; i < strLength; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // eslint-disable-next-line no-plusplus
  for (i = 0; i < 3; i++) {
    // eslint-disable-next-line no-bitwise
    value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }

  return color;
};

export const stringAvatar = (name: string) => {
  return {
    sx: {
      ...avatarCustomerStyle,
      backgroundColor: `${stringToColor(name)}!important`,
    },
    children:
      name.trim().split(' ').length === 1 ? name[0].toUpperCase() : `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
};
