import React, { FC, memo, useCallback, useEffect } from 'react';
import classes from './Navbar.module.scss';
import { Link, NavLink, useLocation } from 'react-router-dom';
import Logotype from '../../assets/images/logo.svg';
import { Avatar, IconButton } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { capitalizeFullName } from '../../utils/capitalizeTitle';
import { stringAvatar } from '../../utils/stringAvatar';
import { APP_ROUTES } from '../../constants/routes';
import { avatarIconStyle, navbarIconStyle } from '../../constants/styles/icons';
import SVGIcon from '../UI/Icons/IconBody';
import classNames from 'classnames';
import { mappedNavigation } from '../../mappings/mappedNavigation';
import SearchContainer from '../../containers/Navbar/Search/SearchContainer';
import { closeNavbarSearch, toggleNavbarSearch } from '../../store/actions/Home/HomeActions';
import { AddButton } from '../UI/Buttons/AddButtons';
import { SearchIcon } from '../UI/Icons';
import { deactivateUser } from '../../store/actions/User/UserActions';

const Navbar: FC = () => {
  const { user } = useAppSelector(state => state.userReducer);
  const { isSearchActive, currentSearchMode } = useAppSelector(state => state.homeReducer);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const handleToggleNavbarSearch = useCallback((mode: string) => dispatch(toggleNavbarSearch(mode)), [dispatch]);

  useEffect(() => {
    dispatch(closeNavbarSearch());
  }, [location]);

  const renderUserAvatar = useCallback(() => {
    if (!user) {
      return null;
    }

    return (
      <div>
        {user?.avatar ? (
          <IconButton aria-label="avatar" onClick={() => dispatch(deactivateUser())}>
            <Avatar alt={capitalizeFullName(user.name, user.surname)} src={user.avatar} sx={avatarIconStyle} />
          </IconButton>
        ) : (
          <IconButton aria-label="avatar" onClick={() => dispatch(deactivateUser())}>
            <Avatar {...stringAvatar(capitalizeFullName(user.name, user.surname))} sx={avatarIconStyle} />
          </IconButton>
        )}
      </div>
    );
  }, [user]);

  return (
    <div className={classNames(classes.Navbar, 'main-container', 'notPrintable')}>
      <Link to={APP_ROUTES.HOME}>
        <img src={Logotype} alt="Logotype" />
      </Link>
      <nav className={classes.Nav}>
        {mappedNavigation.map(nav => (
          <NavLink key={nav.title} to={nav.to} className={classes.NavLink}>
            {nav.title}
          </NavLink>
        ))}
      </nav>
      <div className={classes.NavbarManage}>
        <div className={classes.NavbarIcon}>
          <AddButton onClick={() => handleToggleNavbarSearch('add')} size="normal" style={navbarIconStyle} />
        </div>
        <div className={classes.NavbarIcon}>
          <IconButton aria-label="search" onClick={() => handleToggleNavbarSearch('search')}>
            <SVGIcon currentIcon={SearchIcon} style={navbarIconStyle} />
          </IconButton>
        </div>
        {renderUserAvatar()}
      </div>
      {isSearchActive ? <SearchContainer searchMode={currentSearchMode} isActive={isSearchActive} /> : null}
    </div>
  );
};

export default memo(Navbar);
