import { COLORS } from '../../colors';

export const FormSliderGeneralStyle = () => ({
  color: `${COLORS.WHITE}!important`,
  marginTop: '10px!important',
  '&.MuiSlider-root': {
    color: `${COLORS.WHITE}!important`,
  },
  '&.MuiSlider-thumb': {
    color: `${COLORS.HOVER}!important`,
    '&.Mui-active': {
      boxShadow: `0px 0px 0px 14px ${COLORS.HOVER_ALPHA}!important`,
    },
  },
  '.Mui-focusVisible': {
    boxShadow: `0px 0px 0px 8px ${COLORS.HOVER_ALPHA}!important`,
    '&.Mui-active': {
      boxShadow: `0px 0px 0px 14px ${COLORS.HOVER_ALPHA}!important`,
    },
  },
  '.MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover': {
    boxShadow: `0px 0px 0px 8px ${COLORS.HOVER_ALPHA}!important`,
    '&.Mui-active': {
      boxShadow: `0px 0px 0px 14px ${COLORS.HOVER_ALPHA}!important`,
    },
  },
});
