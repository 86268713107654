import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteProps } from './RoutesTypes';
import { APP_ROUTES } from '../constants/routes';

// permission, features, decidingFeature

const PrivateRoute: FC<RouteProps> = ({ session, component: Component }) => {
  // const dispatch = useAppDispatch();
  // const location = useLocation();

  if (session) {
    return (
      // <RouterContext.Provider value={{ ordersViewMode }}>
      <Component />
      // </RouterContext.Provider>
    );
  }
  return <Navigate to={APP_ROUTES.AUTH} />;
};

export default PrivateRoute;

// const { uuid } = useParams();
// const [ordersViewMode, setOrdersViewMode] = React.useState(null);
//
// if (uuid) {
//   dispatch(Actions.RouteUUID.setRouteUUID(uuid));
// } else {
//   dispatch(Actions.RouteUUID.setRouteUUID(null));
// }

// const isAllowedByRole = (requiredPermission, userRole) => {
//   /**
//    * Validates route permission bitmask with user role
//    * to check access for route.
//    *
//    * How bitmasks works:
//    * https://abdulapopoola.com/2016/05/30/understanding-bit-masks/
//    *
//    * @param requiredPermission (int): bitmask of required permission
//    * @param userRole (string or null): String of user role name, null if guest
//    * @param hash (string): Hash to sent to sentry (when error occurs)
//    */
//   let userRoleBitmask = ROUTER_PERMISSION.GUEST;
//   if (userRole) {
//     userRoleBitmask = ROLE_TO_PERMISSION[userRole];
//   }
//
//   // eslint-disable-next-line no-bitwise
//   const hasPermissions = (requiredPermission && userRoleBitmask) === userRoleBitmask;
//   if (userRoleBitmask !== ROUTER_PERMISSION.GUEST && !hasPermissions) {
//     Sentry.captureMessage(
//       `User requested route without permissions (route ${location.pathname}).
//       Route bitmask: ${requiredPermission}, user bitmask: ${userRoleBitmask}`,
//     );
//   }
//   return hasPermissions;
// };
//
// const isAllowedByFeatures = (requiredFeatures, userFeatures, decidingFeatures) => {
//   /**
//    *
//    * @param requiredFeatures {}
//    *  an object with route.features config { FEATURE_NAME: bool }
//    * @param userFeatures [] List of user feature objects
//    * @param decidingFeatures an array of feature names
//    * if one of the feature form the decidingFeatures list is true, the routing is allowed
//    * @returns boolean if user is allowed to access route based on enabled features
//    */
//   let requiredFeaturesToObject;
//   if (requiredFeatures) {
//     requiredFeaturesToObject = convertRouteFeaturesToObject(requiredFeatures);
//   }
//   let isAllowed = false;
//   if (decidingFeatures) {
//     _forEach(decidingFeatures, featureName => {
//       isAllowed = isFeatureEnabled(userFeatures, featureName) || isAllowed;
//     });
//   } else {
//     isAllowed = _every(
//       requiredFeaturesToObject,
//       (expectedToBeEnabled, featureName) => isFeatureEnabled(userFeatures, featureName) === expectedToBeEnabled,
//     );
//   }
//
//   if (!isAllowed) {
//     // Prepare user features for error message
//     const featuresEntriesList = decidingFeatures || Object.keys(requiredFeaturesToObject);
//     const featuresEntries = featuresEntriesList.map(featureName => [
//       featureName,
//       isFeatureEnabled(userFeatures, featureName),
//     ]);
//     const userFeaturesToCheck = Object.fromEntries(featuresEntries);
//     const requiredFeatureList = decidingFeatures || requiredFeaturesToObject;
//
//     Sentry.captureMessage(
//       `User requested route without features on/off appropriately (route ${
//         location.pathname
//       }). Required features: ${JSON.stringify(requiredFeatureList)}, user features: ${JSON.stringify(
//         userFeaturesToCheck,
//       )}`,
//     );
//   }
//   return isAllowed;
// };
