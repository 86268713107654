import { FormikErrors, withFormik } from 'formik';
import { AddCustomerFormProps } from '../../types/Forms/FormPropsTypes';
import { AddCustomerFormValues } from '../../types/Forms/FormValuesTypes';
import isValidPhone from '../../utils/isValidPhone';
import isValidEmail from '../../utils/isValidEmail';
import { unMaskPhoneNumber } from '../../utils/unMaskPhoneNumber';
import AddCustomerFormUI from '../../components/Forms/AddCustomerFormUI';

export const AddCustomerForm = withFormik<AddCustomerFormProps, AddCustomerFormValues>({
  mapPropsToValues: props => {
    if (props.isEditMode && props.customer) {
      return {
        name: props.customer.name,
        type: props.customer.type,
        email: props.customer.email,
        phone1: props.customer?.phones ? Number(props.customer.phones[0]) : '',
        phone2: props.customer?.phones ? Number(props.customer.phones[1]) : '',
        phone3: props.customer?.phones ? Number(props.customer.phones[2]) : '',
        comment: props.customer.comment,
      };
    }

    return {
      name: props.initialString || '',
      type: props.initialString || '',
      email: props.initialString || '',
      phone1: Number(unMaskPhoneNumber(props.fillInPhone)) || '',
      phone2: props.initialString || '',
      phone3: props.initialString || '',
      comment: props.initialString || '',
    };
  },

  validate: (values: AddCustomerFormValues, props: any) => {
    const errors: FormikErrors<AddCustomerFormValues> = {};
    if (!values.name) {
      errors.name = 'Обязательное поле';
    } else if (values.name.length > 60) {
      errors.name = 'Допустимо не более 60 символов';
    }
    if (!values.type.length) {
      errors.type = 'Обязательное поле';
    }

    if (values.email && !isValidEmail(values.email.toString().trim())) {
      errors.email = 'Неверный адрес';
    }

    if (!values.phone1) {
      errors.phone1 = 'Обязательное поле';
    } else if (!isValidPhone(values.phone1.toString().trim())) {
      errors.phone1 = 'Неверный адрес';
    }

    if ((props.phones.length === 2 || props.phones.length === 3) && !values.phone2) {
      errors.phone2 = 'Обязательное поле';
    } else if (
      (props.phones.length === 2 || props.phones.length === 3) &&
      !isValidPhone(values?.phone2?.toString().trim())
    ) {
      errors.phone2 = 'Неверный адрес';
    }

    if (props.phones.length === 3 && !values.phone3) {
      errors.phone3 = 'Обязательное поле';
    } else if (props.phones.length === 3 && !isValidPhone(values?.phone3?.toString().trim())) {
      errors.phone3 = 'Неверный адрес';
    }

    if (values.comment && values?.comment.length > 330) {
      errors.comment = 'Допустимо не более 330 символов';
    }

    return errors;
  },
  handleSubmit: async (values, { props }) => {
    props.isEditMode ? props.handleEditCustomer(values) : props.handleAddCustomer(values);
    // do submitting things
  },
})(AddCustomerFormUI);
