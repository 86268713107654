// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/filters-bg.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BACKGROUNDS */
/* COLORS */
/* FONTS */
/* SIZES */
.Wrappers_HeadingWrapper__AWxW7 {
  width: 100%;
  height: 713px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
}
.Wrappers_HeadingWrapperBody__ihsks {
  width: 100%;
  height: 600px;
  background: rgba(43, 60, 70, 0.93);
}

@media only screen and (max-width: 320px) {
  .Wrappers_HeadingWrapper__AWxW7 {
    height: 542px !important;
  }
}
@media only screen and (max-width: 375px) {
  .Wrappers_HeadingWrapper__AWxW7 {
    height: 542px !important;
  }
}
@media only screen and (max-width: 460px) {
  .Wrappers_HeadingWrapper__AWxW7 {
    height: 542px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/_variables.scss","webpack://./src/components/Wrappers/Wrappers.module.scss"],"names":[],"mappings":"AAEA,gBAAA;AAIA,WAAA;AAQA,UAAA;AAEA,UAAA;ACdA;EACE,WAAA;EACA,aAAA;EACA,yDAAA;EACA,4BAAA;EACA,sBAAA;AAIF;AAFE;EACE,WAAA;EACA,aAAA;EACA,kCDRY;ACYhB;;AAAA;EACE;IACE,wBAAA;EAGF;AACF;AAAA;EACE;IACE,wBAAA;EAEF;AACF;AACA;EACE;IACE,wBAAA;EACF;AACF","sourcesContent":["@import './_fonts.scss';\n\n/* BACKGROUNDS */\n\n$main-bg-color: rgba(43, 60, 70, 0.93);\n\n/* COLORS */\n\n$white: #fff;\n$black: #000;\n$main-color: #2B3C46;\n$main-orange-color: #c68a52;\n$main-date-hover-color: #747f84;\n\n/* FONTS */\n\n/* SIZES */\n\n$nav-icon-size: 30px;\n\n$main-font: 'Poppins', sans-serif;\n","@import \"../../assets/styles/variables\";\n\n.HeadingWrapper {\n  width: 100%;\n  height: 713px;\n  background-image: url(\"../../assets/images/filters-bg.jpg\");\n  background-repeat: no-repeat;\n  background-size: cover;\n\n  &Body {\n    width: 100%;\n    height: 600px;\n    background: $main-bg-color;\n  }\n}\n\n@media only screen and (max-width: 320px) {\n  .HeadingWrapper {\n    height: 542px!important;\n  }\n}\n\n@media only screen and (max-width: 375px) {\n  .HeadingWrapper {\n    height: 542px!important;\n  }\n}\n\n@media only screen and (max-width: 460px) {\n  .HeadingWrapper {\n    height: 542px!important;\n  }\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HeadingWrapper": `Wrappers_HeadingWrapper__AWxW7`,
	"HeadingWrapperBody": `Wrappers_HeadingWrapperBody__ihsks`
};
export default ___CSS_LOADER_EXPORT___;
