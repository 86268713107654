import React, { FC, memo } from 'react';
import { FormControl, FormGroup, FormLabel, IconButton, TextField } from '@mui/material';
import DatePicker from 'react-multi-date-picker';
import ClearIcon from '@mui/icons-material/Clear';
import gregorian_ru from '../../../constants/date.locale';
import CheckIcon from '@mui/icons-material/Check';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { FormLabelGeneralStyle, InputLabelPropsStyle } from '../../../constants/styles/Forms/labels';
import { FormControlGeneralStyle, FormInputGeneralStyle } from '../../../constants/styles/Forms/inputs';
import { CustomDateInputProps } from '../../../types/Forms/FormPropsTypes';
import { DATE_PATTERNS } from '../../../constants/rules';
import { AcceptButtonStyle, DeleteButtonStyle } from '../../../constants/styles/Forms/buttons';
import { DateFieldComponentProps } from '../../../types/Flats/Filters/FiltersPropsTypes';

const DateField: FC<DateFieldComponentProps> = ({
  name,
  handleClearDates,
  handleChange,
  values,
  datePickerRef,
  handleCloseCalendar,
  setTriggered,
}) => {
  const CustomDateInput = ({ openCalendar, value }: CustomDateInputProps) => {
    const from = value[0] || '';
    const to = value[1] || '';

    value = from && to ? '' + from + ' - ' + to : from;

    return (
      <TextField
        id="date-custom-input"
        sx={{
          ...FormInputGeneralStyle('6px', '255px', undefined, 'pointer'),
          cursor: 'pointer!important',
        }}
        InputLabelProps={{
          style: {
            ...InputLabelPropsStyle(),
          },
        }}
        autoComplete="off"
        placeholder={DATE_PATTERNS.PLACEHOLDER}
        variant="outlined"
        name={name}
        InputProps={{
          endAdornment: <DateRangeIcon onClick={openCalendar} />,
          style: { cursor: 'pointer' },
        }}
        onFocus={openCalendar}
        value={value}
      />
    );
  };

  return (
    <FormControl sx={FormControlGeneralStyle()} variant="standard">
      <div className="d-flex align-items-center">
        <FormLabel sx={FormLabelGeneralStyle(undefined, '0px')} component="legend">
          Дата
        </FormLabel>
        <FormGroup>
          <DatePicker
            locale={gregorian_ru}
            onOpenPickNewDate
            range
            weekStartDayIndex={1}
            portal
            maxDate={new Date()}
            ref={datePickerRef}
            value={values}
            onChange={handleChange}
            format={DATE_PATTERNS.INPUT_FORMAT}
            onClose={() => setTriggered(true)}
            render={<CustomDateInput />}
          >
            <div className="d-flex justify-content-end">
              <IconButton aria-label="delete" size="medium" onClick={handleClearDates} sx={DeleteButtonStyle}>
                <ClearIcon />
              </IconButton>
              <IconButton aria-label="delete" onClick={handleCloseCalendar} size="medium" sx={AcceptButtonStyle}>
                <CheckIcon />
              </IconButton>
            </div>
          </DatePicker>
        </FormGroup>
      </div>
    </FormControl>
  );
};

export default memo(DateField);
