import * as React from 'react';
import { FC, memo, useCallback } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CheckIcon from '@mui/icons-material/Check';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { Checkbox, FormGroup, FormHelperText, FormLabel, ListSubheader, OutlinedInput } from '@mui/material';
import { helperTextStyle, SelectMultipleFilterItemAllStyle } from '../../../../constants/styles/Forms/selects';
import { FormInputPropsGeneralStyle, FormInputSearchPlaceholder } from '../../../../constants/styles/Forms/inputs';
import { SelectMultipleFilterConstructorProps } from '../../../../types/Forms/FormPropsTypes';
import { COLORS } from '../../../../constants/colors';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

const SelectMultipleField: FC<SelectMultipleFilterConstructorProps> = ({
  list,
  label,
  allOptionTitle,
  isGroup,
  colorGroupedTitles,
  isInline,
  required,
  placeholderLabel = 'Поиск',
  customFormStyle,
  isSearchRequired,
  formControlStyle,
  formLabelStyle,
  formInputStyle,
  formGroupStyle,
  placeholderStyle,
  emPlaceholderColor,
  validation,
  name,
  handleChange,
  selectedValue,
  allSelected,
  visibleList,
  handleSelectAll,
  handleSearch,
  searchTerm,
}) => {
  const highlightError = {
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: validation && validation.error ? `${COLORS.ERROR}!important` : 'inherit',
    },
  };

  const helperText = validation?.helperText;
  if (validation) {
    delete validation.helperText;
  }

  const makeGroupedItems = useCallback(
    (data: any) => {
      const items = [];
      for (const [i, dataList] of data.entries()) {
        items.push(
          <ListSubheader key={dataList.id}>
            {colorGroupedTitles ? (
              <span style={{ color: colorGroupedTitles[i] }}>{dataList.title}</span>
            ) : (
              <span>{dataList.title}</span>
            )}
          </ListSubheader>,
        );
        for (const item of dataList.data) {
          items.push(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <MenuItem
              key={item.id}
              value={item.title}
              name={item.title}
              disabled={allSelected && name === 'subwayStations'}
            >
              {selectedValue.includes(item.title) ? <CheckIcon sx={{ marginRight: '5px' }} /> : null}
              {item.title}
            </MenuItem>,
          );
        }
      }
      return items;
    },
    [allSelected, colorGroupedTitles, selectedValue],
  );

  const makeListItems = useCallback(
    (data: any) =>
      data.map((value: any) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <MenuItem
          key={value.id}
          value={value.title}
          name={value.title}
          disabled={allSelected && name === 'subwayStations'}
        >
          {selectedValue.includes(value.title) ? <CheckIcon sx={{ marginRight: '5px' }} /> : null}
          {value.title}
        </MenuItem>
      )),
    [allSelected, selectedValue],
  );

  const renderAllOptionTitleCondition = () => {
    if (allOptionTitle) {
      if (isSearchRequired && (!visibleList.length || visibleList.length < 2)) {
        return null;
      }

      return (
        <div className="text-center">
          <MenuItem
            value={allOptionTitle}
            onClick={() => handleSelectAll(!allSelected)}
            sx={SelectMultipleFilterItemAllStyle}
          >
            <Checkbox sx={{ pointerEvents: 'none' }} checked={allSelected} />
            <em>Выбрать все</em>
          </MenuItem>
        </div>
      );
    }

    return null;
  };

  return (
    <FormControl sx={formControlStyle}>
      <div className={isInline ? 'd-flex align-items-center' : ''}>
        <FormLabel sx={formLabelStyle} component="legend">
          {required ? (
            <p>
              {label}
              <span className="requiredLabel">*</span>
            </p>
          ) : (
            label
          )}
        </FormLabel>
        <FormGroup sx={formGroupStyle}>
          <Select
            labelId="select-multiple-filter-constructor-label"
            id="select-multiple-filter-constructor-id"
            multiple
            autoWidth
            sx={{
              ...formInputStyle,
              position: 'relative!important',
              ...(customFormStyle && { ...customFormStyle }),
              ...highlightError,
            }}
            value={selectedValue}
            onChange={e => handleChange(e, name)}
            inputProps={{
              style: { ...FormInputPropsGeneralStyle() },
            }}
            displayEmpty
            renderValue={selected => {
              if (selected.length === 0) {
                return (
                  <em style={{ color: emPlaceholderColor ? emPlaceholderColor : COLORS.INPUT_OUTLINE }}>
                    {placeholderLabel}
                  </em>
                );
              }

              return selected.join(', ');
            }}
            MenuProps={MenuProps}
            {...validation}
          >
            {isGroup && isSearchRequired ? (
              <div className="text-center">
                <MenuItem onKeyDown={e => {
                  e.stopPropagation();
                }} disabled={allSelected}>
                  <OutlinedInput
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onChange={handleSearch}
                    value={searchTerm}
                    sx={placeholderStyle ? placeholderStyle : FormInputSearchPlaceholder}
                    placeholder="Поиск"
                  />
                </MenuItem>
              </div>
            ) : null}
            {renderAllOptionTitleCondition()}
            {isSearchRequired && !visibleList.length ? (
              <div className="d-flex align-items-center justify-content-center text-center mt30 mb30">
                Ничего не найдено <SentimentVeryDissatisfiedIcon sx={{ marginLeft: '5px' }} />
              </div>
            ) : null}
            {isGroup ? makeGroupedItems(isSearchRequired ? visibleList : list) : makeListItems(list)}
          </Select>
          <FormHelperText sx={helperTextStyle}>{helperText || ''}</FormHelperText>
        </FormGroup>
      </div>
    </FormControl>
  );
};

export default memo(SelectMultipleField);
