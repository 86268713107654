import * as React from 'react';
import { FC, memo } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import classes from './AddCustomer.module.scss';
import { AddCustomerForm } from '../../../Forms/Customers/AddCustomerForm';
import { AddCustomerComponentProps } from '../../../types/Customers/CustomersPropsTypes';

const AddCustomer: FC<AddCustomerComponentProps> = ({
  opened,
  handleClose,
  isEditMode,
  customer,
  fillInPhone,
  phones,
  handleAddCustomer,
  handleEditCustomer,
  setPhones,
  phonesData,
  handleGetContactData,
}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={opened}
      className={classes.AddCustomer}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={opened}>
        <Box className={classes.AddCustomerContent}>
          <div className="d-flex align-items-center justify-content-between mb30">
            <h2 className={classes.AddCustomerTitle}>{isEditMode ? 'Редактирование клиента' : 'Добавление клиента'}</h2>

            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <IconButton onClick={handleClose}>
              <CloseIcon sx={{ fontSize: '33px' }} />
            </IconButton>
          </div>
          <div>
            <AddCustomerForm
              handleAddCustomer={handleAddCustomer}
              handleEditCustomer={handleEditCustomer}
              phones={phones}
              setPhones={setPhones}
              isEditMode={isEditMode}
              customer={customer}
              fillInPhone={fillInPhone}
              handleGetContactData={handleGetContactData}
              phonesData={phonesData}
            />
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default memo(AddCustomer);
