import { yellow } from '@mui/material/colors';

export const FormCheckbox = {
  color: '#DFDFDF!important',
  borderRadius: '15%!important',
  '&.Mui-checked': {
    color: 'rgba(198, 138, 82, 1)!important',
  },
};

export const FormCheckboxHoverYellow = {
  color: yellow[800],
  '&.Mui-checked': {
    color: yellow[600],
  },
};
