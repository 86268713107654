import React from 'react';
import { SVGIconProps } from '../../../../../types/UI/Icons/SVGIconsTypes';

export const WebLinkIcon: React.FC<SVGIconProps> = ({ width = 24, height = 24, clazz }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clazz}
  >
    <circle cx="12" cy="12" r="9" stroke="#C68A52" strokeWidth="2" />
    <path d="M3 12H21" stroke="#C68A52" strokeWidth="2" />
    <path d="M12 21C12 21 16 17 16 12C16 7 12 3 12 3" stroke="#C68A52" strokeWidth="2" />
    <path d="M12 21C12 21 8 17 8 12C8 7 12 3 12 3" stroke="#C68A52" strokeWidth="2" />
  </svg>
);
