import React, { FC, Suspense } from 'react';
// // import Loading from 'rapidfab/components/Loading';
import { useLocation } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { AppRoutesProps } from './RoutesTypes';
import ErrorBoundary from '../components/UI/ErrorBoundary/ErrorBoundary';

const RouterComponent: FC<AppRoutesProps> = ({ session }) => {
  const location = useLocation();
  return (
    <Suspense fallback={<div>Loading</div>}>
      <ErrorBoundary routeHash={location.pathname}>
        <AppRoutes session={session} />
      </ErrorBoundary>
    </Suspense>
  );
};

export default RouterComponent;
