import { COLORS } from '../../colors';

export const DeleteButtonStyle = {
  transition: '.3s',
  '&:hover': {
    backgroundColor: `${COLORS.HOVER_DELETE_BUTTON}!important`,
  },
};

export const AcceptButtonStyle = {
  transition: '.3s',
  '&:hover': {
    backgroundColor: `${COLORS.HOVER_ACCEPT_BUTTON}!important`,
  },
};
