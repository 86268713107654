import { styled, Tooltip as MUITooltip, tooltipClasses, TooltipProps } from '@mui/material';
import React from 'react';

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MUITooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    padding: '10px 30px',
    top: '25px',
    right: '4px',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));
