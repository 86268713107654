import React, { FC, useCallback } from 'react';
import IconsBox from '../../../components/UI/Icons/IconsBox';
import { IconBoxState, IconsBoxContainerProps } from '../../../types/UI/Icons/SVGIconsTypes';

const IconsBoxContainer: FC<IconsBoxContainerProps> = ({ icons: Icons, fill, isInverse, iconsState, handleChange }) => {
  const [state, setState] = React.useState<IconBoxState>(
    Icons.reduce((obj, icon) => {
      const iconName = icon.name.replace('Icon', '').toLowerCase();
      return Object.assign(obj, { [iconName]: Object.values(iconsState).length ? iconsState[iconName] : false });
    }, {}),
  );

  const handleChangeIconsState = useCallback(
    (icon: string) => {
      const payload = {
        ...state,
        [icon]: !state[icon],
      };
      setState(payload);
      handleChange(payload);
    },
    [handleChange, state],
  );

  return (
    <IconsBox
      icons={Icons}
      fill={fill}
      isInverse={isInverse}
      iconsState={state}
      handleChange={handleChangeIconsState}
    />
  );
};

export default IconsBoxContainer;
