import React, { FC, memo, useCallback } from 'react';
import classes from './Flat.module.scss';
import Carousel from 'nuka-carousel';
import ImageViewer from 'react-simple-image-viewer';
import ImageNotFound from '../../../assets/images/not_found.png';
import SVGIcon from '../../UI/Icons/IconBody';
import ActionsMenu from '../../UI/ActionsMenu';
import { FLATS_ICONS, FLATS_STATUSES } from '../../../mappings/Flats/flatsStatuses';
import { Link } from 'react-router-dom';
import getRouteURI from '../../../utils/getRouteURI';
import { APP_ROUTES } from '../../../constants/routes';
import maskedPhoneNumber from '../../../utils/maskPhoneNumber';
import { capitalizeFullName, maskCustomerName } from '../../../utils/capitalizeTitle';
import { Button, Rating } from '@mui/material';
import { COLORS } from '../../../constants/colors';
import dayjs from 'dayjs';
import { transformImageURL } from '../../../constants/api';
import ConfirmationModal from '../../UI/Modals/ConfirmationModal';
import map from 'lodash/map';
import { FlatViewComponentProps } from '../../../types/Flats/FlatsPropsTypes';
import { Tooltip } from '../../UI/Tooltips/Tooltip';
import {
  DateIcon,
  FavoritesIcon,
  FlatIcon,
  FloorsIcon,
  GpsIcon,
  LocationIcon,
  OwnerIcon,
  RoomsIcon,
  SquaresIcon,
  StatusIcon,
  SubwayStationsIcon,
  UserIcon,
  ViewsIcon,
  WebLinkIcon,
} from '../../UI/Icons';
import classNames from 'classnames';

const Flat: FC<FlatViewComponentProps> = ({
  flat,
  isDeleteModal,
  setIsDeleteModal,
  handleDeleteFlat,
  fetching,
  flatActions,
  photoIndex,
  handleOpenFullWidth,
  isOpenFullWidth,
  setIsOpenFullWidth,
  handleGetContactData,
  webLinkData,
  contactDetailsData
}) => {
  const {
    files,
    title,
    adTitle,
    addedBy,
    clientType,
    createdAt,
    notes,
    rooms,
    floors,
    square,
    street,
    district,
    residence,
    timeToSubway,
    special,
    adText,
    status,
    subwayStations,
    propertyType,
    owner,
    price,
    webLink,
    views,
    rating,
    exclusive,
  } = flat;

  const handleCloseDeleteModal = useCallback(
    () =>
      setIsDeleteModal({
        isOped: false,
        uuid: null,
        vfId: null,
      }),
    [setIsDeleteModal],
  );


  const renderWebLink = () => {
    if (!webLinkData && webLink) {
      return (
        <div className="d-flex align-items-center">
          <SVGIcon currentIcon={WebLinkIcon} width={24} height={24} />
          <Button size="small" type="submit" onClick={() => handleGetContactData('webLink')}>Показать ссылку</Button>
        </div>
      )
    }

    if (webLinkData && webLink) {
      return (
        <div className={classes.FlatGeneralInfoWebLink}>
          <SVGIcon currentIcon={WebLinkIcon} width={24} height={24} /> <a href={webLinkData}>{webLinkData}</a>
        </div>
      )
    }

    return null;
  }

  const renderOwnerPhone = () => {
    if (!contactDetailsData) {
      return <Button size="small" type="submit" onClick={() => handleGetContactData('phone')}>Показать телефон</Button>;
    }

    return (
      <div className={classes.FlatContactsOwnerInfo}>{maskedPhoneNumber(contactDetailsData[0])}</div>
    )
  }

  return (
    <div className="main-container">
      <div className={classes.Flat}>
        <div className={classes.FlatSlider}>
          {files?.length ? (
            <div>
              <Carousel slideIndex={photoIndex} renderCenterLeftControls={null} renderCenterRightControls={null}>
                {transformImageURL(files).map((photo: string, index: number) => {
                  return (
                    <img
                      key={photo}
                      alt="Flat Slider"
                      onClick={() => handleOpenFullWidth(index)}
                      style={{
                        height: '478px',
                        borderRadius: '5px',
                        objectFit: 'cover',
                        width: '100%',
                      }}
                      src={photo}
                    />
                  );
                })}
              </Carousel>

              {isOpenFullWidth && (
                <ImageViewer
                  src={transformImageURL(files)}
                  currentIndex={photoIndex}
                  disableScroll={false}
                  closeOnClickOutside={true}
                  onClose={() => setIsOpenFullWidth(false)}
                />
              )}
            </div>
          ) : (
            <img
              alt="Image Not Found"
              style={{
                height: '478px',
                borderRadius: '5px',
                objectFit: 'cover',
                width: '100%',
              }}
              src={ImageNotFound}
            />
          )}
        </div>
        <div className={classes.FlatInfo}>
          <div className={classes.FlatInfoHeader}>
            <div className={classes.FlatInfoHeaderContent}>
              <p className={classes.FlatInfoHeaderContentTitle}>{title}</p>
              <p className={classes.FlatInfoHeaderContentPrice}>{price} грн</p>
            </div>

            <div className={classes.FlatInfoHeaderRest}>
              <div className="d-flex justify-content-between">
                <div className={classes.FlatInfoHeaderActions}>
                  <SVGIcon currentIcon={FavoritesIcon} width={30} height={30} fill="none" />
                  <ActionsMenu options={flatActions} />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.FlatGeneralInfoStats}>
            <div className={classes.FlatGeneralInfoStatsItem}>
              <SVGIcon currentIcon={RoomsIcon} width={30} height={30} />
              {rooms}
            </div>
            <div className={classes.FlatGeneralInfoStatsItem}>
              <SVGIcon currentIcon={FloorsIcon} width={19} height={30} /> {floors.total} / {floors.current}
            </div>
            <div className={classes.FlatGeneralInfoStatsItem}>
              <SVGIcon currentIcon={SquaresIcon} width={28} height={28} />
              <p>{square} м²</p>
            </div>
            <div>
            <div className={classes.FlatGeneralInfoStatsItem} style={{ marginLeft: '22px' }}>
              {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
              {Object.entries(special!).map(([key, value]) => {
                const Icon = FLATS_ICONS[key];

                if (!Icon || !value) {
                  return null;
                }

                return (
                  <SVGIcon
                    key={key}
                    width={25}
                    height={25}
                    currentIcon={Icon}
                    style={{ marginRight: '25.78px' }}
                    fill="#159900"
                  />
                );
              })}
            </div>
            </div>
          </div>
          <div className={classes.FlatGeneralInfoStreet}>
            <SVGIcon currentIcon={LocationIcon} width={20.5} height={24} />
            <p>
              {street}, {district} район{residence ? `, ЖК ${residence}` : null}
            </p>
          </div>
          {subwayStations?.length ? (
            <div className={classes.FlatGeneralInfoSubway}>
              <SVGIcon currentIcon={SubwayStationsIcon} width={24} height={20} />
              <p>
                {map(subwayStations, subway => {
                  return `м. ${subway}`;
                })
                  .join(', ')
                  .replace(',', ', ')}
              </p>
            </div>
          ) : null}
          {timeToSubway ? (
            <div className={classes.FlatGeneralInfoSubway}>
              <>
                <SVGIcon currentIcon={GpsIcon} width={24} height={24} />{' '}
                <p>До метро в пешей доступности до {timeToSubway} мин</p>
              </>
            </div>
          ) : null}
          {renderWebLink()}
          {notes ? (
            <div className={classes.FlatGeneralInfoNote}>
              <p>{notes}</p>
            </div>
          ) : null}
          <div className="d-flex align-items-center flatContacts" style={{ marginTop: '25px' }}>
            <div className={classes.FlatContacts}>
              <div className={classes.FlatContactsOwner}>
                <SVGIcon currentIcon={OwnerIcon} width={40} height={40} style={{ marginRight: '10px' }} />
                <div className="d-flex align-items-start flex-direction-column mt5">
                  <div className="d-flex">
                    <Tooltip
                      title={maskCustomerName(owner?.name)}
                      arrow
                      placement="top"
                      sx={{ padding: '15px', marginLeft: '4px!important' }}
                    >
                      <Link
                        className={classes.FlatContactsOwnerInfo}
                        to={getRouteURI(APP_ROUTES.CUSTOMER_EDIT, { uuid: owner?.uuid })}
                      >
                        {maskCustomerName(owner?.name)}
                      </Link>
                    </Tooltip>
                    {renderOwnerPhone()}
                  </div>
                  <p className={classes.FlatContactsOwnerType}>{clientType}</p>
                </div>
              </div>

              <div className={classes.FlatContactsUser}>
                <SVGIcon currentIcon={UserIcon} width={40} height={40} style={{ marginRight: '10px' }} />
                <div>
                  <div className="d-flex align-items-center">
                    <p style={{ marginRight: '10px' }}>Опубликовано:</p>
                    <div className={classes.FlatContactsUserInfo}>
                      {capitalizeFullName(addedBy?.name, addedBy?.surname)}
                    </div>
                  </div>
                  {addedBy?.team ? <div className={classes.FlatContactsUserInfoTeam}>“{addedBy.team}”</div> : null}
                </div>
              </div>
            </div>
            <div className={classes.FlatAdditionalInfo}>
              <div className="d-flex align-items-center">
                <div className={classes.FlatAdditionalInfoLine} />
                <div>
                  {exclusive ? (
                    <div className={classes.FlatAdditionalInfoStatItem}>
                      <SVGIcon currentIcon={StatusIcon} width={30} height={30} fill="#DA5A00" />
                      <p>Эксклюзив</p>
                    </div>
                  ) : null}
                  <div className={classes.FlatAdditionalInfoStatItem}>
                    <SVGIcon currentIcon={ViewsIcon} width={30} height={30} /> {views}
                  </div>
                  <div className={classes.FlatAdditionalInfoStatItem}>
                    <Rating
                      name="simple-controlled"
                      readOnly
                      value={Number(rating)}
                      precision={0.5}
                      sx={{ fontSize: '25px' }}
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center stats22">
                <div className={classNames(classes.FlatAdditionalInfoLine, 'line2')} />
                <div>
                  <div className={classes.FlatAdditionalInfoStatItem}>
                    <SVGIcon currentIcon={StatusIcon} width={30} height={30} />
                    <p>{FLATS_STATUSES[status?.state?.toUpperCase()]}</p>
                  </div>
                  <div className={classes.FlatAdditionalInfoStatItem}>
                    <SVGIcon currentIcon={FlatIcon} width={30} height={30} />
                    <p>{propertyType}</p>
                  </div>
                  <div className={classes.FlatAdditionalInfoStatItem}>
                    <SVGIcon currentIcon={DateIcon} width={30} height={30} stroke={COLORS.MAIN} />{' '}
                    {dayjs(createdAt).format('DD.MM.YYYY HH:mm')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.FlatAdvertisement}>
        <p>Рекламный текст</p>
        <div className={classes.FlatAdvertisementText}>
          <p>{adTitle}</p>
          <p>{adText.split('\\n').join('\n')}</p>
        </div>
      </div>
      {isDeleteModal.isOpen && (
        <ConfirmationModal
          title="Подтверждение удаления квартиры"
          message={`Вы уверены, что хотите удалить квартиру VF-${isDeleteModal.vfId}?`}
          onCancel={handleCloseDeleteModal}
          onConfirm={() => handleDeleteFlat(isDeleteModal.uuid)}
          loading={fetching}
          buttonConfirmText="Удалить"
          buttonCancelText="Отмена"
        />
      )}
    </div>
  );
};

export default memo(Flat);
