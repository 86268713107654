import { FormControlGeneralStyle, FormInputGeneralStyle } from '../../../constants/styles/Forms/inputs';
import { FormLabelGeneralStyle } from '../../../constants/styles/Forms/labels';
import React, { FC, useCallback, useEffect, useState } from 'react';
import StreetField from '../../../components/Flats/Filters/StreetField';
import { StreetFieldContainerProps } from '../../../types/Flats/Filters/FiltersPropsTypes';
import { LANG } from '../../../constants/strings';
import { usePlacesWidget } from 'react-google-autocomplete';
import { API_SECRET } from '../../../.secret';

const StreetFieldContainer: FC<StreetFieldContainerProps> = ({
  formControlStyle = FormControlGeneralStyle(),
  formLabelStyle = FormLabelGeneralStyle(),
  formInputStyle = FormInputGeneralStyle('6px', '300px', 1),
  formGroupStyle,
  isInline,
  svgColor = '#fff',
  label = 'Улица',
  required,
  street,
  handleChange,
}) => {
  const [country] = useState(LANG.UA);
  const [value, setValue] = useState<string>(street || '');

  useEffect(() => {
    if (!value) {
      handleChange('');
    }
  }, [handleChange, value]);

  const move = useCallback((array: any, oldIndex: number, newIndex: number) => {
    if (newIndex >= array.length) {
      newIndex = array.length - 1;
    }
    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
    return array;
  }, []);

  const { ref: streetFieldRef } = usePlacesWidget({
    apiKey: API_SECRET.GOOGLE_STREET_API_KEY,
    onPlaceSelected: place => {
      const arr = place?.address_components!.filter(item => {
        if (
          ((item.types.includes('street_number') || item.types.includes('route')) && item.types.length === 1) ||
          item.types.every((current, index) => current === ['locality', 'political'][index])
        ) {
          return item;
        }
      });

      const currentStreet =
        arr.length === 3
          ? move(
              arr.map(item => item.long_name),
              0,
              1,
            ).join(', ')
          : arr.map(item => item.long_name).join(', ');

      setValue(currentStreet);
      handleChange(currentStreet);
    },
    inputAutocompleteValue: 'off',
    options: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      bounds: {
        north: 30.552838,
        south: 50.417809,
        east: 30.553462,
        west: 50.422299,
      },
      // strictBounds: true,
      componentRestrictions: { country },
      types: ['route'],
      // fields: ['place_id'],
    },
    language: 'ru',
  });

  const handleChangeStreet = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  return (
    <StreetField
      formControlStyle={formControlStyle}
      formLabelStyle={formLabelStyle}
      formInputStyle={formInputStyle}
      formGroupStyle={formGroupStyle}
      isInline={isInline}
      required={required}
      label={label}
      svgColor={svgColor}
      streetFieldRef={streetFieldRef}
      handleChange={handleChangeStreet}
      value={value}
    />
  );
};

export default StreetFieldContainer;
