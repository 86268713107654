import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import AddNewFlat from '../../../components/Flats/new/AddNewFlat';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getCurrentCustomer } from '../../../store/actions/Customer/CustomerThunk';
import { addFlat, editFlat, getFlat, getResourceContactData } from '../../../store/actions/Flats/FlatThunk';
import {
  clearUIOptionsAddFlat,
  setFlatOption,
  setFlatWebLink,
  setSpecialFields
} from '../../../store/actions/Flats/FlatActions';
import { STATUSES } from '../../../constants/statuses';
import { APP_ROUTES } from '../../../constants/routes';
import { IFile } from '../../../models/IFile';
import { AddNewFlatFormValues, EditFlatInterface } from '../../../types/Forms/FormValuesTypes';
import { Notify } from '../../../utils/notifications';
import getRouteURI from '../../../utils/getRouteURI';
import { checkIfPayloadSame } from '../../../utils/checkIfPayloadSame';
import { PROPERTY_TYPES } from '../../../mappings/Forms/checkboxes';
import { PropertyState } from '../../../types/Forms/FormStateTypes';
import isURL from '../../../utils/isURL';

const AddNewFlatContainer: FC = () => {
  const { customer } = useAppSelector(state => state.customerReducer);
  const {
    street,
    propertyType,
    special,
    isEditMode: editMode,
    flatPreview,
  } = useAppSelector(state => state.flatReducer);

  const { uuid } = useParams();
  const navigate = useNavigate();
  const location: any = useLocation();
  // TODO: Think on route comparison as redux state is clearing
  const isRouteAddingMode = !!location.pathname.includes('addFlat');
  const isEditMode = !!location.pathname.includes('editFlat') || editMode;
  const dispatch = useAppDispatch();

  const [files, setFiles] = useState<IFile[] | any>([]);
  const [propertyState, setPropertyState] = useState<PropertyState>({
    flat: !isEditMode,
    room: false,
    house: false,
    commercial: false,
  });

  const [webLinkData, setWebLinkData] = useState<any>(null);

  const handleGetContactData = async (resourceKey: string) => {
    const { payload } = await dispatch(getResourceContactData({ resourceKey, uuid }));

    if (payload.status === STATUSES.SUCCESS && payload?.contactData) {
      if (resourceKey === 'webLink') {
        dispatch(setFlatWebLink(payload.contactData));
        return setWebLinkData(payload.contactData);
      }
    } else {
      Notify({ message: 'Ошибка получения данных', type: 'error' });
    }
  }

  const handleGetCustomer = useCallback(
    async (customerUUID: string | undefined) => {
      if (!customerUUID) {
        return null;
      }
      await dispatch(getCurrentCustomer(customerUUID));
      return true;
    },
    [dispatch],
  );

  const handleAddNewFlat = useCallback(
    async (values: AddNewFlatFormValues) => {
      if (!street) {
        return Notify({ message: 'Необходимо выбрать улицу', type: 'error' });
      }

      if (!customer) {
        return Notify({ message: 'Необходимо выбрать клиента', type: 'error' });
      }

      const objToSend = new FormData();

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      objToSend.append('price', Number(values.price));
      objToSend.append('clientType', customer.type);
      objToSend.append('propertyType', propertyType);
      objToSend.append('street', street);
      objToSend.append('rooms', values.rooms as any);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      objToSend.append('square', Number(values.square));
      objToSend.append('floors', JSON.stringify(values.floors));
      objToSend.append('adText', values.adText);
      objToSend.append('adTitle', values.adTitle);
      objToSend.append('special', JSON.stringify(special));
      objToSend.append('customerUUID', customer.uuid);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      objToSend.append('exclusive', values.exclusive);
      objToSend.append('residence', values.residence || '');
      objToSend.append('notes', values.notes || '');
      objToSend.append('rating', values.rating || '');

      if (values.subwayStations && values.subwayStations.length) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        objToSend.append('subwayStations', values.subwayStations);
      }

      if (values.district) {
        objToSend.append('district', values.district);
      }

      if (values.timeToSubway) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        objToSend.append('timeToSubway', Number(values.timeToSubway));
      }

      if (Object.keys(files).length) {
        files.forEach((file: any) => {
          objToSend.append('file[]', file);
        });
      }

      const { payload } = await dispatch(addFlat(objToSend as any));

      if (payload.status === STATUSES.SUCCESS) {
        Notify({
          message: payload.notification.text,
          type: 'success',
        });

        return navigate(getRouteURI(APP_ROUTES.FLAT_VIEW, { uuid: payload.flat.uuid }));
      } else {
        return Notify({
          message: payload.text,
          type: 'error',
        });
      }
    },
    [street, customer, propertyType, special, files, dispatch, navigate],
  );

  const handleEditFlat = useCallback(
    async (values: AddNewFlatFormValues) => {
      if (!street) {
        return Notify({ message: 'Необходимо выбрать улицу', type: 'error' });
      }

      if (!customer) {
        return Notify({ message: 'Необходимо выбрать клиента', type: 'error' });
      }

      if (values.webLink && !isURL(values.webLink)) {
        delete values.webLink;
      }

      const payloadToSend = {
        flat: {
          ...values,
          street,
          clientType: customer.type,
          propertyType,
          files,
          special,
          customer: customer,
        },
        uuid: flatPreview?.uuid,
      };

      const payloadSameCheck = {
        ...values,
        street,
        clientType: customer.type,
        propertyType,
        special,
      };

      if (checkIfPayloadSame(payloadSameCheck, flatPreview)) {
        return Notify({
          message: 'Пожалуйста, измените что-нибудь перед редактированием',
          type: 'error',
        });
      }

      const { payload } = await dispatch(editFlat(payloadToSend as EditFlatInterface));

      if (payload.status === STATUSES.SUCCESS) {
        Notify({
          message: payload.notification.text,
          type: 'success',
        });

        dispatch(setFlatOption({ type: 'isEditMode', value: false }));
        return navigate(getRouteURI(APP_ROUTES.FLAT_VIEW, { uuid: payload.flat.uuid }));
      }
    },
    [customer, dispatch, files, flatPreview, navigate, propertyType, special, street],
  );

  const onInitialize = useCallback(async () => {
    if (isEditMode && uuid) {
      const responsePayload = await dispatch(getFlat(uuid));
      const { payload } = responsePayload;
      if (payload.status === STATUSES.SUCCESS) {
        await handleGetCustomer(payload?.flat?.owner?.uuid);
      }

      return false;
    }

    if (isRouteAddingMode && uuid) {
      await handleGetCustomer(uuid);
    }

    return false;
  }, [dispatch, handleGetCustomer, isEditMode, isRouteAddingMode, uuid]);

  // TODO: propertyState loop occurs when we change propertyType
  const initPropertyType = useCallback(() => {
    if (isEditMode) {
      const result: any = {};
      Object.keys(propertyState).forEach(key => {
        if (PROPERTY_TYPES[key] === flatPreview?.propertyType) {
          return (result[key] = true);
        }

        return (result[key] = false);
      });
      setPropertyState(result);
      return;
    }
    return dispatch(
      setFlatOption({
        type: 'propertyType',
        value: Object.keys(propertyState)
          .filter(key => propertyState[key])
          .map(key => PROPERTY_TYPES[key])
          .join(' '),
      }),
    );
  }, [dispatch, flatPreview?.propertyType, isEditMode]);

  useEffect(() => {
    initPropertyType();
  }, [initPropertyType]);

  const handleChangePropertyType = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // It is single select method

      if (propertyState && propertyState[event.target.name]) {
        return;
      }

      const newState = JSON.parse(JSON.stringify(propertyState));
      Object.keys(newState).forEach(key => (newState[key] = false));
      const result = {
        ...newState,
        [event.target.name]: true,
      };
      setPropertyState(result);
      dispatch(
        setFlatOption({
          type: 'propertyType',
          value: Object.keys(result)
            .filter(key => result[key])
            .map(key => PROPERTY_TYPES[key])
            .join(' '),
        }),
      );
    },
    [dispatch, propertyState],
  );

  const handleChangeStreet = useCallback(
    (value: string) => {
      return dispatch(setFlatOption({ type: 'street', value }));
    },
    [dispatch],
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const handleChangeSpecialField = useCallback(values => dispatch(setSpecialFields(values)), [dispatch]);

  useEffect(() => {
    if (isEditMode && flatPreview) {
      dispatch(setFlatOption({ type: 'street', value: flatPreview.street }));
    }
  }, [dispatch, flatPreview, isEditMode]);

  useEffect(() => {
    onInitialize();
    return () => {
      dispatch(clearUIOptionsAddFlat());
    };
  }, [dispatch, onInitialize]);

  const memoizedFiles = useMemo(() => [...files], [files]);

  if (!customer) {
    return null;
  }

  return (
    <AddNewFlat
      customer={customer}
      isEditMode={isEditMode}
      handleAddNewFlat={handleAddNewFlat}
      handleEditFlat={handleEditFlat}
      files={memoizedFiles}
      setFiles={setFiles}
      flatPreview={flatPreview}
      propertyTypeState={propertyState}
      handleChangePropertyType={handleChangePropertyType}
      handleChangeStreet={handleChangeStreet}
      handleChangeSpecialField={handleChangeSpecialField}
      handleGetContactData={handleGetContactData}
      webLinkData={webLinkData}
    />
  );
};

export default AddNewFlatContainer;
