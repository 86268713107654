import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { DateFieldContainerProps } from '../../../types/Flats/Filters/FiltersPropsTypes';
import DateFilter from '../../../components/Flats/Filters/DateField';

const DateFieldContainer: FC<DateFieldContainerProps> = ({ name, values, handleChange }) => {
  const [localValues, setLocalValues] = useState(values);
  const [triggered, setTriggered] = useState(false);
  const datePickerRef: any = useRef();

  const handleClearDates = (): void => setLocalValues([]);
  const handleCloseCalendar = (): void => datePickerRef?.current?.closeCalendar();

  const handleChangeDate = useCallback((): void => {
    if (triggered) {
      handleChange(localValues, name);
      setTriggered(false);
    }
  }, [handleChange, localValues, name, triggered]);

  useEffect(() => {
    handleChangeDate();
  }, [handleChangeDate]);

  return (
    <DateFilter
      name={name}
      handleClearDates={handleClearDates}
      handleCloseCalendar={handleCloseCalendar}
      datePickerRef={datePickerRef}
      values={localValues}
      handleChange={setLocalValues}
      setTriggered={setTriggered}
    />
  );
};

export default DateFieldContainer;
