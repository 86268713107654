import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { authorize, currentSessionCheck, verify } from '../../../services/UserService';
import { AuthFormValues, AuthFormVerificationValues } from '../../../types/Authorization/AuthorizationValues';

export const auth = createAsyncThunk('user/auth', async (values: AuthFormValues, { rejectWithValue }) => {
  try {
    const { data } = await authorize(values);
    return data;
  } catch (e) {
    const error = e as AxiosError;
    return rejectWithValue(error.response?.data || { status: 'failure', text: 'Что-то пошло не так' });
  }
});

export const verifyLogin = createAsyncThunk(
  'user/verifyLogin',
  async (values: AuthFormVerificationValues, { rejectWithValue }) => {
    try {
      const { data } = await verify(values);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.response?.data || { status: 'failure', text: 'Что-то пошло не так' });
    }
  },
);

export const checkSession = createAsyncThunk('user/checkSession', async (_, { rejectWithValue }) => {
  try {
    const { data } = await currentSessionCheck();
    return data;
  } catch (e) {
    const error = e as AxiosError;
    return rejectWithValue(error.response?.data || { status: 'failure', text: 'Что-то пошло не так' });
  }
});
