import React from 'react';
import { SVGIconProps } from '../../../../../types/UI/Icons/SVGIconsTypes';

export const FavoritesIcon: React.FC<SVGIconProps> = ({ width = 30, height = 30, clazz, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 30 30"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    className={clazz}
  >
    <path
      d="M5.47355 15.3429C4.40609 14.2448 3.75 12.7536 3.75 11.1111C3.75 7.73604 6.52024 5 9.9375 5C12.0302 5 13.8802 7.27605 15 8.84658C16.1198 7.27605 17.9698 5 20.0625 5C23.4798 5 26.25 7.73604 26.25 11.1111C26.25 12.8342 25.5279 14.3907 24.3664 15.5017L15 25L5.47355 15.3429Z"
      stroke="#8F2B2B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
