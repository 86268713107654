import { COLORS } from './colors';

// State	Global class name
// active	.Mui-active
// checked	.Mui-checked
// completed	.Mui-completed
// disabled	.Mui-disabled
// error	.Mui-error
// expanded	.Mui-expanded
// focus visible	.Mui-focusVisible
// focused	.Mui-focused
// required	.Mui-required
// selected	.Mui-selected

/* AUTH FORM */

export const AuthFormStyles = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  margin: '0 70px',
};

export const AuthFormButtonStyles = {
  background: COLORS.PRIMARY,
};

export const AuthFormInputStyles = {
  marginBottom: '1rem',
};
