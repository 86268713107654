/* * FormLabelStyle - style for all form labels <FormControl>*/

import { FONTS } from '../fonts';

export const FormLabelGeneralStyle = (fontSize = '18px', mb = '10px') => ({
  fontFamily: FONTS.GENERAL,
  fontSize: fontSize ? `${fontSize}!important` : '18px',
  color: '#fff!important',
  marginBottom: `${mb}!important`,
  borderRadius: '15%!important',
  '.MuiFormLabel-root': {
    color: '#fff!important',
  },
  marginRight: '15px',
  whiteSpace: 'nowrap!important',
});

export const FormLabelModalStyle = (fontSize = '18px', mb = '10px') => ({
  fontFamily: FONTS.GENERAL,
  lineHeight: '32px',
  fontSize: fontSize ? `${fontSize}!important` : '18px!important',
  fontWeight: '600!important',
  color: 'rgba(43, 60, 70, 1)!important',
  marginBottom: `${mb}!important`,
  borderRadius: '15%!important',
  '.MuiFormLabel-root': {
    color: 'rgba(43, 60, 70, 1)!important',
  },
  '> p': {
    fontSize: '18px!important',
    fontWeight: '600!important',
  },
  marginRight: '15px',
  whiteSpace: 'nowrap!important',
});

export const FormLabelConstructorStyle = (fontSize = '18px', mb = '10px', mr?: string, color?: string) => ({
  fontFamily: FONTS.GENERAL,
  lineHeight: '32px',
  fontSize: fontSize ? `${fontSize}!important` : '18px!important',
  fontWeight: '600!important',
  color: color ? `${color}!important` : 'rgba(43, 60, 70, 1)!important',
  marginBottom: `${mb}!important`,
  borderRadius: '15%!important',
  '.MuiFormLabel-root': {
    color: color ? `${color}!important` : 'rgba(43, 60, 70, 1)!important',
  },
  '> p': {
    fontSize: '18px!important',
    fontWeight: '600!important',
  },
  marginRight: mr ? `${mr}!important` : '15px',
  whiteSpace: 'nowrap!important',
});

export const InputLabelPropsStyle = (
  height = '35px',
  fontSize = '14px',
  left?: string | undefined,
  top?: string | undefined,
) => ({
  height,
  fontSize,
  left: left || '',
  top: top || '',
});
