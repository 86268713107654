import React, { FC, useEffect, useLayoutEffect } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import AppContainer from './containers/AppContainer';
import './assets/styles/index.scss';
import 'animate.css/animate.min.css';
import { Toaster } from 'react-hot-toast';
import { useAppDispatch, useAppSelector } from './hooks/redux';
import { logoutUser } from './store/actions/User/UserActions';
import { checkSession } from './store/actions/User/UserThunk';

const Wrapper: FC<any> = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const App: FC = () => {
  const dispatch = useAppDispatch();
  const { expiresIn } = useAppSelector(state => state.userReducer);

  useEffect(() => {
    if (localStorage.getItem('verticalAccessToken')) {
      dispatch(checkSession());
    }
  }, [sessionStorage]);

  useEffect(() => {
    if (expiresIn) {
      dispatch(logoutUser(expiresIn));
    }
  }, [expiresIn]);

  return (
    <BrowserRouter>
      <Wrapper>
        <Toaster position="top-right" reverseOrder={false} />
        <AppContainer />
      </Wrapper>
    </BrowserRouter>
  );
};

export default App;
