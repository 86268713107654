// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Auth_AuthForm__0u330 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 523px;
  padding: 5rem 0;
  background: #fff;
  border-radius: 2%;
  box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0.2509803922);
  transform: translate(-50%, -50%);
}
.Auth_AuthFormHeader__QyzoA {
  margin-bottom: 1rem;
  text-align: center;
}
.Auth_AuthFormHeader__QyzoA > div:first-child {
  font-weight: 100;
  font-size: 20px;
  margin-bottom: 10px;
}
.Auth_AuthFormHeader__QyzoA > div:last-child {
  font-weight: 400;
  font-size: 25px;
}`, "",{"version":3,"sources":["webpack://./src/components/Authorization/Auth/Auth.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,qDAAA;EACA,gCAAA;AACF;AACE;EACE,mBAAA;EACA,kBAAA;AACJ;AACI;EACE,gBAAA;EACA,eAAA;EACA,mBAAA;AACN;AAEI;EACE,gBAAA;EACA,eAAA;AAAN","sourcesContent":[".AuthForm {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  width: 100%;\n  max-width: 523px;\n  padding: 5rem 0;\n  background: #fff;\n  border-radius: 2%;\n  box-shadow: 0 0 20px 20px #00000040;\n  transform: translate(-50%, -50%);\n\n  &Header {\n    margin-bottom: 1rem;\n    text-align: center;\n\n    & > div:first-child {\n      font-weight: 100;\n      font-size: 20px;\n      margin-bottom: 10px;\n    }\n\n    & > div:last-child {\n      font-weight: 400;\n      font-size: 25px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AuthForm": `Auth_AuthForm__0u330`,
	"AuthFormHeader": `Auth_AuthFormHeader__QyzoA`
};
export default ___CSS_LOADER_EXPORT___;
