import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import NotFoundImage from '../assets/images/404.jpg';

const NotFound: FC = () => {
  return (
    <div className="text-center main404">
      <h1 className="header404">Страница не найдена</h1>
      <p className="paragraph404">Неправильно набран адрес или такой страницы на сайте больше не существует.</p>
      <img className="img404" src={NotFoundImage} alt="404 Not Found" />
      <div>
        <Link className="btn404" to="/">
          На главную
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
