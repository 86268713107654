import React from 'react';
import { ErrorBoundaryProps, PrevProps, State } from './ErrorBoundaryTypes';
import CrashPage from '../../CrashPage';

class ErrorBoundary extends React.Component<ErrorBoundaryProps, State> {
  public constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  public componentDidUpdate(prevProps: PrevProps) {
    const { routeHash } = this.props;
    if (routeHash !== prevProps.routeHash) {
      // If state is changed, we need to remove this error message
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ hasError: false });
    }
  }

  public componentDidCatch() {
    this.setState({ hasError: true });
  }

  public render() {
    if (this.state.hasError) {
      return <CrashPage />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
