export default function isURL(uri: string) {
  if (!uri) {
    return false;
  }
  let url;
  try {
    url = new URL(uri);
  } catch {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
}
