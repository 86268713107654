import React, { memo } from 'react';
import { Form, FormikProps } from 'formik';
import { Button, TextField } from '@mui/material';
import { AuthFormProps, FormInput, MainFormValues } from '../../types/Forms/FormPropsTypes';

const AuthFormUI = (props: AuthFormProps & FormikProps<MainFormValues>) => {
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    submitTitle,
    inputs,
    fullWidth,
    margin = 'normal',
    formStyle,
    variant = 'contained',
    inputStyle = {},
    buttonStyle = {},
  } = props;

  return (
    <Form style={formStyle}>
      <div style={inputStyle}>
        {inputs.map((input: FormInput, index: number) => {
          return (
            <TextField
              fullWidth={fullWidth}
              key={`${input.id}-${index}`}
              id={input.id}
              name={input.name}
              label={input.label}
              margin={margin}
              type={input.type}
              value={values[input.name as keyof MainFormValues]}
              onBlur={handleBlur}
              onChange={handleChange}
              error={touched[input.name as keyof MainFormValues] && Boolean(errors[input.name as keyof MainFormValues])}
              helperText={touched[input.name as keyof MainFormValues] && errors[input.name as keyof MainFormValues]}
            />
          );
        })}
      </div>
      <Button type="submit" variant={variant} disabled={isSubmitting} fullWidth={fullWidth} sx={buttonStyle}>
        {submitTitle}
      </Button>
    </Form>
  );
};

export default memo(AuthFormUI);
