import { IUserAuth, IUserLogin, User } from '../../models/IUser';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { auth, checkSession, verifyLogin } from '../actions/User/UserThunk';
import { API } from '../../constants/api';
import { IError } from '../../models/IError';
import { IInfoNotification } from '../../models/IInfoNotification';

interface UserState {
  user: User;
  token: string | null;
  expiresIn: string | null;
  loading: boolean;
  errors: Array<IError>;
  notification: IInfoNotification | undefined;
  isVerified: boolean;
}

const handleAPIBaseRequest = (reducer: any) => ({
  [reducer.pending.type]: (state: { loading: boolean; errors: never[] }) => {
    state.loading = true;
    state.errors = [];
  },
  [reducer.fulfilled.type]: (state: { loading: boolean }) => {
    state.loading = false;
  },
  [reducer.rejected.type]: (state: { loading: boolean; errors: any[] }, action: PayloadAction<IError>) => {
    state.loading = false;
    state.errors = [...state.errors, action.payload];
  },
});

const initialState: UserState = {
  user: null,
  token: null,
  expiresIn: null,
  loading: false,
  errors: [],
  notification: undefined,
  isVerified: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUIErrors: state => {
      state.errors = [];
    },
    userLogin: (state, action: PayloadAction<IUserAuth>) => {
      const { user, token, expiresIn } = action.payload;
      localStorage.setItem('verticalAccessToken', token);
      localStorage.setItem(API.EXPIRES, String(expiresIn));
      localStorage.setItem(API.USER, JSON.stringify(user));
      state.user = user;
      state.token = token;
      state.expiresIn = expiresIn;
    },
    userDeactivation: state => {
      state.user = null;
      state.token = null;
      state.expiresIn = null;
      localStorage.removeItem('verticalAccessToken');
      localStorage.removeItem(API.EXPIRES);
      localStorage.removeItem(API.USER);
    },
    autoUserSetup: state => {
      if (!localStorage.getItem('verticalAccessToken')) {
        state.token = null;
        state.expiresIn = null;
        state.user = null;
        localStorage.removeItem(API.EXPIRES);
        localStorage.removeItem(API.USER);
      } else {
        state.token = localStorage.getItem('verticalAccessToken');
        state.expiresIn = String(localStorage.getItem(API.EXPIRES));
        state.user = JSON.parse(localStorage.getItem(API.USER) || '{}');
      }
    },
  },
  extraReducers: {
    [auth.pending.type]: state => {
      state.loading = true;
      state.errors = [];
    },
    [auth.fulfilled.type]: (state, action: PayloadAction<IUserLogin>) => {
      // const { user, text, status } = action.payload;
      state.loading = false;
      state.user = action.payload.user;
      // state.error = status === 'failure' ? true : null;
      state.notification = action.payload.notification;
    },
    [auth.rejected.type]: (state, action: PayloadAction<IError>) => {
      state.loading = false;
      state.errors = [...state.errors, action.payload];
    },
    [verifyLogin.pending.type]: state => {
      state.loading = true;
      state.errors = [];
    },
    [verifyLogin.fulfilled.type]: (state, action: PayloadAction<IUserLogin>) => {
      // const { user, text, status } = action.payload;
      state.loading = false;
      // state.error = status === 'failure' ? true : null;
      state.notification = action.payload.notification;
    },
    [verifyLogin.rejected.type]: (state, action: PayloadAction<IError>) => {
      state.loading = false;
      state.errors = [...state.errors, action.payload];
    },
    [checkSession.pending.type]: state => {
      state.loading = true;
      state.errors = [];
    },
    [checkSession.fulfilled.type]: state => {
      state.loading = false;
    },
    [checkSession.rejected.type]: (state, action: PayloadAction<IError>) => {
      state.loading = true;
      state.errors = [...state.errors, action.payload];
      state.token = null;
      state.expiresIn = null;
      state.user = null;
      localStorage.removeItem('verticalAccessToken');
      localStorage.removeItem(API.EXPIRES);
      localStorage.removeItem(API.USER);
    },
  },
});

export default userSlice.reducer;
