import React from 'react';
import { SVGIconProps } from '../../../../../../types/UI/Icons/SVGIconsTypes';

export const PhoneIcon: React.FC<SVGIconProps> = ({ width = 16, height = 16, stroke = '#C68A52' }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.122 9.77024L10.1435 10.7487C8.9665 10.1995 7.95527 9.53155 7.20822 8.79177C6.09223 7.68666 5.49549 6.49142 5.25128 5.85648L6.22978 4.87797C6.49997 4.60778 6.49997 4.16971 6.22978 3.89952L3.78365 1.45338C3.51346 1.18319 3.0725 1.17937 2.812 1.45892C1.32082 3.05914 -0.511677 5.96413 4.7621 11.2379C10.0359 16.5117 12.9409 14.6792 14.5411 13.188C14.8206 12.9275 14.8168 12.4866 14.5466 12.2164L12.1005 9.77024C11.8303 9.50005 11.3922 9.50005 11.122 9.77024Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
