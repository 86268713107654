import React, { FC, memo } from 'react';
import Preview from './Preview';
import SVGIcon from '../Icons/IconBody';
import { AttachFileIcon } from '../Icons/Flats/AttachFileIcon';
import classes from './Attachments.module.scss';
import { AttachmentsComponentProps } from '../../../types/UI/Attachments/AttachmentsPropsTypes';
import { ATTACHMENTS_RULES } from '../../../constants/attachments';

const Attachments: FC<AttachmentsComponentProps> = ({
  files,
  setFiles,
  isEditMode,
  flatPreview,
  getRootProps,
  getInputProps,
  handleOnDragEndFlatPreview,
  handleDeleteFile,
  previewFiles,
}) => {
  return (
    <section className={classes.Attachments}>
      <div {...getRootProps({ className: ATTACHMENTS_RULES.className })}>
        <input {...getInputProps()} />
        <div className={classes.AttachmentsContainer}>
          <SVGIcon width={40} height={40} currentIcon={AttachFileIcon} style={{ margin: '18px' }} />
          <p>Перетащите ваши файлы сюда</p>
        </div>
      </div>
      <Preview
        files={files}
        isEditMode={isEditMode}
        flatPreview={flatPreview}
        setFiles={setFiles}
        handleOnDragEndFlatPreview={handleOnDragEndFlatPreview}
        handleDeleteFile={handleDeleteFile}
        previewFiles={previewFiles}
      />
    </section>
  );
};

export default memo(Attachments);
