import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import classes from './Search.module.scss';
import SVGIcon from '../../UI/Icons/IconBody';
import AddCustomerContainer from '../../../containers/Customers/Customer/Modals/AddCustomerContainer';
import { SearchResultNotFoundProps, SearchResultProps } from '../../../types/UI/Navbar/Search/SearchPropsTypes';
import CustomerCard from '../../../containers/Customers/Customer/CustomerCardContainer';
import { SubmitButtonMain } from '../../../constants/styles/Buttons/SubmitButtons';
import { CircularProgress } from '@mui/material';
import { useAppSelector } from '../../../hooks/redux';
import { InfoIcon } from '../../UI/Icons';

const SearchResultNotFound: FC<SearchResultNotFoundProps> = ({ handleAddCustomer, errors }) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHasError(errors.length > 0);
  }, [errors]);

  return (
    <div className={classes.SearchNotFound}>
      <div className="d-flex align-items-center" style={{ marginBottom: '75px' }}>
        <SVGIcon currentIcon={InfoIcon} width={23} height={23} />{' '}
        <p style={{ color: '#000' }}>{hasError ? 'Что-то пошло не так' : 'Нет клиента в базе'}</p>
      </div>
      {hasError ? null : (
        <SubmitButtonMain
          variant="contained"
          startIcon={<AddIcon sx={{ width: '23px', height: '23px' }} />}
          onClick={handleAddCustomer}
          sx={{
            width: '80%',
          }}
        >
          Добавить
        </SubmitButtonMain>
      )}
    </div>
  );
};

const SearchResult: FC<SearchResultProps> = ({ phone, customer, fetching }) => {
  const [addCustomerActive, setAddCustomerActive] = useState(false);
  const { errors } = useAppSelector(state => state.userReducer);

  const handleAddCustomer = useCallback(() => setAddCustomerActive(true), []);
  const handleCloseCustomerModal = useCallback(() => setAddCustomerActive(false), []);

  if (fetching) {
    return (
      <div className={classes.SearchLoading} onClick={e => e.stopPropagation()}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={customer ? classes.SearchCustomerResult : classes.SearchResult} onClick={e => e.stopPropagation()}>
      {customer ? (
        <CustomerCard customer={customer} />
      ) : (
        <SearchResultNotFound handleAddCustomer={handleAddCustomer} errors={errors} />
      )}
      {addCustomerActive ? (
        <AddCustomerContainer opened={addCustomerActive} handleClose={handleCloseCustomerModal} fillInPhone={phone} />
      ) : null}
    </div>
  );
};

export default memo(SearchResult);
