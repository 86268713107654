import React, { FC } from 'react';
import { CheckboxLabelContainerProps } from '../../../../types/UI/Fields/FieldsPropsTypes';
import CheckboxLabelField from '../../../../components/UI/Fields/Checkboxes/CheckboxLabelField';

const CheckboxLabelFieldContainer: FC<CheckboxLabelContainerProps> = ({
  label,
  checked,
  name,
  formLabelStyle,
  validation,
  handleChange,
}) => {
  if (validation) {
    delete validation.helperText;
  }

  return (
    <CheckboxLabelField
      formLabelStyle={formLabelStyle}
      label={label}
      handleChange={handleChange}
      name={name}
      checked={checked}
      validation={validation}
    />
  );
};

export default CheckboxLabelFieldContainer;
