import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import classes from './Flats.module.scss';
import dayjs from 'dayjs';
import SVGIcon from '../UI/Icons/IconBody';
import { COLORS } from '../../constants/colors';
import { Button, Pagination, Rating } from '@mui/material';
import Carousel from 'nuka-carousel';
import maskedPhoneNumber from '../../utils/maskPhoneNumber';
import { FLATS_ICONS, FLATS_STATUSES } from '../../mappings/Flats/flatsStatuses';
import ImageViewer from 'react-simple-image-viewer';
import ImageNotFound from '../../assets/images/not_found.png';
import { capitalizeFullName } from '../../utils/capitalizeTitle';
import getRouteURI from '../../utils/getRouteURI';
import { APP_ROUTES } from '../../constants/routes';
import { Link, useNavigate } from 'react-router-dom';
import { API, API_URL, transformImageURL } from '../../constants/api';
import { shortenFullName } from '../../utils/shortenFullName';
import { GeneralPaginationStyle } from '../../constants/styles/pagination';
import ActionsMenu from '../UI/ActionsMenu';
import { IActionMenu } from '../../models/IActionMenu';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { setFlatOption } from '../../store/actions/Flats/FlatActions';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useAppDispatch } from '../../hooks/redux';
import MainLoader from '../UI/Loaders/MainLoader';
import { useFreezeWhileLoading } from '../../hooks/useFreezeWhileLoading';
import ConfirmationModal from '../UI/Modals/ConfirmationModal';
import classNames from 'classnames';
import { FlatsComponentProps } from '../../types/Flats/FlatsPropsTypes';
import { DATE_PATTERNS } from '../../constants/rules';
import { Tooltip } from '../UI/Tooltips/Tooltip';
import {
  DateIcon,
  FavoritesIcon,
  FlatIcon,
  FloorsIcon,
  GpsIcon,
  LocationIcon,
  OwnerIcon,
  RoomsIcon,
  SquaresIcon,
  StatusIcon,
  UserIcon,
  ViewsIcon,
} from '../UI/Icons';

// eslint-disable-next-line func-style
export async function downloadFlatPDF(uuid: string, vfId: string | null) {
  const response = await fetch(`${API_URL}/flat/flat-pdf/${uuid}`);
  const blob = await response.blob();
  const downloadUrl = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = downloadUrl;
  a.download = `VF-${vfId}.pdf`;
  document.body.appendChild(a);
  a.click();
  a.remove();
}

const Flats: FC<FlatsComponentProps> = ({
  flats,
  handleChangePage,
  pagesLimit,
  fetching,
  page,
  handleDeleteFlat,
  isDeleteModal,
  setIsDeleteModal,
  user,
  handleGetContactData,
  phonesData,
  selectedFlat,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState({} as any);
  const [currentFlatPreview, setCurrentFlatPreview] = useState({} as any);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useFreezeWhileLoading(fetching);

  const handleOpenFullWidth = useCallback(
    (flatTitle: string, index: number) => {
      setPhotoIndex({ [flatTitle]: index });
      const currentFlat = flats.find(flat => flat.title.trim() === flatTitle.trim()) || null;
      setCurrentFlatPreview(currentFlat);
      setIsOpen(true);
    },
    [flats],
  );

  const handleCloseDeleteModal = useCallback(
    () =>
      setIsDeleteModal({
        isOped: false,
        uuid: null,
        vfId: null,
      }),
    [setIsDeleteModal],
  );

  const sortedFlatsByDate = useMemo(
    () =>
      [...flats].sort((a, b) => {
        return dayjs(b.createdAt).diff(dayjs(a.createdAt));
      }),
    [flats],
  );

  if (!flats.length) {
    return <p className={classes.FlatsNotFound}>Квартиры не найдены</p>;
  }
  if (fetching && !flats.length) {
    return <MainLoader />;
  }

  const renderPhoneNumber = (uuid: string) => {
    if (selectedFlat === uuid && phonesData) {
      return (
        <div className={classes.FlatsItemContactsOwnerInfo}>{maskedPhoneNumber(phonesData[0])}</div>
      )
    }

    return (
      <Button size="small" onClick={() => handleGetContactData('phone', uuid)}>Показать телефон</Button>
    );
  }

  return (
    <div className={classes.Flats}>
      {sortedFlatsByDate.map(
        ({
          price,
          owner,
          addedBy,
          createdAt,
          notes,
          rooms,
          floors,
          square,
          street,
          district,
          residence,
          timeToSubway,
          special,
          title,
          files,
          views,
          rating,
          status,
          uuid,
          vfId,
          exclusive,
          propertyType,
        }) => {
          const FlatActions: IActionMenu[] =
            user?.role === 'tester'
              ? [
                  {
                    label: 'Скачать',
                    disableRipple: true,
                    icon: DownloadOutlinedIcon,
                    disabled: !files?.length,
                    onClick: async () => {
                      const currentFlat = sortedFlatsByDate.find(flat => flat.uuid === uuid);
                      handleGetContactData('download', currentFlat!.uuid as string)
                      dispatch(setFlatOption({ type: 'flatPreview', value: currentFlat }));
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      await downloadFlatPDF(uuid, currentFlat?.vfId);
                      return null;
                      // return dispatch(setFlatOption({ type: 'isPrintingFlat', value: true }));
                    },
                  },
                ]
              : [
                  {
                    label: 'Скачать',
                    disableRipple: true,
                    icon: DownloadOutlinedIcon,
                    disabled: !files?.length,
                    onClick: async () => {
                      const currentFlat = sortedFlatsByDate.find(flat => flat.uuid === uuid);
                      handleGetContactData('download', currentFlat!.uuid as string)
                      dispatch(setFlatOption({ type: 'flatPreview', value: currentFlat }));
                      // await downloadFlatPDF(uuid);
                      // return null;
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      await downloadFlatPDF(uuid, currentFlat?.vfId);
                      return null;
                      // return dispatch(setFlatOption({ type: 'isPrintingFlat', value: true }));
                    },
                  },
                  {
                    label: 'Редактировать',
                    disableRipple: true,
                    icon: EditIcon,
                    disabled: false,
                    onClick: () => {
                      const currentFlat = sortedFlatsByDate.find(
                        flat => flat.uuid.toString().trim() === uuid.toString().trim(),
                      );
                      dispatch(setFlatOption({ type: 'flatPreview', value: currentFlat }));
                      dispatch(setFlatOption({ type: 'isEditMode', value: true }));
                      return navigate(getRouteURI(APP_ROUTES.FLAT_EDIT,
                        { uuid: currentFlat?.uuid }), { state: { editMode: true } });
                    },
                  },
                  {
                    label: 'Дублировать',
                    disableRipple: true,
                    icon: ContentCopyIcon,
                    disabled: true,
                    // onClick: () => handleEditMode(),
                  },
                  {
                    label: '',
                    disableRipple: undefined,
                    divider: true,
                  },
                  {
                    label: 'Закрыть',
                    disableRipple: true,
                    icon: DoDisturbOnOutlinedIcon,
                    disabled: true,
                  },
                  {
                    label: 'Архивировать',
                    disableRipple: true,
                    icon: ArchiveIcon,
                    disabled: true,
                  },
                  {
                    label: 'Удалить',
                    disableRipple: true,
                    icon: DeleteOutlineIcon,
                    disabled: false,
                    onClick: () => setIsDeleteModal({ isOpen: true, uuid, vfId }),
                  },
                ];

          return (
            <div className={classNames(classes.FlatsItem, exclusive && classes.FlatsItemExclusive)} key={title}>
              {files && files.length ? (
                <div className={classes.FlatsItemSlider}>
                  <Carousel
                    slideIndex={photoIndex[title]}
                    renderCenterLeftControls={null}
                    renderCenterRightControls={null}
                  >
                    {transformImageURL(files).map((photo: string, index: number) => {
                      return (
                        <img
                          key={photo}
                          alt="Flat Slider"
                          onClick={() => handleOpenFullWidth(title, index)}
                          style={{
                            height: '300px',
                            borderRadius: '0px 0px 0px 5px',
                            objectFit: 'cover',
                            width: '100%',
                          }}
                          src={photo}
                        />
                      );
                    })}
                  </Carousel>
                </div>
              ) : (
                <img
                  alt="Image Not Found"
                  style={{
                    height: '300px',
                    borderRadius: '0px 0px 0px 5px',
                    objectFit: 'cover',
                    width: '300px',
                  }}
                  src={ImageNotFound}
                />
              )}

              <div className={classes.FlatsItemGeneralInfo}>
                <div className={classes.FlatsItemGeneralInfoTitle}>
                  <Link to={getRouteURI(APP_ROUTES.FLAT_VIEW, { uuid })}>{title}</Link>
                  <p>{price} грн</p>
                </div>
                <div className={classes.FlatsItemGeneralInfoStats}>
                  <div className={classes.FlatsItemGeneralInfoStatsItem}>
                    <SVGIcon currentIcon={RoomsIcon} width={30} height={30} />
                    {rooms}
                  </div>
                  <div className={classes.FlatsItemGeneralInfoStatsItem}>
                    <SVGIcon currentIcon={FloorsIcon} width={19} height={30} /> {floors.total} / {floors.current}
                  </div>
                  <div className={classes.FlatsItemGeneralInfoStatsItem}>
                    <SVGIcon currentIcon={SquaresIcon} width={28} height={28} />
                    <p>{square} м²</p>
                  </div>
                  <div
                    className={classes.FlatsItemGeneralInfoStatsItem}
                    style={{ marginLeft: Object.values(special!).filter(Boolean).length < 6 ? '22px' : '0' }}
                  >
                    {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                    {Object.entries(special!).map(([key, value]) => {
                      const Icon = FLATS_ICONS[key];

                      if (!Icon || !value) {
                        return null;
                      }

                      return <SVGIcon key={key} width={25} height={25} currentIcon={Icon} fill="#159900" />;
                    })}
                  </div>
                </div>
                <div className={classes.FlatsItemGeneralInfoStreet}>
                  <SVGIcon currentIcon={LocationIcon} width={20.5} height={24} />
                  <Tooltip
                    title={`${street}, ${district} район${residence ? `, ЖК ${residence}` : ''}`}
                    arrow
                    placement="top"
                    sx={{ padding: '15px', marginLeft: '4px!important' }}
                  >
                    <p>
                      {street}, {district} район{residence ? `, ЖК ${residence}` : null}
                    </p>
                  </Tooltip>
                </div>
                <div className={classes.FlatsItemGeneralInfoSubway}>
                  {timeToSubway ? (
                    <>
                      <SVGIcon currentIcon={GpsIcon} width={24} height={24} />{' '}
                      <p>До метро в пешей доступности до {timeToSubway} мин</p>
                    </>
                  ) : null}
                </div>
                {notes ? (
                  <div className={classes.FlatsItemGeneralInfoNote}>
                    <p>{notes.slice(0, 170)}...</p>
                  </div>
                ) : null}
              </div>
              <div className={classNames(classes.FlatsItemContacts, exclusive && classes.FlatsItemContactsExclusive)}>
                <div className={classes.FlatsItemContactsOwner}>
                  <SVGIcon currentIcon={OwnerIcon} width={40} height={40} />
                  <Tooltip
                    title={owner?.name}
                    arrow
                    placement="top"
                    sx={{ padding: '15px', marginLeft: '4px!important' }}
                  >
                    <div>
                      <Link
                        className={classes.FlatsItemContactsOwnerInfo}
                        to={getRouteURI(APP_ROUTES.CUSTOMER_EDIT, { uuid: owner?.uuid })}
                      >
                        {shortenFullName(owner?.name)}
                      </Link>
                    </div>
                  </Tooltip>

                  {renderPhoneNumber(uuid)}
                </div>
                <div className={classes.FlatsItemContactsUser}>
                  <SVGIcon currentIcon={UserIcon} width={40} height={40} />
                  <p>Опубликовано:</p>
                  <div className={classes.FlatsItemContactsUserInfo}>
                    {capitalizeFullName(addedBy?.name, addedBy?.surname)}
                  </div>
                  {addedBy?.team ? <div className={classes.FlatsItemContactsUserInfo}>“{addedBy.team}”</div> : null}
                </div>
              </div>
              <div className={classes.FlatsItemAdditionalInfo}>
                <div className="d-flex align-items-center justify-content-between">
                  <div className={classes.FlatsItemAdditionalInfoStatItem}>
                    <SVGIcon currentIcon={ViewsIcon} width={30} height={30} /> {views}
                  </div>
                  <div className="d-flex align-items-center">
                    <div className={classes.FlatsItemAdditionalInfoStatItem}>
                      <SVGIcon
                        currentIcon={FavoritesIcon}
                        width={30}
                        height={30}
                        fill="none"
                        style={{ marginRight: '0' }}
                      />
                    </div>
                    <ActionsMenu options={FlatActions} />
                  </div>
                </div>
                <div className={classes.FlatsItemAdditionalInfoStatItem}>
                  <Rating
                    name="simple-controlled"
                    readOnly
                    value={Number(rating)}
                    precision={0.5}
                    sx={{ fontSize: '25px' }}
                  />
                </div>
                <div className={classes.FlatsItemAdditionalInfoStatItem} style={{ alignItems: 'flex-start' }}>
                  <SVGIcon currentIcon={FlatIcon} width={30} height={30} />
                  <p>{propertyType}</p>
                </div>
                <div
                  className={classes.FlatsItemAdditionalInfoStatItem}
                  style={{ marginBottom: exclusive ? '10px' : '70px' }}
                >
                  <SVGIcon currentIcon={StatusIcon} width={30} height={30} />
                  <p>{FLATS_STATUSES[status?.state?.toUpperCase()]}</p>
                </div>
                {exclusive ? (
                  <div className={classes.FlatsItemAdditionalInfoStatItem} style={{ marginBottom: '30px' }}>
                    <SVGIcon currentIcon={StatusIcon} width={30} height={30} fill="#DA5A00" />
                    <p>Эксклюзив</p>
                  </div>
                ) : null}
                <div className={classes.FlatsItemAdditionalInfoStatItem}>
                  <SVGIcon currentIcon={DateIcon} width={30} height={30} stroke={COLORS.MAIN} />{' '}
                  {dayjs(createdAt).format(DATE_PATTERNS.UI_FORMAT)}
                </div>
              </div>
            </div>
          );
        },
      )}
      {isOpen && currentFlatPreview && (
        <ImageViewer
          src={transformImageURL(currentFlatPreview.files)}
          currentIndex={photoIndex[currentFlatPreview.title]}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={() => setIsOpen(false)}
        />
      )}
      <div className="d-flex justify-content-center">
        <Pagination
          count={pagesLimit}
          getItemAriaLabel={index => `${index + 1}`}
          size="large"
          page={page}
          shape="rounded"
          onChange={handleChangePage}
          sx={GeneralPaginationStyle}
          color="primary"
        />
      </div>
      {isDeleteModal.isOpen && (
        <ConfirmationModal
          title="Подтверждение удаления квартиры"
          message={`Вы уверены, что хотите удалить квартиру VF-${isDeleteModal.vfId}?`}
          onCancel={handleCloseDeleteModal}
          onConfirm={() => handleDeleteFlat(isDeleteModal.uuid)}
          loading={fetching}
          buttonConfirmText="Удалить"
          buttonCancelText="Отмена"
        />
      )}
    </div>
  );
};

export default memo(Flats);
