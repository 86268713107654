export const shortenFullName = (name: string) => {
  if (!name) {
    return '';
  }
  if (name.trim().split(' ').length > 1) {
    const firstName = name.split(' ')[0];
    const lastName = name.split(' ')[1];
    return `${firstName[0].toUpperCase()}${firstName.slice(1)} ${lastName[0].toUpperCase()}.`;
  } else {
    return name[0].toUpperCase() + name.slice(1, 13);
  }
};
