import React, { FC } from 'react';
import SVGIcon from '../../components/UI/Icons/IconBody';
import { MaskedEmailProps } from '../../types/Utils/UtilsProps';
import { INPUT_PATTERNS } from '../../constants/rules';
import { Tooltip } from '../../components/UI/Tooltips/Tooltip';
import { EmailIcon } from '../../components/UI/Icons';

export const MaskedEmail: FC<MaskedEmailProps> = ({ email }) => {
  if (!email) {
    return null;
  }

  const emailTitle = email.split('@');
  const restrictedLength = emailTitle[0].length > INPUT_PATTERNS.EMAIL_LENGTH_TO_SHOW;

  return (
    <>
      <SVGIcon width={13.5} height={9} currentIcon={EmailIcon} style={{ marginRight: '7.25px' }} />
      <Tooltip title={email} arrow placement="top" sx={{ padding: '10px' }}>
        <span
          style={
            restrictedLength
              ? {
                  maxWidth: '90px',
                  display: 'inline-block',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }
              : {}
          }
        >
          {emailTitle[0].slice(0, INPUT_PATTERNS.EMAIL_LENGTH_TO_SHOW)}
        </span>
      </Tooltip>
    </>
  );
};

export default MaskedEmail;
