import React, { FC } from 'react';
import classes from './Auth.module.scss';
import { AuthFormVerification } from '../../../Forms/Auth/AuthVerificationForm';

import { AuthFormButtonStyles, AuthFormInputStyles, AuthFormStyles } from '../../../constants/styles';
import { AuthFormVerificationInput } from '../../../mappings/Forms/inputs';
import { AuthVerificationContainerProps } from '../../../types/Authorization/AuthorizationPropsTypes';

const AuthVerification: FC<AuthVerificationContainerProps> = ({ handleVerifyLogin }) => {
  return (
    <div className={classes.AuthForm}>
      <div className={classes.AuthFormHeader}>
        <div>Введите код подтверждения</div>
      </div>
      <div>
        <AuthFormVerification
          submitTitle="Подтвердить"
          inputs={AuthFormVerificationInput}
          fullWidth
          formStyle={AuthFormStyles}
          buttonStyle={AuthFormButtonStyles}
          inputStyle={AuthFormInputStyles}
          handleVerifyLogin={handleVerifyLogin}
        />
      </div>
    </div>
  );
};

export default AuthVerification;
