import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../models/IError';
import { PayloadSetNewOption } from './FlatSlice';
import { IInfoNotification } from '../../models/IInfoNotification';
import { AllOptionsSelected } from '../../types/Flats/Filters/FiltersPropsTypes';
import { PropertyState } from '../../types/Forms/FormStateTypes';

interface flatFiltersState {
  subwayDistrictToggle: string;
  streetResidenceToggle: string;
  allOptionsSelected: AllOptionsSelected;
  propertyTypeState: PropertyState;
  errors: Array<IError>;
  notification: IInfoNotification | undefined;
  filtersPayload: {
    propertyType: string;
    subwayStations: string[];
    district: string[];
    street: string;
    residence: string[];
    price: number[];
    square: number[] | undefined;
    floors: number[] | undefined;
    rooms: number[] | undefined;
    exclusive: boolean;
    vfId: number | undefined;
    date: string[] | undefined;
    status: string;
    clientType: string;
    team: string;
    special: {
      dog?: boolean;
      cat?: boolean;
      children?: boolean;
      smoking?: boolean;
      foreigners?: boolean;
      bed?: boolean;
      studio?: boolean;
      bathroom?: boolean;
      bake?: boolean;
    };
  };
}

const initialState: flatFiltersState = {
  subwayDistrictToggle: 'subway',
  streetResidenceToggle: 'street',
  allOptionsSelected: {
    subway: false,
  },
  propertyTypeState: {
    flat: false,
    room: false,
    house: false,
    commercial: false,
  },
  errors: [],
  notification: undefined,
  filtersPayload: {
    propertyType: '',
    subwayStations: [],
    district: [],
    street: '',
    residence: [],
    price: [],
    square: [],
    floors: [],
    rooms: [],
    exclusive: false,
    vfId: undefined,
    date: [],
    status: '',
    clientType: '',
    team: '',
    special: {},
  },
};

export const flatFiltersSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    toggleSubwayDistrictFilter: (state, action: PayloadAction<string>) => {
      state.subwayDistrictToggle = action.payload;
    },
    toggleStreetResidenceFilter: (state, action: PayloadAction<string>) => {
      state.streetResidenceToggle = action.payload;
    },
    setAllOptionsSelected: (state, action: PayloadAction<PayloadSetNewOption>) => {
      const { type, value } = action.payload;
      state.allOptionsSelected[type] = value;
    },
    setPropertyTypeState: (state, action: PayloadAction<PropertyState>) => {
      state.propertyTypeState = action.payload;
    },
    setFilterOption: (state, action: PayloadAction<PayloadSetNewOption>) => {
      const { type, value } = action.payload;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.filtersPayload[type] = value;
    },
  },
  extraReducers: {},
});

export default flatFiltersSlice.reducer;
