import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AddNewFlatFilesValues,
  AddNewFlatFormValues,
  AddNewFlatOLXFormValues,
  EditFlatInterface
} from '../../../types/Forms/FormValuesTypes';
import {
  addFlatAttachments,
  addNewFlat, addNewOlxFlat,
  editFlatService,
  updateFlatAttachmentsPosition,
  uploadFlatAttachments,
} from '../../../services/UserService';
import { AxiosError } from 'axios';
import {
  deleteFlatService, getContactData,
  getCustomerFlatsService,
  getFlats,
  getFlatService,
  removeFile,
} from '../../../services/FlatService';

export const addFlat = createAsyncThunk(
  'flat/addNewFlat',
  async (values: AddNewFlatFormValues, { rejectWithValue }) => {
    try {
      const { data } = await addNewFlat(values);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.response?.data || { status: 'failure', text: 'Что-то пошло не так' });
    }
  },
);

export const addOLXFlat = createAsyncThunk(
  'flat/addNewOLXFlat',
  async (values: AddNewFlatOLXFormValues, { rejectWithValue }) => {
    try {
      const { data } = await addNewOlxFlat(values);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.response?.data || { status: 'failure', text: 'Что-то пошло не так' });
    }
  },
);

export const uploadFlatFiles = createAsyncThunk(
  'flat/uploadFlatFiles',
  async (values: AddNewFlatFilesValues, { rejectWithValue }) => {
    try {
      const { data } = await uploadFlatAttachments(values.uuid, values.files);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.response?.data || { status: 'failure', text: 'Что-то пошло не так' });
    }
  },
);

export const addFlatFiles = createAsyncThunk(
  'flat/addFlatFiles',
  async (values: AddNewFlatFilesValues, { rejectWithValue }) => {
    try {
      const { data } = await addFlatAttachments(values.uuid, values.files);
      return data.flat;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.response?.data || { status: 'failure', text: 'Что-то пошло не так' });
    }
  },
);

export const updateFlatImagesPosition = createAsyncThunk(
  'flat/updateFlatImagesPosition',
  async (values: AddNewFlatFilesValues, { rejectWithValue }) => {
    try {
      const { data } = await updateFlatAttachmentsPosition(values.uuid, values.files);
      return data.flat;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.response?.data || { status: 'failure', text: 'Что-то пошло не так' });
    }
  },
);

export const editFlat = createAsyncThunk('flat/editFlat', async (values: EditFlatInterface, { rejectWithValue }) => {
  try {
    const { data } = await editFlatService(values.uuid, values.flat as any);
    return data;
  } catch (e) {
    const error = e as AxiosError;
    return rejectWithValue(error.response?.data || { status: 'failure', text: 'Что-то пошло не так' });
  }
});

export const deleteFlat = createAsyncThunk('flat/deleteFlat', async (payload: any, { rejectWithValue }) => {
  try {
    const { data } = await deleteFlatService(payload);
    return data;
  } catch (e) {
    const error = e as AxiosError;
    return rejectWithValue(error.response?.data || { status: 'failure', text: 'Что-то пошло не так' });
  }
});

// Remove file when saving edited flat and remove flat visually only if not saving edited flat
//
export const removeUploadedFile = createAsyncThunk(
  'user/removeUploadedFile',
  async (currentData: any, { rejectWithValue }) => {
    try {
      const { data } = await removeFile(currentData.flatUUID, currentData.fileName);
      return data.flat;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.response?.data || { status: 'failure', text: 'Что-то пошло не так' });
    }
  },
);

export const getAllFlats = createAsyncThunk('user/getAllFlats', async (payload: any, { rejectWithValue }) => {
  try {
    const { data } = await getFlats(payload);
    return data;
  } catch (e) {
    const error = e as AxiosError;
    return rejectWithValue(error.response?.data || { status: 'failure', text: 'Что-то пошло не так' });
  }
});

export const getFlat = createAsyncThunk('user/getFlat', async (uuid: string, { rejectWithValue }) => {
  try {
    const { data } = await getFlatService(uuid);
    return data;
  } catch (e) {
    const error = e as AxiosError;
    return rejectWithValue(error.response?.data || { status: 'failure', text: 'Что-то пошло не так' });
  }
});

export const getCustomerFlats = createAsyncThunk('user/getCustomerFlats', async (uuid: string, { rejectWithValue }) => {
  try {
    const { data } = await getCustomerFlatsService(uuid);
    return data.flats;
  } catch (e) {
    const error = e as AxiosError;
    return rejectWithValue(error.response?.data || { status: 'failure', text: 'Что-то пошло не так' });
  }
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getResourceContactData = createAsyncThunk('user/getContactData', async (payload: any, { rejectWithValue }) => {
  try {
    const { resourceKey, uuid } = payload;

    const { data } = await getContactData(resourceKey, uuid);
    return data;
  } catch (e) {
    const error = e as AxiosError;
    return rejectWithValue(error.response?.data || { status: 'failure', text: 'Что-то пошло не так' });
  }
});
