import React, { FC, memo } from 'react';
import classes from './Wrappers.module.scss';
import { HeadWrapperProps } from '../../types/Wrappers/HeadWrapperPropsTypes';
import Breadcrumbs from '../UI/Breadcrumbs/Breadcrumbs';

const HeadInfoWrapper: FC<HeadWrapperProps> = ({ breadcrumbs, currentPage, children, bodyStyle, headStyle }) => {
  return (
    <div className={classes.HeadingWrapper} style={{ ...headStyle }}>
      <Breadcrumbs breadcrumbs={breadcrumbs} currentPage={currentPage} />
      <div className={classes.HeadingWrapperBody} style={{ ...bodyStyle }}>
        <div className="filters-container">{children}</div>
      </div>
    </div>
  );
};

export default memo(HeadInfoWrapper);
