import React, { FC, memo } from 'react';

const MainLoader: FC = () => {
  return (
    <div style={{ margin: '150px 0', position: 'relative' }}>
      <div className="MainLoaderContainer">
        <span className="MainLoaderBlock" />
        <div className="MainLoaderItems">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default memo(MainLoader);
