export const COLORS = {
  PRIMARY: '#364B54',
  GENERAL: '#2B3C46',
  HOVER: '#C68A52',
  MAIN: '#2F6D8E',
  FILTERS_BG: '#303F49',
  HOVER_ALPHA: 'rgba(198,138,82,0.4)',
  INPUT_OUTLINE: 'rgba(255, 255, 255, 0.7)',
  WHITE: '#fff',
  GRAY_PHONE: 'rgba(43, 60, 70, 0.7)',
  HOVER_DELETE_BUTTON: 'rgb(255 28 28 / 12%)',
  HOVER_ACCEPT_BUTTON: 'rgb(32 245 96 / 20%)',
  OVERLAY_BLACK: '#00000006',
  RED: '#ff0000',
  ERROR: '#d32f2f',
  GREEN: '#179e17',
  BLUE: '#000dff',
  BLACK: '#000',
  LABELS_COLOR: 'rgba(43, 60, 70, 1)',
};
