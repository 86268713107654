import { useEffect } from 'react';

export const useDebounceAsyncHandler = (trigger: boolean | any, setDebouncedValue: () => void, delay = 600) => {
  useEffect(() => {
    if (trigger) {
      const handler = setTimeout(() => {
        setDebouncedValue();
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [trigger, delay, setDebouncedValue]);

  return true;
};
