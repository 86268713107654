import React from 'react';
import { SVGIconProps } from '../../../../../types/UI/Icons/SVGIconsTypes';

export const SubwayIcon: React.FC<SVGIconProps> = ({ width = 30, height = 25.96, clazz }) => (
  <svg width={width} height={height} viewBox="0 0 29 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8806 0.0593491C10.2647 0.335238 7.46507 1.56002 5.34134 3.35769C4.24286 4.28755 2.94735 5.84117 2.1699 7.161C0.127713 10.6279 -0.507598 14.8706 0.411446 18.9049C0.676983 20.0707 1.18436 21.4841 1.65927 22.3815L1.85432 22.75H14.3694H26.8846L27.1658 22.1456C27.7015 20.9944 28.0305 20.0141 28.3491 18.6207C29.2345 14.7487 28.5882 10.5907 26.5679 7.161C25.7905 5.84117 24.495 4.28755 23.3965 3.35769C21.2275 1.52176 18.4704 0.330167 15.7492 0.0527453C15.0333 -0.0202512 13.6043 -0.0169493 12.8806 0.0593491ZM15.6368 1.88179C18.5011 2.17926 21.0949 3.44166 23.1626 5.54464C25.167 7.58318 26.416 10.0471 26.8928 12.9031C27.0697 13.9624 27.1092 15.6825 26.9802 16.7078C26.8116 18.0479 26.4939 19.289 26.041 20.3767L25.8138 20.9221H14.447C3.3984 20.9221 3.07726 20.919 2.97471 20.8113C2.8188 20.6477 2.42437 19.5314 2.20123 18.6226C1.90762 17.4266 1.78395 16.4168 1.78237 15.2027C1.77929 12.8584 2.2196 10.9678 3.24738 8.91197C5.62322 4.15971 10.5658 1.35513 15.6368 1.88179ZM10.4212 4.03996C10.3637 4.14025 5.04593 18.2216 5.04593 18.2734C5.04593 18.3038 4.724 18.3278 4.31581 18.3278H3.5857V18.9174V19.507H7.43284H11.28V18.919V18.3311L10.5327 18.3147L9.78543 18.2983L10.5297 16.1166C10.939 14.9167 11.2816 13.9166 11.291 13.8941C11.3004 13.8715 12.0019 15.1123 12.8501 16.6511C14.0547 18.8371 14.4064 19.4328 14.458 19.3749C14.4944 19.334 15.1652 18.087 15.9489 16.6036C16.7325 15.1202 17.3874 13.9063 17.4042 13.906C17.4265 13.9056 18.4846 16.9694 18.906 18.2541C18.9245 18.3107 18.7527 18.3278 18.1659 18.3278H17.4017V18.9174V19.507H21.2207H25.0398V18.9174V18.3278L24.3799 18.3269L23.72 18.326L21.0762 11.1776C19.4202 6.69985 18.4026 4.02321 18.3524 4.01325C18.2716 3.99721 18.1274 4.27729 16.6291 7.36059C16.2588 8.12269 15.6077 9.46051 15.1822 10.3335L14.4086 11.9207L14.2692 11.6307C14.0751 11.2268 10.4832 4.03341 10.4642 4.01048C10.4557 4.00022 10.4364 4.01348 10.4212 4.03996Z"
      fill="white"
      className={clazz}
    />
  </svg>
);
