import { COLORS } from '../../colors';
import { FONTS } from '../fonts';

export const FormSelectGeneralStyle = (padding = '6px', inputWidth?: string | undefined, mr = 1) => ({
  mr,
  fontFamily: FONTS.GENERAL,
  padding: `${padding}!important`,
  marginRight: '0px!important',
  fontSize: '15px!important',
  color: `${COLORS.INPUT_OUTLINE}!important`,
  width: inputWidth ? `${inputWidth}!important` : '100%',
  paddingRight: '0px!important',
  fieldset: {
    borderColor: `${COLORS.INPUT_OUTLINE}!important`,
  },
  '&.Mui-focused fieldset': {
    borderColor: `${COLORS.HOVER}!important`,
  },
  '&:hover fieldset': {
    borderColor: `${COLORS.HOVER}!important`,
  },
  '& .MuiSvgIcon-root': {
    color: `${COLORS.INPUT_OUTLINE}!important`,
  },
  '& .MuiOutlinedInput-input': {
    padding: '7.5px 4px 7.5px 6px',
    fontSize: '14px!important',
    color: `${COLORS.WHITE}!important`,
    width: inputWidth ? `${inputWidth}!important` : null,
    // paddingRight: '0px!important',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: `${COLORS.INPUT_OUTLINE}!important`,
  },
  '& .MuiInputLabel-root': {
    color: `${COLORS.INPUT_OUTLINE}!important`,
    fontSize: '14px!important',
  },
  '& .MuiInputLabel-shrink': {
    left: '0px!important',
  },
});

export const FormSelectModalStyle = (padding = '6px', inputWidth?: string | undefined, mr = 1) => ({
  mr,
  fontFamily: FONTS.GENERAL,
  padding: `${padding}!important`,
  marginRight: '0px!important',
  fontSize: '15px!important',
  color: `${COLORS.GENERAL}!important`,
  width: inputWidth ? `${inputWidth}!important` : '100%!important',
  paddingRight: '0px!important',
  fieldset: {
    borderColor: `${COLORS.GRAY_PHONE}!important`,
  },
  '&.Mui-focused fieldset': {
    borderColor: `${COLORS.HOVER}!important`,
  },
  '&:hover fieldset': {
    borderColor: `${COLORS.HOVER}!important`,
  },
  '& .MuiSvgIcon-root': {
    color: `${COLORS.GENERAL}!important`,
  },
  '& .MuiOutlinedInput-input': {
    padding: '7.5px 4px 7.5px 6px',
    fontSize: '14px!important',
    color: `${COLORS.GENERAL}!important`,
    width: inputWidth ? `${inputWidth}!important` : '100%!important',
    // paddingRight: '0px!important',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: `${COLORS.GRAY_PHONE}!important`,
  },
  '& .MuiInputLabel-root': {
    color: `${COLORS.GENERAL}!important`,
    fontSize: '14px!important',
  },
  '& .MuiInputLabel-shrink': {
    left: '0px!important',
  },
});

export const SubwayListStyle = { display: 'flex', justifyContent: 'space-between' };

export const SelectMultipleFilterItemAllStyle = {
  fontSize: '14px',
  padding: '3px 5px',
  zIndex: 99,
  backgroundColor: COLORS.OVERLAY_BLACK,
};

export const helperTextStyle = {
  position: 'absolute',
  color: '#d32f2f!important',
  top: '100%',
  margin: '3px',
  whiteSpace: 'nowrap',
};
