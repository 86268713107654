import React from 'react';
import { SVGIconProps } from '../../../../../types/UI/Icons/SVGIconsTypes';

export const InfoIcon: React.FC<SVGIconProps> = ({ width = 23, height = 23 }) => (
  <svg width={width} height={height} viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.36827 3.86827C5.52494 1.7116 8.45001 0.5 11.5 0.5C14.55 0.5 17.4751 1.7116 19.6317 3.86827C21.7884 6.02494 23 8.95001 23 12C23 15.05 21.7884 17.9751 19.6317 20.1317C17.4751 22.2884 14.55 23.5 11.5 23.5C8.45001 23.5 5.52494 22.2884 3.36827 20.1317C1.2116 17.9751 0 15.05 0 12C0 8.95001 1.2116 6.02494 3.36827 3.86827ZM11.5 20.625C13.7875 20.625 15.9813 19.7163 17.5988 18.0988C19.2163 16.4813 20.125 14.2875 20.125 12C20.125 9.71251 19.2163 7.51871 17.5988 5.9012C15.9813 4.2837 13.7875 3.375 11.5 3.375C9.21251 3.375 7.01871 4.2837 5.4012 5.9012C3.7837 7.51871 2.875 9.71251 2.875 12C2.875 14.2875 3.7837 16.4813 5.4012 18.0988C7.01871 19.7163 9.21251 20.625 11.5 20.625ZM10.0625 8.16619C10.0625 7.37228 10.7061 6.72869 11.5 6.72869H11.5101C12.304 6.72869 12.9476 7.37228 12.9476 8.16619C12.9476 8.9601 12.304 9.60369 11.5101 9.60369H11.5C10.7061 9.60369 10.0625 8.9601 10.0625 8.16619ZM11.5 10.5625C12.2939 10.5625 12.9375 11.2061 12.9375 12V15.8338C12.9375 16.6277 12.2939 17.2713 11.5 17.2713C10.7061 17.2713 10.0625 16.6277 10.0625 15.8338V12C10.0625 11.2061 10.7061 10.5625 11.5 10.5625Z"
      fill="#C68A52"
    />
  </svg>
);
