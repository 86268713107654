import React, { FC, useCallback, useEffect } from 'react';
import AddCustomer from '../../../../components/Customers/Modals/AddCustomer';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { AddCustomerContainerProps } from '../../../../types/Customers/CustomersPropsTypes';
import {
  AddCustomerAPIValues,
  AddCustomerFormValues,
  PayloadCustomerPUTData,
} from '../../../../types/Forms/FormValuesTypes';
import { addCustomer, editCustomer, getCustomerContactData } from '../../../../store/actions/Customer/CustomerThunk';
import { STATUSES } from '../../../../constants/statuses';
import { Notify } from '../../../../utils/notifications';
import getRouteURI from '../../../../utils/getRouteURI';
import { APP_ROUTES } from '../../../../constants/routes';
import { useNavigate } from 'react-router-dom';

const AddCustomerContainer: FC<AddCustomerContainerProps> = ({ opened, handleClose, isEditMode, fillInPhone }) => {
  const { customer } = useAppSelector(state => state.customerReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [phones, setPhones] = React.useState([1]);

  const [phonesData, setPhonesData] = React.useState([]);

  const handleGetContactData = async () => {
    const { payload } = await dispatch(getCustomerContactData({ uuid: customer?.uuid }));

    if (payload.status === STATUSES.SUCCESS && payload?.contactData) {
        return setPhonesData(payload.contactData);
    }
     return Notify({ message: 'Ошибка получения данных', type: 'error' });

  }

  const handleAddCustomer = useCallback(
    async (values: AddCustomerFormValues) => {
      const phones = [values.phone1, values.phone2, values.phone3].filter(Boolean).map(phone => {
        return `0${phone}`;
      });
      const payloadToSend = {
        name: values.name.trim(),
        type: values.type,
        email: values.email?.trim(),
        phones,
        comment: values.comment?.trim(),
      };

      const { payload } = await dispatch(addCustomer(payloadToSend as AddCustomerAPIValues));

      if (payload.status === STATUSES.SUCCESS) {
        Notify({
          message: payload.notification.text,
          type: 'success',
        });
        handleClose();
        navigate(getRouteURI(APP_ROUTES.CUSTOMER_EDIT, { uuid: payload.customer.uuid }));
      }
    },
    [dispatch, handleClose, navigate],
  );

  const handleEditCustomer = useCallback(
    async (values: AddCustomerFormValues) => {
      const phones = [values.phone1, values.phone2, values.phone3].filter(Boolean).map(phone => {
        return `0${phone}`;
      });
      const payloadToSend = {
        uuid: customer?.uuid,
        data: {
          name: values.name,
          type: values.type,
          email: values.email,
          phones,
          comment: values.comment,
        },
      };
      const { payload } = await dispatch(editCustomer(payloadToSend as PayloadCustomerPUTData));

      if (payload.status === STATUSES.SUCCESS) {
        Notify({
          message: payload.notification.text,
          type: 'success',
        });
        handleClose();
      }
    },
    [dispatch, handleClose, customer],
  );

  const handleAutomaticallySetPhoneNumber = useCallback(() => {
    if (isEditMode && phonesData) {
      setPhones(phonesData.map((_: any, index: number) => index + 1).flat());
    }
  }, [phonesData, isEditMode]);

  useEffect(() => {
    handleAutomaticallySetPhoneNumber();
  }, [handleAutomaticallySetPhoneNumber]);

  return (
    <AddCustomer
      opened={opened}
      handleClose={handleClose}
      isEditMode={isEditMode}
      customer={customer}
      fillInPhone={fillInPhone}
      phones={phones}
      setPhones={setPhones}
      handleAddCustomer={handleAddCustomer}
      handleEditCustomer={handleEditCustomer}
      handleGetContactData={handleGetContactData}
      phonesData={phonesData}
    />
  );
};

export default AddCustomerContainer;
