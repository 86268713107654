import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AddCustomerAPIValues,
  FindCustomerAPIValues,
  PayloadCustomerPUTData,
} from '../../../types/Forms/FormValuesTypes';
import {
  addNewCustomer,
  editCurrentCustomer,
  findCustomer,
  getCustomer,
  getCustomerInfoData
} from '../../../services/UserService';
import { AxiosError } from 'axios';

export const addCustomer = createAsyncThunk(
  'user/addCustomer',
  async (values: AddCustomerAPIValues, { rejectWithValue }) => {
    try {
      const { data } = await addNewCustomer(values);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.response?.data || { status: 'failure', text: 'Что-то пошло не так' });
    }
  },
);

export const editCustomer = createAsyncThunk(
  'user/editCustomer',
  async (payload: PayloadCustomerPUTData, { rejectWithValue }) => {
    try {
      const { data } = await editCurrentCustomer(payload);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.response?.data || { status: 'failure', text: 'Что-то пошло не так' });
    }
  },
);

export const searchCustomer = createAsyncThunk(
  'user/searchCustomer',
  async (phone: FindCustomerAPIValues, { rejectWithValue }) => {
    try {
      const { data } = await findCustomer({ phone });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.response?.data || { status: 'failure', text: 'Что-то пошло не так' });
    }
  },
);

export const getCurrentCustomer = createAsyncThunk(
  'user/getCurrentCustomer',
  async (uuid: string, { rejectWithValue }) => {
    try {
      const { data } = await getCustomer(uuid);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.response?.data || { status: 'failure', text: 'Что-то пошло не так' });
    }
  },
);


export const getCustomerContactData = createAsyncThunk('user/getCustomerData', async (payload: any, { rejectWithValue }) => {
  try {
    const { uuid } = payload;

    const { data } = await getCustomerInfoData(uuid);
    return data;
  } catch (e) {
    const error = e as AxiosError;
    return rejectWithValue(error.response?.data || { status: 'failure', text: 'Что-то пошло не так' });
  }
});
