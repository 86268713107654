// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Attachments_Attachments__oI5WT {
  background: #F9F9FB;
  border: 1px dashed #D2D7E5;
  border-radius: 10px;
}
.Attachments_AttachmentsContainer__Kh6z4 {
  text-align: center;
}
.Attachments_AttachmentsContainer__Kh6z4 > p {
  color: #7B89B2;
  font-size: 14px;
  line-height: 20px;
}
.Attachments_AttachmentsThumbs__3xxoK {
  display: block;
}
.Attachments_AttachmentsThumbsContainer__hlRzJ {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
}
.Attachments_AttachmentsThumb__guC9C {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 120px;
  height: 120px;
  padding: 4px;
  box-sizing: border-box;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Attachments/Attachments.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,0BAAA;EACA,mBAAA;AACF;AACE;EACE,kBAAA;AACJ;AACI;EACE,cAAA;EACA,eAAA;EACA,iBAAA;AACN;AAGE;EACE,cAAA;AADJ;AAGI;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;AADN;AAKE;EACE,oBAAA;EACA,kBAAA;EACA,yBAAA;EACA,kBAAA;EACA,iBAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;EACA,sBAAA;EACA,kBAAA;AAHJ","sourcesContent":[".Attachments {\n  background: #F9F9FB;\n  border: 1px dashed #D2D7E5;\n  border-radius: 10px;\n\n  &Container {\n    text-align: center;\n\n    & > p {\n      color: #7B89B2;\n      font-size: 14px;\n      line-height: 20px;\n    }\n  }\n\n  &Thumbs {\n    display: block;\n\n    &Container {\n      display: flex;\n      flex-direction: row;\n      flex-wrap: wrap;\n      margin-top: 16px;\n    }\n  }\n\n  &Thumb {\n    display: inline-flex;\n    border-radius: 2px;\n    border: 1px solid #eaeaea;\n    margin-bottom: 8px;\n    margin-right: 8px;\n    width: 120px;\n    height: 120px;\n    padding: 4px;\n    box-sizing: border-box;\n    position: relative;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Attachments": `Attachments_Attachments__oI5WT`,
	"AttachmentsContainer": `Attachments_AttachmentsContainer__Kh6z4`,
	"AttachmentsThumbs": `Attachments_AttachmentsThumbs__3xxoK`,
	"AttachmentsThumbsContainer": `Attachments_AttachmentsThumbsContainer__hlRzJ`,
	"AttachmentsThumb": `Attachments_AttachmentsThumb__guC9C`
};
export default ___CSS_LOADER_EXPORT___;
