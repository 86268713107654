import React from 'react';
import { SVGIconProps } from '../../../../../../types/UI/Icons/SVGIconsTypes';

export const EmailIcon: React.FC<SVGIconProps> = ({ width = 16, height = 12, stroke = '#C68A52' }) => (
  <svg width={width} height={height} viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 2.5L8 6.25L14 2.5M2.75 10.75H13.25C14.0784 10.75 14.75 10.0784 14.75 9.25V3.25C14.75 2.42157 14.0784 1.75 13.25 1.75H2.75C1.92157 1.75 1.25 2.42157 1.25 3.25V9.25C1.25 10.0784 1.92157 10.75 2.75 10.75Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
