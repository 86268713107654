import _replace from 'lodash/replace';
import _forEach from 'lodash/forEach';

export default function getRouteURI(route: string, params?: any, queryParams?: any) {
  let selectedRoute = route;
  if (params) {
    _forEach(params, (value, key) => {
      selectedRoute = _replace(selectedRoute, `:${key}`, encodeURIComponent(value));
    });
  }

  if (queryParams) {
    const queryParamsString = new URLSearchParams(queryParams).toString();
    selectedRoute = `${selectedRoute}?${queryParamsString}`;
  }

  return selectedRoute;
}
