import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../models/IError';
import { ICustomer } from '../../models/ICustomer';
import { handleAPIBaseRequest, handleAPIManageCustomer } from '../../utils/handleAPIBaseRequest';
import { addCustomer, editCustomer, getCurrentCustomer, searchCustomer } from '../actions/Customer/CustomerThunk';
import { GetCustomerPayloadValues } from '../../types/Api/Customer/CustomerPayloadValues';
import { IInfoNotification } from '../../models/IInfoNotification';

interface CustomerState {
  customer: ICustomer | null;
  loading: boolean;
  errors: Array<IError>;
  notification: IInfoNotification | undefined;
}

const initialState: CustomerState = {
  customer: null,
  loading: false,
  errors: [],
  notification: undefined,
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCurrentCustomer: (state, action: PayloadAction<ICustomer>) => {
      state.customer = action.payload;
    },
    setPhones: (state, action: PayloadAction<any>) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.customer.phones = action.payload;
    },
  },
  extraReducers: {
    ...handleAPIBaseRequest(addCustomer),
    ...handleAPIManageCustomer(editCustomer),
    ...handleAPIBaseRequest(searchCustomer),
    [getCurrentCustomer.pending.type]: state => {
      state.loading = true;
      state.errors = [];
    },
    [getCurrentCustomer.fulfilled.type]: (state, action: PayloadAction<GetCustomerPayloadValues>) => {
      state.loading = false;
      const { customer } = action.payload;
      state.customer = customer;
    },
    [getCurrentCustomer.rejected.type]: (state, action: PayloadAction<IError>) => {
      state.loading = false;
      state.errors = [...state.errors, action.payload];
    },
  },
});

export default customerSlice.reducer;
