export const AttachmentsDeleteButton = {
  position: 'absolute',
  zIndex: '999',
  top: '-15px',
  right: '-15px',
};

export const AttachmentsImage = {
  display: 'block',
  width: 'auto',
  height: '115px',
};

export const AttachmentsThumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};
