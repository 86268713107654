import { IFlat } from '../models/IFlat';

export const checkIfPayloadSame = (payload: any, flat: IFlat | undefined) => {
  if (!Object.values(payload).length || !flat || !Object.values(flat).length) {
    return false;
  }
  const payloadToSend = {
    price: payload.price,
    district: payload.district,
    subwayStations: payload.subwayStations,
    timeToSubway: payload.timeToSubway,
    street: payload.street,
    residence: payload.residence,
    floors: payload.floors,
    square: payload.square,
    rooms: payload.rooms,
    exclusive: payload.exclusive,
    special: payload.special,
    rating: payload.rating,
    webLink: payload.webLink,
    notes: payload.notes,
    adText: payload.adText,
    adTitle: payload.adTitle,
    propertyType: payload.propertyType,
    clientType: payload.clientType,
  };

  const flatPayload = {
    price: flat.price,
    district: flat.district,
    subwayStations: flat.subwayStations,
    timeToSubway: flat.timeToSubway,
    street: flat.street,
    residence: flat.residence,
    floors: flat.floors,
    square: flat.square,
    rooms: flat.rooms,
    exclusive: flat.exclusive,
    special: flat.special,
    rating: flat.rating,
    webLink: flat.webLink,
    notes: flat.notes,
    adText: flat.adText,
    adTitle: flat.adTitle,
    propertyType: flat.propertyType,
    clientType: flat.clientType,
  };

  return JSON.stringify(payloadToSend) === JSON.stringify(flatPayload);
};
