import { FormikErrors, withFormik } from 'formik';
import isValidEmail from '../../utils/isValidEmail';
import AuthFormUI from '../../components/Forms/AuthFormUI';
import { AuthFormProps } from '../../types/Forms/FormPropsTypes';
import { AuthFormValues } from '../../types/Authorization/AuthorizationValues';

export const AuthForm = withFormik<AuthFormProps, AuthFormValues>({
  mapPropsToValues: props => ({
    email: props.initialEmail || '',
    password: props.initialPassword || '',
  }),
  validate: (values: AuthFormValues) => {
    const errors: FormikErrors<AuthFormValues> = {};
    if (!values.email) {
      errors.email = 'Обязательное поле';
    } else if (!isValidEmail(values.email)) {
      errors.email = 'Неверный адрес';
    }
    if (!values.password) {
      errors.password = 'Обязательное поле';
    }
    return errors;
  },
  handleSubmit: async (values, { props }) => {
    props.handleSignIn(values);
    // do submitting things
  },
})(AuthFormUI);
