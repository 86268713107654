import { isObject } from './isObject';

export const getPayloadQueries = (queries: any) => {
  const result: any = {};

  Object.entries(queries).forEach(([key, value]) => {
    if (!Array.isArray(value) && !isObject(value) && value) {
      // it is just simple value which is '' or null or undefined
      result[key] = value;
    }

    if (isObject(value)) {
      if (Object.values(value as any).filter(Boolean).length) {
        result[key] = value;
      }
    }

    if (Array.isArray(value) && value.length > 0) {
      result[key] = value;
    }

    return null;
  });

  return result;
};
