import { ICheckbox } from '../../models/Forms/ICheckbox';
import { PropertyTypes } from '../../types/Forms/FormStateTypes';

export const propertyFilterCheckboxes: ICheckbox[] = [
  {
    name: 'flat',
    label: 'Квартира',
  },
  {
    name: 'room',
    label: 'Комната',
  },
  {
    name: 'house',
    label: 'Дом',
  },
  {
    name: 'commercial',
    label: 'Коммерческое',
  },
];

export const PROPERTY_TYPES: PropertyTypes = {
  flat: 'Квартира',
  room: 'Комната',
  house: 'Дом',
  commercial: 'Коммерческое',
};
