import React, { FC, memo, useCallback } from 'react';
import Filters from '../../../components/Flats/Filters/Filters';
import Sorting from '../../../components/Flats/Filters/Sorting';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { filterFlatsByQueries } from '../../../store/actions/Flats/Filters/FiltersThunk';
import { getPayloadQueries } from '../../../utils/getPayloadQueries';
import usePrevious from '../../../hooks/usePrevious';
import {
  setCurrentPropertyTypeState,
  setNewAllOptionsSelected,
  setNewFilterOption,
  streetResidenceFilterToggle,
  subwayDistrictFilterToggle,
} from '../../../store/actions/Flats/Filters/FlatFiltersActions';

import { PROPERTY_TYPES } from '../../../mappings/Forms/checkboxes';
import { useDebounceAsyncHandler } from '../../../hooks/useDebounce';

const FilterContainer: FC = () => {
  const { filtersPayload, subwayDistrictToggle, streetResidenceToggle, allOptionsSelected, propertyTypeState } =
    useAppSelector(state => state.flatFiltersReducer);
  const dispatch = useAppDispatch();

  const handleDistrictCheckboxChange = useCallback(() => {
    dispatch(subwayDistrictFilterToggle(subwayDistrictToggle === 'subway' ? 'district' : 'subway'));
  }, [dispatch, subwayDistrictToggle]);

  const handleResidenceCheckboxChange = useCallback(() => {
    dispatch(streetResidenceFilterToggle(streetResidenceToggle === 'street' ? 'residence' : 'street'));
  }, [dispatch, streetResidenceToggle]);

  const previousPayload = usePrevious(filtersPayload);

  useDebounceAsyncHandler(
    filtersPayload && previousPayload && JSON.stringify(previousPayload) !== JSON.stringify(filtersPayload),
    () => dispatch(filterFlatsByQueries(getPayloadQueries(filtersPayload))),
  );

  const handleChangeSelectMultiple = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (ev: any, select) => {
      switch (select) {
        case 'district':
          dispatch(setNewFilterOption({ type: 'district', value: ev.target.value }));
          break;
        case 'residence':
          dispatch(setNewFilterOption({ type: 'residence', value: ev.target.value }));
          break;
        case 'subwayStations':
          dispatch(setNewFilterOption({ type: 'subwayStations', value: ev.target.value }));
          break;
        default:
          break;
      }
    },
    [dispatch],
  );

  const handleSelectAllStations = useCallback(
    (shouldSelectAll: boolean, name: string, visibleList: any) => {
      let allValueName = '';
      switch (name) {
        case 'subwayStations':
          allValueName = 'subway';
          break;
        default:
          break;
      }
      dispatch(setNewAllOptionsSelected({ type: allValueName, value: shouldSelectAll }));

      const allSelectedData = shouldSelectAll
        ? visibleList
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .reduce((total: string | any[], amount: any): any => {
              return total.concat(amount.data);
            }, [])
            .map((value: any) => value.title)
        : [];
      dispatch(setNewFilterOption({ type: name, value: allSelectedData }));
    },
    [dispatch],
  );

  const handleChangeInputField = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => dispatch(setNewFilterOption({ type: 'vfId', value: e.target.value })),
    [dispatch],
  );

  const handleChangePropertyType = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const result = {
        ...propertyTypeState,
        [event.target.name]: event.target.checked,
      };

      dispatch(setCurrentPropertyTypeState(result));

      const types: any = [];
      Object.entries(result).forEach(([key, value]) => {
        if (value) {
          types.push(PROPERTY_TYPES[key]);
        }
      });

      dispatch(setNewFilterOption({ type: 'propertyType', value: types }));
    },
    [dispatch, propertyTypeState],
  );

  const handleChangeStreet = useCallback(
    (value: string) => dispatch(setNewFilterOption({ type: 'street', value })),
    [dispatch],
  );

  const handleChangePriceField = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (values, triggered) => {
      if (values.some(Boolean) && triggered) {
        dispatch(setNewFilterOption({ type: 'price', value: values }));
      }
    },
    [dispatch],
  );

  const handleChangeRangeFields = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (type, values) => {
      if (!type) {
        return;
      }
      return dispatch(setNewFilterOption({ type, value: values }));
    },
    [dispatch],
  );

  const handleChangeDateField = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (values, type) => {
      if (values && type) {
        const filteredDates = values.map((date: string | number | Date) => new Date(date).toISOString());
        return dispatch(setNewFilterOption({ type, value: filteredDates }));
      }
    },
    [dispatch],
  );

  const handleChangeSelectSingle = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (ev: any, select) => {
      switch (select) {
        case 'status':
          dispatch(setNewFilterOption({ type: 'status', value: ev.target.value }));
          break;
        case 'clientType':
          dispatch(setNewFilterOption({ type: 'clientType', value: ev.target.value }));
          break;
        default:
          break;
      }
    },
    [dispatch],
  );

  const handleChangeCheckboxField = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      dispatch(setNewFilterOption({ type: 'exclusive', value: e.target.checked })),
    [dispatch],
  );

  const handleChangeSpecialFields = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    values => {
      const payloadValues = Object.fromEntries(
        Object.entries(values).filter(([key, value]) => {
          if (value) {
            return {
              [key]: value,
            };
          }
        }),
      );
      return dispatch(setNewFilterOption({ type: 'special', value: payloadValues }));
    },
    [dispatch],
  );

  return (
    <div className="notPrintable">
      <Filters
        handleResidenceCheckboxChange={handleResidenceCheckboxChange}
        handleDistrictCheckboxChange={handleDistrictCheckboxChange}
        subwayDistrictToggle={subwayDistrictToggle}
        streetResidenceToggle={streetResidenceToggle}
        filtersPayload={filtersPayload}
        handleChangeSelectMultiple={handleChangeSelectMultiple}
        allOptionsSelected={allOptionsSelected}
        handleSelectAllOptions={handleSelectAllStations}
        handleChangeInputField={handleChangeInputField}
        propertyState={propertyTypeState}
        handleChangePropertyType={handleChangePropertyType}
        handleChangeStreet={handleChangeStreet}
        handleChangePriceField={handleChangePriceField}
        handleChangeRangeFields={handleChangeRangeFields}
        handleChangeDateField={handleChangeDateField}
        handleChangeSelectSingle={handleChangeSelectSingle}
        handleChangeCheckboxField={handleChangeCheckboxField}
        handleChangeSpecialFields={handleChangeSpecialFields}
      />
      <Sorting />
    </div>
  );
};

export default memo(FilterContainer);
