import React from 'react';
import { SVGIconProps } from '../../../../types/UI/Icons/SVGIconsTypes';

export const StatusIcon: React.FC<SVGIconProps> = ({ width = 30, height = 30, clazz, fill = '#159900' }) => (
  <svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 2.8125C12.5895 2.8125 10.2332 3.52728 8.22899 4.86646C6.22477 6.20564 4.66267 8.10907 3.74022 10.336C2.81778 12.563 2.57643 15.0135 3.04668 17.3777C3.51694 19.7418 4.67769 21.9134 6.38214 23.6179C8.08659 25.3223 10.2582 26.4831 12.6223 26.9533C14.9865 27.4236 17.437 27.1822 19.664 26.2598C21.8909 25.3373 23.7944 23.7752 25.1335 21.771C26.4727 19.7668 27.1875 17.4105 27.1875 15C27.1813 11.7696 25.8953 8.67324 23.611 6.38898C21.3268 4.10472 18.2304 2.81869 15 2.8125ZM20.8008 12.8672L13.9336 19.4297C13.7561 19.5967 13.5211 19.689 13.2774 19.6875C13.1582 19.6892 13.0399 19.6673 12.9293 19.6231C12.8187 19.5788 12.7179 19.5131 12.6328 19.4297L9.19922 16.1484C9.10399 16.0654 9.02654 15.9639 8.97152 15.8501C8.91651 15.7363 8.88507 15.6126 8.8791 15.4863C8.87313 15.3601 8.89274 15.2339 8.93677 15.1154C8.98079 14.997 9.04832 14.8886 9.13528 14.7969C9.22225 14.7052 9.32686 14.632 9.44283 14.5818C9.55879 14.5315 9.68372 14.5053 9.81011 14.5045C9.93649 14.5038 10.0617 14.5286 10.1783 14.5775C10.2948 14.6264 10.4003 14.6984 10.4883 14.7891L13.2774 17.4492L19.5117 11.5078C19.694 11.3488 19.9307 11.2664 20.1724 11.2778C20.414 11.2892 20.6419 11.3936 20.8083 11.5692C20.9748 11.7447 21.0669 11.9778 21.0655 12.2197C21.0641 12.4616 20.9693 12.6936 20.8008 12.8672Z"
      fill={fill}
      className={clazz}
    />
  </svg>
);
