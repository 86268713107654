import { AddNewFlatFormProps, AddNewFlatOLXFormProps } from '../../types/Forms/FormPropsTypes';
import { Form, FormikProps, useFormikContext } from 'formik';
import { AddNewFlatFormValues, AddNewFlatOLXFormValues } from '../../types/Forms/FormValuesTypes';
import {
  FormControlModalStyle,
  FormInputConstructorStyle,
  FormInputSearchPlaceholder,
} from '../../constants/styles/Forms/inputs';
import { FormLabelConstructorStyle, FormLabelModalStyle } from '../../constants/styles/Forms/labels';
import React, { memo, useCallback, useEffect } from 'react';
import { SubwayLinesList } from '../../mappings/Forms/inputs';
import { COLORS } from '../../constants/colors';
import { Button, CircularProgress, FormControl, FormGroup, FormLabel, Rating } from '@mui/material';
import CheckboxLabelConstructor from '../UI/Fields/Checkboxes/CheckboxLabelField';
import IconsBox from '../UI/Icons/IconsBox';
import { SubmitButtonMain } from '../../constants/styles/Buttons/SubmitButtons';
import AttachmentsContainer from '../../containers/UI/Attachments/AttachmentsContainer';
import { DistrictLinesSingle, ResidenceListSingle } from '../../mappings/Forms/selects';
import classes from '../Flats/Flats.module.scss';
import { useAppSelector } from '../../hooks/redux';
import { useFreezeWhileLoading } from '../../hooks/useFreezeWhileLoading';
import SelectMultipleContainer from '../../containers/UI/Fields/Selects/SelectMultipleContainer';
import InputFieldContainer from '../../containers/UI/Fields/Inputs/InputFieldContainer';
import StreetFieldContainer from '../../containers/Flats/Filter/StreetFieldContainer';
import RangeFieldContainer from '../../containers/UI/Fields/Ranges/RangeFieldContainer';
import SelectSingleFieldContainer from '../../containers/UI/Fields/Selects/SelectSingleFieldContainer';
import {
  BakeIcon,
  BathroomIcon,
  BedIcon,
  CatIcon,
  ChildrenIcon,
  DogIcon,
  ForeignersIcon,
  SmokingIcon,
  StudioIcon,
} from '../UI/Icons';
import CheckboxLabelFieldContainer from '../../containers/UI/Fields/Checkboxes/CheckboxLabelFieldContainer';
import IconsBoxContainer from '../../containers/UI/Icons/IconsBoxContainer';

const AddNewFlatOLXFormUI = (props: AddNewFlatOLXFormProps & FormikProps<AddNewFlatOLXFormValues>) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleChangeStreet,
  } = props;
  const { loading, street } = useAppSelector(state => state.flatReducer);

  useFreezeWhileLoading(loading);

  const handleValidateAddNewFlatField = useCallback(
    (field: string, type: string, customOnChange?: any) => {
      return {
        type: type,
        name: field,
        value: values[field as keyof AddNewFlatOLXFormValues],
        onBlur: handleBlur,
        onChange: customOnChange ? customOnChange : handleChange,
        error: touched[field as keyof AddNewFlatOLXFormValues]
          && Boolean(errors[field as keyof AddNewFlatOLXFormValues]),
        helperText: touched[field as keyof AddNewFlatOLXFormValues] && errors[field as keyof AddNewFlatOLXFormValues],
      };
    },
    [errors, handleBlur, handleChange, touched, values],
  );

 const notesValidation = handleValidateAddNewFlatField('notes', 'text');
 const urlValidation = handleValidateAddNewFlatField('url', 'text');
  const subwayStationsValidation = handleValidateAddNewFlatField('subwayStations', 'select');
  const timeToSubwayValidation = handleValidateAddNewFlatField('timeToSubway', 'number');
  const residenceValidation = handleValidateAddNewFlatField('residence', 'select');

  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '20px',
  };

  return (
    <Form style={{ margin: '0px 40px' }}>
      <div style={gridStyle} className="grid-container">
          <InputFieldContainer
            label="URL"
            isInline
            required
            formControlStyle={FormControlModalStyle('25px 0 10px 0', 'unset')}
            formLabelStyle={FormLabelConstructorStyle(undefined, '0px', undefined, 'rgba(43, 60, 70, 1)')}
            formInputStyle={FormInputConstructorStyle('2px 0px', '100%', undefined, undefined, 'rgba(43, 60, 70, 1)')}
            formGroupStyle={{ width: '100%' }}
            value={urlValidation.value}
            handleChange={urlValidation.onChange}
            validation={urlValidation}
            disableAutoComplete
          />
          <StreetFieldContainer
            formControlStyle={FormControlModalStyle('25px 0px', 'unset')}
            formLabelStyle={FormLabelConstructorStyle(undefined, '0px', undefined, 'rgba(43, 60, 70, 1)')}
            formInputStyle={FormInputConstructorStyle('2px 0px', '100%', 0, undefined, 'rgba(43, 60, 70, 1)')}
            formGroupStyle={{ width: '100%' }}
            isInline
            required
            label="Адрес"
            svgColor={COLORS.BLACK}
            street={street}
            handleChange={handleChangeStreet}
          />
      </div>

      <div style={gridStyle} className="grid-container">
      <SelectMultipleContainer
        formControlStyle={FormControlModalStyle('25px 0px', 'unset')}
        formLabelStyle={FormLabelConstructorStyle(undefined, '0px', undefined, 'rgba(43, 60, 70, 1)')}
        formInputStyle={FormInputConstructorStyle('2px 0px', '100%', 0, undefined, 'rgba(43, 60, 70, 1)')}
        formGroupStyle={{ width: '100%' }}
        placeholderStyle={{ ...FormInputSearchPlaceholder, width: '100%!important' }}
        list={SubwayLinesList}
        isInline
        label="Станции метро"
        isGroup
        isSearchRequired
        customFormStyle={{ width: '100%!important' }}
        colorGroupedTitles={[COLORS.RED, COLORS.BLUE, COLORS.GREEN]}
        emPlaceholderColor={COLORS.LABELS_COLOR}
        selectedValue={subwayStationsValidation.value}
        handleChange={subwayStationsValidation.onChange}
        validation={subwayStationsValidation}
      />
        <SelectSingleFieldContainer
          label="ЖК"
          isInline
          formControlStyle={FormControlModalStyle('25px 0px', 'unset')}
          formLabelStyle={FormLabelConstructorStyle(undefined, '0px', undefined, 'rgba(43, 60, 70, 1)')}
          formInputStyle={FormInputConstructorStyle('2px 0px', '100%', 0, undefined, 'rgba(43, 60, 70, 1)')}
          formGroupStyle={{ width: '100%' }}
          options={ResidenceListSingle}
          validation={residenceValidation}
          selectedValue={residenceValidation.value}
          handleChange={residenceValidation.onChange}
        />
        <InputFieldContainer
          label="До метро"
          isInline
          endPlaceholder="минут"
          formControlStyle={FormControlModalStyle('25px 0', 'unset')}
          formLabelStyle={FormLabelConstructorStyle(undefined, '0px', undefined, 'rgba(43, 60, 70, 1)')}
          formInputStyle={FormInputConstructorStyle('2px 0px', '300px', 0, undefined, 'rgba(43, 60, 70, 1)')}
          value={timeToSubwayValidation.value}
          handleChange={timeToSubwayValidation.onChange}
          validation={timeToSubwayValidation}
        />
      </div>

      <div>
        <InputFieldContainer
          label="Важная информация"
          isInline
          formControlStyle={FormControlModalStyle('25px 0 10px 0', 'unset')}
          formLabelStyle={FormLabelConstructorStyle(undefined, '0px', undefined, 'rgba(43, 60, 70, 1)')}
          formInputStyle={FormInputConstructorStyle('2px 0px', '100%', undefined, undefined, 'rgba(43, 60, 70, 1)')}
          formGroupStyle={{ width: '100%' }}
          value={notesValidation.value}
          handleChange={notesValidation.onChange}
          validation={notesValidation}
        />
        <p
          style={{
            // marginBottom: '15px',
            fontSize: '12px',
            color: values.notes && values.notes.length >= 290 ? 'red' : '#7d7979',
            textAlign: 'end',
          }}
        >
          {values.notes?.length}/290
        </p>
      </div>

      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          marginTop: '50px',
          marginBottom: '100px',
        }}
      >
        <SubmitButtonMain
          type="submit"
          disabled={loading}
          variant="contained"
          sx={{
            marginRight: '25px',
            width: '400px!important',
          }}
        >
          <p>Добавить</p>
          {loading && <CircularProgress size="1.3em" sx={{ marginLeft: '10px' }} />}
        </SubmitButtonMain>
      </div>
    </Form>
  );
};

export default memo(AddNewFlatOLXFormUI);
