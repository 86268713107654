import React, { FC, useCallback } from 'react';
import { SelectSingleFieldContainerProps } from '../../../../types/Forms/FormPropsTypes';
import SelectSingleField from '../../../../components/UI/Fields/Selects/SelectSingleField';

const SelectSingleFieldContainer: FC<SelectSingleFieldContainerProps> = ({
  label,
  options,
  formControlStyle,
  formLabelStyle,
  formInputStyle,
  formGroupStyle,
  validation,
  required,
  isInline,
  defaultValue = '',
  removeEmptyValue,
  name,
  handleChange,
  selectedValue,
}) => {
  const handleChangeValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!name) {
        return handleChange(e);
      }
      return handleChange(e, name);
    },
    [handleChange, name],
  );

  return (
    <SelectSingleField
      formControlStyle={formControlStyle}
      formLabelStyle={formLabelStyle}
      formInputStyle={formInputStyle}
      formGroupStyle={formGroupStyle}
      required={required}
      isInline={isInline}
      label={label}
      options={options}
      validation={validation}
      defaultValue={defaultValue}
      removeEmptyValue={removeEmptyValue}
      value={selectedValue}
      handleChange={handleChangeValue}
    />
  );
};

export default SelectSingleFieldContainer;
