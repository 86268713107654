import { COLORS } from '../colors';

export const navbarIconStyle = {
  width: '36px',
  height: '36px',
};

export const addFlatIconStyle = {
  fontSize: '23.61px',
  width: '23.61px',
  height: '23.61px',
};

export const addCustomerIconStyle = {
  fontSize: '28',
  width: '28px',
  height: '28px',
  marginTop: '3px',
};

export const avatarIconStyle = {
  width: '36px',
  height: '36px',
  border: `1px solid ${COLORS.GENERAL}`,
  borderRadius: '50%',
};

export const avatarCustomerStyle = {
  width: '100px',
  height: '100px',
  fontSize: '54px',
  lineHeight: '32px',
  marginRight: '5px',
  borderRadius: '5px',
  textDecoration: 'none',
};
