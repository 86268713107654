import React from 'react';
import { SVGIconProps } from '../../../../types/UI/Icons/SVGIconsTypes';

export const DateIcon: React.FC<SVGIconProps> = ({ width = 20, height = 20, clazz, stroke = '#2F6D8E' }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5 7.5C2.5 6.11929 3.61929 5 5 5H15C16.3807 5 17.5 6.11929 17.5 7.5V14.1667C17.5 15.5474 16.3807 16.6667 15 16.6667H5C3.61929 16.6667 2.5 15.5474 2.5 14.1667V7.5Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={clazz}
    />
    <path
      d="M6.66667 3.33325V4.99992"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={clazz}
    />
    <path
      d="M13.3333 3.33325V4.99992"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={clazz}
    />
    <circle cx="6.66666" cy="8.33333" r="0.833333" fill="#2F6D8E" />
    <circle cx="10" cy="8.33333" r="0.833333" fill="#2F6D8E" />
    <ellipse cx="13.3333" cy="8.33333" rx="0.833333" ry="0.833333" fill="#2F6D8E" />
    <ellipse cx="6.66666" cy="11.6666" rx="0.833333" ry="0.833333" fill="#2F6D8E" />
  </svg>
);
