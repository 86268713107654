import { FormikErrors, useFormikContext, withFormik } from 'formik';
import { AddNewFlatFormProps } from '../../types/Forms/FormPropsTypes';
import { AddNewFlatFormValues } from '../../types/Forms/FormValuesTypes';
import AddNewFlatFormUI from '../../components/Forms/AddNewFlatFormUI';
import isURL from '../../utils/isURL';

export const AddNewFlatForm = withFormik<AddNewFlatFormProps, AddNewFlatFormValues>({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  mapPropsToValues: props => {
    if (props.isEditMode && props.flatPreview) {
      return {
        price: props.flatPreview.price,
        district: props.flatPreview.district,
        subwayStations: props.flatPreview.subwayStations,
        timeToSubway: props.flatPreview.timeToSubway,
        street: props.flatPreview.street,
        residence: props.flatPreview.residence,
        square: props.flatPreview.square,
        rooms: props.flatPreview.rooms,
        notes: props.flatPreview.notes,
        rating: props.flatPreview.rating,
        adTitle: props.flatPreview.adTitle,
        adText: props.flatPreview.adText,
        floorsTotal: props.flatPreview.floors.total,
        floorsCurrent: props.flatPreview.floors.current,
        exclusive: props.flatPreview.exclusive,
        webLink: props.flatPreview.webLink,
      };
    }

    return {
      price: undefined,
      district: '',
      subwayStations: [],
      timeToSubway: undefined,
      street: undefined,
      residence: '',
      square: undefined,
      rooms: undefined,
      notes: '',
      rating: '',
      adTitle: '',
      adText: '',
      floorsTotal: undefined,
      floorsCurrent: undefined,
      exclusive: false,
      webLink: '',
    };
  },

  validate: (values: AddNewFlatFormValues) => {
    const errors: FormikErrors<AddNewFlatFormValues> = {};
    if (!values.price) {
      errors.price = 'Обязательное поле';
    }

    if (!values.district.length) {
      errors.district = 'Обязательное поле';
    }

    if (!values.square) {
      errors.square = 'Обязательное поле';
    }

    if (!values.rooms) {
      errors.rooms = 'Обязательное поле';
    }

    if (values.notes && values.notes.length > 290) {
      errors.notes = 'Допустимо не более 290 символов';
    }

    if (!values.adTitle) {
      errors.adTitle = 'Обязательное поле';
    }

    if (!values.floorsTotal || !values.floorsCurrent) {
      errors.floorsTotal = 'Обязательное поле';
      errors.floorsCurrent = ' ';
    }

    if (!values.adText.length) {
      errors.adText = 'Обязательное поле';
    }

    if (values.webLink && typeof values.webLink === 'string' && !isURL(values.webLink)) {
      errors.webLink = 'Неверный формат URL';
    }

    // TODO: Add after button submitted only
    // if (errors && Object.keys(errors)?.length) {
    //   Notify({ message: 'Проверьте правильность заполнения полей', type: 'error' });
    // }
    return errors;
  },
  validateOnBlur: true,
  validateOnChange: false,
  handleSubmit: async (values, { props }) => {
    const newValues = { ...values, floors: { total: values.floorsTotal, current: values.floorsCurrent } };
    props.isEditMode ? props.handleEditFlat(newValues) : props.handleAddNewFlat(newValues);
    // do submitting things
  },
})(AddNewFlatFormUI);
