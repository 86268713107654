import { FC, useCallback, useEffect, useState } from 'react';
import {
  SelectMultipleFilterConstructorGroupedList,
  SelectMultipleFilterConstructorList,
} from '../../../../types/Forms/Fields/Selects/SelectTypes';
import * as React from 'react';
import { SelectMultipleContainerProps } from '../../../../types/Forms/FormPropsTypes';
import SelectMultipleField from '../../../../components/UI/Fields/Selects/SelectMultipleField';

const SelectMultipleContainer: FC<SelectMultipleContainerProps> = ({
  formControlStyle,
  formLabelStyle,
  formInputStyle,
  formGroupStyle,
  placeholderStyle,
  list,
  label,
  placeholderLabel,
  isInline,
  isGroup,
  isSearchRequired,
  customFormStyle,
  allOptionTitle,
  colorGroupedTitles,
  name,
  emPlaceholderColor,
  validation,
  selectedValue,
  handleChange,
  allSelected,
  handleSelectAllOptions,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const [visibleList, setVisibleList] = useState<
    SelectMultipleFilterConstructorGroupedList[] | SelectMultipleFilterConstructorList[]
  >([]);

  const handleSelectVisibleList = useCallback(() => {
    if (isSearchRequired && list?.length) {
      setVisibleList(list);
    }
  }, [isSearchRequired, list]);

  useEffect(() => {
    handleSelectVisibleList();
  }, [handleSelectVisibleList]);

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value ? e.target.value.toLowerCase().trim() : '';
      setSearchTerm(value);
      const filteredList = list
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .map(d => ({ ...d, data: d.data.filter(c => c.title.toLowerCase().includes(value.toLowerCase())) }))
        .filter(d => d.data.length);
      setVisibleList(filteredList);
    },
    [list],
  );

  const handleSelectAll = useCallback(
    (shouldSelectAll: boolean) => {
      if (isGroup && name && allOptionTitle) {
        handleSelectAllOptions(shouldSelectAll, name, visibleList);
      }
    },
    [allOptionTitle, handleSelectAllOptions, isGroup, name, visibleList],
  );

  return (
    <SelectMultipleField
      formControlStyle={formControlStyle}
      formLabelStyle={formLabelStyle}
      formInputStyle={formInputStyle}
      formGroupStyle={formGroupStyle}
      placeholderStyle={placeholderStyle}
      list={list}
      label={label}
      placeholderLabel={placeholderLabel}
      isInline={isInline}
      isGroup={isGroup}
      isSearchRequired={isSearchRequired}
      customFormStyle={customFormStyle}
      allOptionTitle={allOptionTitle}
      colorGroupedTitles={colorGroupedTitles}
      name={name}
      emPlaceholderColor={emPlaceholderColor}
      validation={validation}
      handleChange={handleChange}
      selectedValue={selectedValue}
      allSelected={allSelected}
      visibleList={visibleList}
      handleSelectAll={handleSelectAll}
      handleSearch={handleSearch}
      searchTerm={searchTerm}
    />
  );
};

export default SelectMultipleContainer;
