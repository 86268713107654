import * as React from 'react';
import { FC, memo } from 'react';
import { FormControl, FormGroup, FormLabel, TextField } from '@mui/material';
import { FormLabelGeneralStyle, InputLabelPropsStyle } from '../../../constants/styles/Forms/labels';
import { FormControlGeneralStyle, FormInputGeneralStyle } from '../../../constants/styles/Forms/inputs';
import SVGIcon from '../../UI/Icons/IconBody';
import { SVGIconStyles } from '../../../constants/styles/Forms/icons';
import { StreetFieldComponentProps } from '../../../types/Flats/Filters/FiltersPropsTypes';
import { SearchIcon } from '../../UI/Icons';

const StreetField: FC<StreetFieldComponentProps> = ({
  formControlStyle = FormControlGeneralStyle(),
  formLabelStyle = FormLabelGeneralStyle(),
  formInputStyle = FormInputGeneralStyle('6px', '300px', 1),
  formGroupStyle,
  isInline,
  svgColor = '#fff',
  label = 'Улица',
  required,
  streetFieldRef,
  handleChange,
  value,
}) => {
  return (
    <FormControl sx={formControlStyle} component="fieldset" variant="standard">
      <div className={isInline ? 'd-flex align-items-center' : ''}>
        <FormLabel sx={formLabelStyle} component="legend">
          {required ? (
            <p>
              {label}
              <span className="requiredLabel">*</span>
            </p>
          ) : (
            label
          )}
        </FormLabel>
        <FormGroup sx={formGroupStyle}>
          <div style={{ display: 'flex' }}>
            <TextField
              inputRef={streetFieldRef}
              fullWidth
              label="Поиск"
              color="primary"
              variant="outlined"
              value={value}
              onChange={handleChange}
              InputLabelProps={{
                shrink: value.length > 0,
                style: {
                  ...InputLabelPropsStyle(
                    undefined,
                    undefined,
                    undefined,
                    isInline ? (value.length > 0 ? '0' : '-3px') : undefined,
                  ),
                  marginLeft: value.length > 0 ? '2px' : isInline ? '15px' : '20px',
                },
              }}
              InputProps={{
                startAdornment: !value ? (
                  <>
                    <SVGIcon currentIcon={SearchIcon} style={SVGIconStyles} width={16} height={16} stroke={svgColor} />
                  </>
                ) : null,
                placeholder: '',
              }}
              autoComplete="off"
              sx={formInputStyle}
            />
          </div>
        </FormGroup>
      </div>
    </FormControl>
  );
};

export default memo(StreetField);
