export const filtersSortingData = [
  {
    id: 1,
    label: 'Цена',
    name: 'price',
  },
  {
    id: 2,
    label: 'Добавлен',
    name: 'added',
  },
  {
    id: 3,
    label: 'Активность',
    name: 'activity',
  },
  {
    id: 4,
    label: 'Рейтинг',
    name: 'rating',
  },
];
