import React, { FC, useCallback, useEffect, useState } from 'react';
import classes from './AuthContainer.module.scss';
import Auth from '../../../components/Authorization/Auth/Auth';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { auth, verifyLogin } from '../../../store/actions/User/UserThunk';
import { STATUSES } from '../../../constants/statuses';
import AuthVerification from '../../../components/Authorization/Auth/AuthVerification';
import { setupUser } from '../../../store/actions/User/UserActions';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../constants/routes';
import { AuthFormValues, AuthFormVerificationValues } from '../../../types/Authorization/AuthorizationValues';
import { Notify } from '../../../utils/notifications';

const AuthContainer: FC = () => {
  const dispatch = useAppDispatch();
  const { user, notification } = useAppSelector(state => state.userReducer);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [, setIsVerifiedLogin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn && notification) {
      Notify({
        message: notification.text,
      });
    }
  }, [isLoggedIn, notification]);

  const handleSignIn = useCallback(
    async (values: AuthFormValues) => {
      const { payload } = await dispatch(auth(values as AuthFormValues));
      if (payload.status !== STATUSES.FAILURE) {
        if (payload?.user && payload?.token) {
            setIsVerifiedLogin(true);
            await dispatch(setupUser(payload));
            navigate(APP_ROUTES.HOME);

            if (payload?.notification?.text) {
              Notify({
                message: payload.notification.text,
              });
            }
        }

        setIsLoggedIn(true);
      }
    },
    [dispatch],
  );

  const handleVerifyLogin = useCallback(
    async (values: AuthFormVerificationValues) => {
      const payloadToSend = {
        ...values,
        email: user?.email,
        keepSigned: true,
      };
      const { payload } = await dispatch(verifyLogin(payloadToSend as AuthFormVerificationValues));

      if (payload.status === STATUSES.SUCCESS) {
        setIsVerifiedLogin(true);
        await dispatch(setupUser(payload));
        navigate(APP_ROUTES.HOME);
      }
    },
    [dispatch, navigate, user?.email],
  );

  return (
    <main className={classes.AuthContainer}>
      {isLoggedIn ? <AuthVerification handleVerifyLogin={handleVerifyLogin} /> : <Auth handleSignIn={handleSignIn} />}
    </main>
  );
};

export default AuthContainer;
