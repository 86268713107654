import React from 'react';
import { SVGIconProps } from '../../../../../../types/UI/Icons/SVGIconsTypes';

export const CustomerTypeIcon: React.FC<SVGIconProps> = ({ width = 14, height = 14 }) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.66667 0.75C8.09412 0.75 9.25 1.90588 9.25 3.33333C9.25 4.76079 8.09412 5.91667 6.66667 5.91667C5.23921 5.91667 4.08333 4.76079 4.08333 3.33333C4.08333 1.90588 5.23921 0.75 6.66667 0.75ZM12.5833 12.5833H0.75V11.6667C0.75 11.388 0.883659 11.0883 1.23745 10.7618C1.59633 10.4306 2.12936 10.1248 2.77786 9.86496C4.07606 9.3449 5.64792 9.08333 6.66667 9.08333C7.68541 9.08333 9.25727 9.3449 10.5555 9.86496C11.204 10.1248 11.737 10.4306 12.0959 10.7618C12.4497 11.0883 12.5833 11.388 12.5833 11.6667V12.5833Z"
      stroke="#C68A52"
      strokeWidth="1.5"
    />
  </svg>
);
