import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { filterFlatsService } from '../../../../services/FlatService';

export const filterFlatsByQueries = createAsyncThunk(
  'user/filterFlatsByQueries',
  async (queries: any, { rejectWithValue }) => {
    try {
      const { data } = await filterFlatsService(queries);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.response?.data || { status: 'failure', text: 'Что-то пошло не так' });
    }
  },
);
