import React, { FC, useEffect, useState } from 'react';
import { RangeFieldContainerProps } from '../../../../types/UI/Fields/FieldsPropsTypes';
import RangeFormConstructor from '../../../../components/UI/Fields/Ranges/RangeField';

const RangeFieldContainer: FC<RangeFieldContainerProps> = ({
  label,
  formInputStyle,
  formGroupStyle,
  formControlStyle,
  formLabelStyle,
  required,
  labels = ['От', 'До'],
  currentType,
  removeMarginRight,
  validateMultiple,
  filterNames,
  handleChange,
  values,
}) => {
  const [localValues, setLocalValues] = useState<number[]>(values.length ? values : [0, 0]);
  const [triggered, setTriggered] = useState<boolean>(false);

  useEffect(() => {
    if (triggered) {
      handleChange(filterNames && filterNames[0].replaceAll('Min', ''), localValues);
    }
  }, [filterNames, handleChange, localValues, triggered]);

  const handleRangeChange = (event: any) => {
    if (event.target.name.includes('Min')) {
      setTriggered(true);
      setLocalValues(prev => {
        const newValues = [...prev];
        newValues[0] = Number(event.target.value);
        return newValues;
      });
    } else {
      setTriggered(true);
      setLocalValues(prev => {
        const newValues = [...prev];
        newValues[1] = Number(event.target.value);
        setTriggered(true);
        return newValues;
      });
    }
  };

  return (
    <RangeFormConstructor
      label={label}
      labels={labels}
      formControlStyle={formControlStyle}
      formLabelStyle={formLabelStyle}
      formInputStyle={formInputStyle}
      formGroupStyle={formGroupStyle}
      currentType={currentType}
      removeMarginRight={removeMarginRight}
      required={required}
      filterNames={filterNames}
      validateMultiple={validateMultiple}
      handleChange={handleRangeChange}
      values={localValues}
    />
  );
};

export default RangeFieldContainer;
