import React from 'react';
import { SVGIconProps } from '../../../../../types/UI/Icons/SVGIconsTypes';

export const StudioIcon: React.FC<SVGIconProps> = ({ width = 29.94, height = 30, clazz, fill = 'white' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clazz}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.166029 0.268371C0.0217974 0.44202 -0.0229519 2.1457 0.0105656 6.17689C0.0535321 11.3473 0.0849101 11.8585 0.36963 12.038C0.597121 12.1815 0.766134 12.1815 0.993625 12.038C1.27728 11.8592 1.3099 11.3694 1.35287 6.64578L1.40011 1.4504H14.947H28.494V9.29098V17.1317L25.0813 17.1801C22.0568 17.223 21.6461 17.264 21.4719 17.5405C21.3286 17.7679 21.3286 17.9372 21.4719 18.1645C21.6461 18.4411 22.0568 18.4821 25.0813 18.525L28.494 18.5733V23.5616V28.5496H22.0757H15.6575V23.5644V18.5791L17.2877 18.5279C18.6104 18.4862 18.9551 18.4177 19.1143 18.1645C19.2571 17.9374 19.2571 17.7677 19.1141 17.5405C18.9485 17.2774 18.5896 17.2207 16.8226 17.1781C15.4307 17.1448 14.6443 17.1968 14.4794 17.3337C14.2772 17.5015 14.2312 18.5589 14.2312 23.0445V28.5496H7.81567H1.40011L1.35287 23.3542C1.3099 18.6306 1.27728 18.1408 0.993625 17.962C0.766134 17.8185 0.597121 17.8185 0.36963 17.962C0.0849101 18.1415 0.0535321 18.6527 0.0105656 23.8231C-0.0229519 27.8543 0.0217974 29.558 0.166029 29.7316C0.445936 30.0689 29.3697 30.0985 29.7063 29.7619C30.0162 29.4521 30.0162 0.54792 29.7063 0.238062C29.3697 -0.0985384 0.445936 -0.0689431 0.166029 0.268371Z"
      fill={fill}
    />
  </svg>
);
