import React, { FC, memo } from 'react';
import { FormControl, FormGroup, FormLabel } from '@mui/material';
import CheckboxLabelConstructor from '../../UI/Fields/Checkboxes/CheckboxLabelField';
import { FormLabelGeneralStyle } from '../../../constants/styles/Forms/labels';
import { propertyFilterCheckboxes } from '../../../mappings/Forms/checkboxes';
import { FormControlGeneralStyle } from '../../../constants/styles/Forms/inputs';
import { PropertyTypeFilterProps } from '../../../types/Flats/Filters/FiltersPropsTypes';
import CheckboxLabelFieldContainer from '../../../containers/UI/Fields/Checkboxes/CheckboxLabelFieldContainer';

const PropertyType: FC<PropertyTypeFilterProps> = ({ isInline, handleChange, propertyState }) => (
  <FormControl
    sx={FormControlGeneralStyle(isInline ? '0px' : undefined, isInline ? 'unset' : '300px')}
    component="fieldset"
    variant="standard"
  >
    <div className={isInline ? 'd-flex align-items-center' : ''}>
      <FormLabel sx={FormLabelGeneralStyle(undefined, isInline ? '0px' : '10px')} component="legend">
        Тип недвижимости
      </FormLabel>
      <FormGroup sx={{ flexDirection: isInline ? 'unset' : undefined }}>
        <div>
          {propertyFilterCheckboxes.slice(0, 2).map(checkbox => (
            <CheckboxLabelFieldContainer
              label={checkbox.label}
              key={checkbox.name}
              handleChange={handleChange}
              name={checkbox.name}
              checked={propertyState?.[checkbox.name]}
            />
          ))}
        </div>
        <div>
          {propertyFilterCheckboxes.slice(2).map(checkbox => (
            <CheckboxLabelFieldContainer
              label={checkbox.label}
              key={checkbox.name}
              handleChange={handleChange}
              name={checkbox.name}
              checked={propertyState?.[checkbox.name]}
            />
          ))}
        </div>
      </FormGroup>
    </div>
  </FormControl>
);

export default memo(PropertyType);
