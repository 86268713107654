export const capitalizeFullName = (name: string, surname: string): string => {
  if (!name || !surname) {
    return '';
  }
  const nameCapitalized = name.charAt(0).toUpperCase() + name.slice(1);
  const surnameCapitalized = surname.charAt(0).toUpperCase() + surname.slice(1);
  return `${nameCapitalized} ${surnameCapitalized}`;
};

export const capitalizeTitle = (title: string): string => {
  const titleCapitalized = title.charAt(0).toUpperCase() + title.slice(1);
  return titleCapitalized;
};

export const capitalizeEveryWord = (title: string | undefined): string => {
  if (!title) {
    return '';
  }
  return title
    .split(' ')
    .map(item => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase())
    .join(' ');
};

export const maskCustomerName = (name: string): string => {
  if (!name) {
    return '';
  }

  if (name.split(' ').length === 3) {
    const resultName = name.split(' ').slice(0, 2).join(' ');
    return capitalizeEveryWord(resultName);
  }

  return capitalizeEveryWord(name);
};
